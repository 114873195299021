//Divs for Mobile View Only
import styled from "styled-components";
import { ColorPalette } from "../../StylesheetComps/Colors";

const nullProp = null;

export const MobileMenuLinkDiv = styled.div`
  height: 10vw;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 1vw 2vw; // Added some padding on the sides as well
  border-top: 1px solid rgba(0, 0, 0, 0.1); // Soften the border color for a more subtle and refined appearance
`;

export const BackgroundColorDiv = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Softer shadow on the bottom */
  border-radius: 2px;
`;



export const FixedItemPropertyDiv = styled.div`
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const MobileFlexColumnCont = styled.div`
  height: auto;
  margin: 1%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 100%;
  @media (max-width: 1006px) {
    height: auto;
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
  }
`;

export const MyAccountCardDiv = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 4%;
  margin-bottom: 4%;
  box-shadow: 0px 4px 8px #b7b7b7;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
`;

export const FlexColumnDiv90vw = styled.div`
  width: 90vw;
  height: auto;
  padding-bottom: 10vh;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
`;
export const WhiteTopRoundedDivAutoHeight = styled.div`
  width: 86.75vw;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
  bottom: 10%;
  background-color: white;
  padding: 7vw;
  display: ${(props) => (props.display ? props.display : "block")};
`;

export const WhiteTopRoundedDiv = styled.div`
  width: ${(props) => (props.width ? props.width : "86.75vw")};
  height: 40vh;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
  bottom: 10%;
  background-color: white;
  padding: 25px;
`;

export const TopDiv40vh = styled.div`
  position: relative;
  width: 88.5vw;
  height: 20vh;
  bottom: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const MobileSpaceAroundDiv = styled.div`
  width: 85vw;
  margin: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const GreenBorderColumnDiv = styled.div`
  height: 30vh;
  padding: 1vw;
  width: 100.3vw;
  background-color: ${ColorPalette.LightGreen};
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const TopDivWhite = styled.div`
  padding: 4.3vw;
  width: 100%;
  max-width: 91vw;
  height: 30px; /* Flexible height to accommodate content */
  min-height: 30px; /* Slightly increased for better usability */
  background-color: #ffffff; /* Clean white background */
  border-bottom: 1px solid #e0e0e0; /* Subtle separation */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #f9f9f9; /* Slight hover effect */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }
`;


export const AuthPageCont = styled.div`
  height: 105vh;
  width: 100vw;
  display: flex;
  padding-bottom: 2.5vh;
`;

export const Div100vw20vh = styled.div`
  width: 100vw;
  height: 20vh;
`;

export const Div100vw10vh = styled.div`
  width: 100vw;
  height: 10vh;
`;

export const SignupItemContWithEffects = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: 1.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 0.5s;
  padding-bottom: 2.5vh;
`;

export default nullProp;
