import styled from "styled-components";
import { ColorPalette } from "../Colors";
import { animated } from "react-spring";

const nullProp = null;

export const MobileMainCont = styled.div`
  display: none;
  @media (max-width: 1006px) {
    display: block;
    width: 101vw;
    height: 8vh;
    top: 0;
    display: flex;
    align-items: center;
    right: 2vw;
  }
`;

export const MenuMainCont = styled.div`
  width: 100vw;
  height: 13vh;
  display: flex;
  display: none;
  border-bottom: 1px dotted #d3d3d3;
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const SummaryContainerItems = styled.div`
  height: auto;
  margin-bottom: 5px;
  padding: ${(props) => (props.padding ? props.padding : "")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: space-between;
`;

export const SummaryContainer = styled.div`
  width: 90%;
  height: ${(props) => (props.height ? props.height : "auto")};
  padding: 10px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content:${(props) => (props.justifycontent ? props.justifycontent : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobileWidth ? props.mobileWidth : "95vw")};
    padding: 0px;
    margin: 0px;
  }
`;

export const FlexDivMargins = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

export const FlexEndDivAutox15Percent = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 15%;
`;

export const Div70Percent = styled.div`
  width: 70%;
`;

export const FlexColumnDivSpaceBetween = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "")};
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const Div30Percent = styled.div`
  width: 30%;
`;

export const FlexDiv50Percent = styled.div`
  width: 50%;
  display: flex;
`;

export const FlexDivSpaceBetween = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "")};
  justify-content: space-between;
  margin-top:  ${(props) => (props.margintop ? props.margintop : "1%")};
  padding-bottom: ${(props) => (props.paddingbottom ? props.paddingbottom : "")};
    background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  text-align: ${(props) => (props.textalign ? props.textalign : "center")};;
  align-items: center;
`;

export const Div300x400pxMargin30px = styled.div`
  background-color: white;
  width: 300px;
  height: 400px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  align-items: center;
  justify-content: center;
`;

export const BlankDiv = styled.div``;

export const FlexDiv40PercentMobileWidth100Percent = styled.div`
  display: flex;
  width: 40%;
  @media (max-width: 1006px) {
    width: 100%;
  }
`;

export const FlexDiv40PercentMobileDisplayNone = styled.div`
  display: flex;
  width: 40%;
  @media (max-width: 1006px) {
    width: 100%;
    display: none;
  }
`;

export const FlexDivLeftAlign = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`;

export const FlexCenterDiv100PercentSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexCenterDiv100PercentCenter = styled.div`
  width: 100%;
  display: ${(props) => (props.display ? props.display : "flex")};
  align-items: center;
  justify-content: center;
`;

export const FlexDiv90PercentMargins = styled(animated.div)`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1006px) {
    width: 100%;
  }
`;

export const GridDivMobileFlexDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 5%;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FlexDiv80Percent = styled.div`
  max-width: 80%;
  display: flex;
  min-height: 0;
  flex-flow: row wrap;
`;

export const GridDiv80xAutoPercent = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const FlexDiv70x7Percent = styled.div`
  width: ${(props) =>
    props.width ? props.width : "70%"};  
  height: ${(props) =>
    props.height ? props.height : "7%"};  
    margin: ${(props) =>
      props.margin ? props.margin : "30px"};  
  display: flex;
  align-items: ${(props) =>
    props.alignitems ? props.alignitems : "center"};  
  margin-left: 2%;
  overflow-y: ${(props) =>
    props.overflowy ? props.overflowy : ""};  
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const GreyHRMobileHidden = styled.hr`
  width: 100%;
  border: 1px solid ${ColorPalette.PrimaryGrey};
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const HiddenDivSpaceAroundColumn = styled.div`
  display: ${(props) =>
    props.vacationdisplayprop ? props.vacationdisplayprop : "none"};
  border: 1px dotted black;
  align-items: left;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 2%;
  padding-left: 2%;
`;

export const DivWidth80PercentFlexStartDisplayProp = styled.div`
  width: 80%;
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
  justify-content: flex-start;
`;

export const FullWidthDivPropDisplay = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
`;

export const FlexColumnStartDiv45Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "45%")};
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "45%")};
  }
`;

export const FlexDivCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : "")};
`;

export const FlexDivFlexEnd = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  justify-content: flex-end;
  display: flex;
`;

export const FlexDivSpaceAround = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  justify-content: space-around;
  display: flex;
`;

export const Div400x75px = styled.div`
  width: 400px;
  height: 75px;
  display: flex;
  justify-content: center;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
  }
`;

export const Div450x175px = styled.div`
  width: 450px;
  height: 175px;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 2em;
  top: 1em;
  @media (max-width: 1006px) {
    left: 0;
    width: auto;
    height: auto;
    text-align: center;
  }
`;

export const Div300pxMargin30px = styled.div`
  background-color: white;
  width: 300px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
`;

export const FlexColumnDiv = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: column;
  justify-content: ${(props) => (props.justifycontent ? props.justifycontent : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  text-align: ${(props) => (props.textalign ? props.textalign : "")};
`;

export const QuadContainer = styled.div`
  width: 100%;
  height: 6%;
  display: flex;
  justify-content: flex-start;
`;

export const QuadDeleteTitle = styled.p`
  font-family: Avenir;
  cursor: pointer;
  color: red;
`;

export const QuoteQuadMedium = styled.div`
  width: 15%;
  display: flex;
  border: 1px solid #aaaaaa;
  justify-content: center;
  background-color: white;
  align-items: ${(props) => (props.alignitems ? props.alignitems : "")};
  border-bottom-right-radius: ${(props) =>
    props.bottomrightborder ? props.bottomrightborder : ""};
`;

export const QuoteQuadHeaderBorderTopLeft = styled.div`
  width: 45%;
  height: 30px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  background-color: #eeeeee;
  padding: 5px;
  border-top-left-radius: 10px;
`;

export const QuoteQuadHead = styled.div`
  width: 20%;
  height: 30px;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 5px;
  justify-content: center;
  background-color: #eeeeee;
`;

export const QuoteQuadHeadMedium = styled.div`
  width: 15%;
  height: 30px;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 5px;
  justify-content: center;
  background-color: #eeeeee;
`;

export const QuoteQuadHeaderBorderTopRight = styled.div`
  width: 15%;
  height: 30px;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 5px;
  justify-content: center;
  background-color: #eeeeee;
  border-top-right-radius: 10px;
`;

export const FullWidthDivFlexStart = styled.div`
  width: 72vw;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const FlexDivCentered45Percent = styled.div`
  min-width: auto;
  width: 43.5%;
  text-align: left;
  padding: 0.5%;
  border: 1px solid #aaaaaa;
  display: ${(props) => (props.displayProp ? props.displayProp : "flex")};
  justify-content: flex-start;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "white")};
`;

export const FlexColumnDivCentered20Percent = styled.div`
  width: 20%;
  border: 1px solid #aaaaaa;
  display: ${(props) => (props.displayProp ? props.displayProp : "flex")};
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "white")};
`;

export const FlexibleEstimateDiv35Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "35%")};
  height: auto;
  min-height: 95px;
  max-height: 210px;
  align-items: center;
  text-align: ${(props) => (props.textalign ? props.textalign : "left")};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  padding-top: ${(props) => (props.paddingtop ? props.paddingtop : "")};
  padding-bottom: ${(props) =>
    props.paddingbottom ? props.paddingbottom : ""};
  display: ${(props) => (props.display ? props.display : "flex")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    // width:33vw;
  }
`;

export const FlexibleEstimateDiv15Percent = styled.div`
  min-width: ${(props) => (props.minwidth ? props.minwidth : "10%")};
  width: ${(props) => (props.width ? props.width : "17%")};
  max-width: 17%;
  height: auto;
  min-height: 95px;
  max-height: 210px;
  align-items: center;
  border-right: ${(props) => (props.borderright ? props.borderright : "")};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  padding-top: ${(props) => (props.paddingtop ? props.paddingtop : "")};
  padding-bottom: ${(props) =>
    props.paddingbottom ? props.paddingbottom : ""};
  text-align: left;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: center;
  @media (max-width: 1006px) {
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
  }
`;

export const GreyFlexibleEstimateDiv15Percent = styled.div`
  min-width: 10%;
  width: 17%;
  max-width: 17%;
  // height: auto;
  // min-height: 110px;
  // max-height: 210px;
  flex-grow: 1;
  border-right: ${(props) => (props.borderright ? props.borderright : "")};
  align-items: center;
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  text-align: left;
  padding-top: ${(props) => (props.paddingtop ? props.paddingtop : "")};
  padding-bottom: ${(props) =>
    props.paddingbottom ? props.paddingbottom : ""};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.TertiaryGrey};
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: center;
  @media (max-width: 1006px) {
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
  }
`;

export const FlexibleEstimateDiv5Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "5%")};
  height: auto;
  //min-height: 110px;
  //max-height: 210px;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  align-items: center;
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: center;
  @media (max-width: 1006px) {
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    width: auto;
  }
`;

export const FlexContainerPhotos = styled.div`
  width: 100%;
  min-height: 200px;
  height: auto;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 1006px) {
    box-shadow: none;
  }
`;

export const FlexContainerItem = styled.div`
width: ${(props) => (props.width ? props.width : "45%")};
min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  justify-content: flex-start;
  @media (max-width: 1006px) {
    box-shadow: none;
  }
`;

export const GridContainer200pxWidth = styled.div`
  max-width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 0.2fr;
  grid-gap: 2px;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
`;

export const GridContainer3Wide = styled.div`
  width: 95%;
  margin: 1%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
  }
`;

export const GridContainer2Wide = styled.div`
  width: 90%;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  row-gap: 0px;
  margin: 0%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1006px) {
    display: flex;
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "90%")};
    flex-direction: column;
  }
`;

export const InlineGridContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3px;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FlexDiv40Percent = styled.div`
  width: 40%;
  display: flex;
`;

export const Div225px = styled.div`
  width: 225px;
`;

export const Div175px = styled.div`
  width: 175px;
`;

export const Div125px = styled.div`
  width: 125px;
`;

export const Div80px = styled.div`
  width: 80px;
`;

export const Div150pxMobile250px = styled.div`
  width: 150px;
  @media (max-width: 1006px) {
    width: 250px;
  }
`;

export const Div150pxMobileDisplayNone = styled.div`
  width: 200px;
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const StyledDiv = styled.div`
  width: ${(props) => props.width || '100%'};
  
  @media (max-width: 1006px) {
    width: ${(props) => props.mobileWidth || props.width || '100%'};
    display: ${(props) => props.mobileDisplay || 'block'};
  }
`;

export const StyledFlexDiv = styled.div`
  flex-grow: ${(props) => props.grow || 1};
  flex-basis: ${(props) => props.basis || '0'};
  flex-shrink: ${(props) => props.shrink || 1};

  @media (max-width: 1006px) {
    display: ${(props) => props.mobileDisplay || 'block'};
    flex-basis: ${(props) => props.mobileWidth || props.basis || '0'};
  }
`;


export const FlexColumnDivAutoMargin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
`;

export default nullProp;
