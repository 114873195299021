// // React import
// import React, { useState } from "react";

// // Input component import
// import Input from "../Input";

// // FlexDiv, FlexRowCont100x8PercentSpaceAround, and RelativeContBGColorWhite imported from the "Cont" stylesheet
// import {
//   FlexDiv,
//   FlexRowCont100x8PercentSpaceAround,
//   RelativeContBGColorWhite,
// } from "../../StylesheetComps/Cont";

// // FlexDiv40PercentMobileWidth100Percent imported from the "Div" stylesheet
// import { FlexDiv40PercentMobileWidth100Percent } from "../../StylesheetComps/Div";

// // RegularText, SmallTitleBoldWithWidth, and RegularTextBoldRed imported from the "Titles" stylesheet
// import {
//   RegularText,
//   SmallTitleBoldWithWidth,
//   RegularTextBoldRed,
// } from "../../StylesheetComps/Titles";

// // RegularResponsiveButton imported from the "Components" stylesheet
// import { RegularResponsiveButton } from "../../StylesheetComps/Components";

// // MobileInput imported from the "Inputs" mobile component
// import { MobileInput } from "../../MobileComponents/Inputs";

// // ColorPalette imported from the "Colors" stylesheet
// import { ColorPalette } from "../../StylesheetComps/Colors";

// const ChangePass = ({ passSwitch, onClose, onPasswordChanged, onPreview }) => {
//   const [oldPass, setOldPass] = useState("");
//   const [newPass, setNewPass] = useState("");
//   const [confirmNewPass, setConfirmNewPass] = useState("");
//   const [errorText, setErrorText] = useState("");

//   if (!passSwitch) return null;

//   if (window.$ISMOBILE === true) {
//     return (
//       <RelativeContBGColorWhite>
//         <RegularText>Change Password</RegularText>
//         <hr></hr>
//         <FlexDiv>
//           <SmallTitleBoldWithWidth>Old Password</SmallTitleBoldWithWidth>
//           <MobileInput
//             type="password"
//             inputwidth="40%"
//             inputheight="5px"
//             placeholder="Old Password"
//             onChange={(e) => {
//               setOldPass(e.target.value);
//             }}
//           ></MobileInput>
//         </FlexDiv>
//         <br></br>
//         <FlexDiv>
//           <SmallTitleBoldWithWidth>New Password</SmallTitleBoldWithWidth>
//           <MobileInput
//             type="password"
//             inputwidth="40%"
//             inputheight="5px"
//             placeholder="New Password"
//             onChange={(e) => {
//               setNewPass(e.target.value);
//             }}
//           ></MobileInput>
//         </FlexDiv>
//         <br></br>
//         <FlexDiv>
//           <SmallTitleBoldWithWidth>
//             Confirm New Password
//           </SmallTitleBoldWithWidth>
//           <MobileInput
//             type="password"
//             inputwidth="40%"
//             inputheight="1px"
//             placeholder="Confirm New Password"
//             onChange={(e) => {
//               setConfirmNewPass(e.target.value);
//             }}
//           ></MobileInput>
//         </FlexDiv>
//         <br></br>
//         <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
//         <FlexRowCont100x8PercentSpaceAround>
//           <RegularResponsiveButton
//             bgcolor={ColorPalette.PrimaryRed}
//             onClick={() => {
//               onClose();
//               setErrorText("");
//             }}
//           >
//             Cancel
//           </RegularResponsiveButton>
//           <RegularResponsiveButton
//             onClick={() => {
//               if (confirmNewPass !== newPass || oldPass === "") {
//                 setErrorText("Passwords do not match");
//               } else {
//                 onPreview({ oldPass, newPass });
//                 onPasswordChanged(oldPass, newPass);
//                 setErrorText("");
//               }
//             }}
//           >
//             Save Changes
//           </RegularResponsiveButton>
//         </FlexRowCont100x8PercentSpaceAround>
//       </RelativeContBGColorWhite>
//     );
//   } else {
//     return (
//       <RelativeContBGColorWhite>
//         <RegularText>Change Password</RegularText>
//         <hr></hr>
//         <FlexDiv>
//           <SmallTitleBoldWithWidth>Old Password</SmallTitleBoldWithWidth>
//           <Input
//             type="password"
//             inputwidth="40%"
//             inputheight="5px"
//             placeholder="Old Password"
//             onChange={(e) => {
//               setOldPass(e.target.value);
//             }}
//           ></Input>
//         </FlexDiv>
//         <FlexDiv>
//           <SmallTitleBoldWithWidth>New Password</SmallTitleBoldWithWidth>
//           <Input
//             type="password"
//             inputwidth="40%"
//             inputheight="5px"
//             placeholder="New Password"
//             onChange={(e) => {
//               setNewPass(e.target.value);
//             }}
//           ></Input>
//         </FlexDiv>
//         <FlexDiv>
//           <SmallTitleBoldWithWidth>
//             Confirm New Password
//           </SmallTitleBoldWithWidth>
//           <Input
//             type="password"
//             inputwidth="40%"
//             inputheight="1px"
//             placeholder="Confirm New Password"
//             onChange={(e) => {
//               setConfirmNewPass(e.target.value);
//             }}
//           ></Input>
//         </FlexDiv>
//         <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
//         <FlexDiv40PercentMobileWidth100Percent>
//           <RegularResponsiveButton
//             onClick={() => {
//               onClose();
//               setErrorText("");
//             }}
//             bgcolor={ColorPalette.PrimaryRed}
//           >
//             Cancel
//           </RegularResponsiveButton>
//           <RegularResponsiveButton
//             onClick={() => {
//               if (confirmNewPass !== newPass || oldPass === "") {
//                 setErrorText("Passwords do not match");
//               } else {
//                 onPreview({ oldPass, newPass });
//                 onPasswordChanged(oldPass, newPass);
//                 setErrorText("");
//               }
//             }}
//           >
//             Save Changes
//           </RegularResponsiveButton>
//         </FlexDiv40PercentMobileWidth100Percent>
//       </RelativeContBGColorWhite>
//     );
//   }
// };

// ChangePass.defaultProps = {
//   onChange: () => {},
//   onPreview: () => {},
//   onPasswordChanged: () => {},
// };

// export default ChangePass;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import IconButton from "../IconButton";
import { ColorPalette } from "../../StylesheetComps/Colors";

/* ---------------------------------
   Animations
--------------------------------- */
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

/* ---------------------------------
   Styled Components
--------------------------------- */

/** 
 * Main container, reminiscent of an Apple card:
 * - Rounded corners, subtle shadow, white background 
 * - Slide/fade in from below
 */
const Container = styled.div`
  animation: ${fadeInUp} 0.35s ease forwards;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  margin: 1.5rem auto;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

/** 
 * A simple heading styled in an iOS-inspired fashion 
 */
const Heading = styled.h2`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
  color: #333;
`;

/** 
 * Thin horizontal line akin to iOS separators 
 */
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e5ea;
  margin: 0.75rem 0 1.25rem 0;
`;

/** 
 * Label text for each field 
 */
const Label = styled.label`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 0.25rem;
  display: inline-block;
`;

/** 
 * A minimal input with Apple-inspired styling:
 * - Lightly rounded, subtle border, focus glow
 */
const StyledInput = styled.input`
  display: block;
  width: 100%;
  max-width: 400px;
  padding: 0.6rem 0.75rem;
  font-size: 0.95rem;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: #007aff;
  }
`;

/** 
 * Container for each row (label + input).
 * We stack on mobile, side by side if we want on desktop, 
 * but let's keep it simple & consistent for Apple-like clarity.
 */
const FieldRow = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
`;

/** 
 * Error message in red 
 */
const ErrorText = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #d0021b;
  font-size: 0.9rem;
  margin: 0.5rem 0;
`;

/** 
 * Button row container for "Cancel" / "Save" 
 */
const ButtonRow = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
`;

/** 
 * Buttons:
 * - Apple-like system style 
 */
const StyledButton = styled.button`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.95rem;
  font-weight: 500;
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  background-color: ${(props) => (props.cancel ? "#FF3B30" : "#007AFF")};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.cancel ? "#e23028" : "#0067e6")};
  }
`;

/** 
 * Special container for mobile 
 * - We'll tweak layout or spacing as needed 
 */
const MobileContainer = styled(Container)`
  width: 95%;
  max-width: 600px;
  /* Any mobile-specific overrides can go here */
`;

/* ---------------------------------
   Main Component 
--------------------------------- */

const ChangePass = ({ passSwitch, onClose, onPasswordChanged, onPreview }) => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [errorText, setErrorText] = useState("");

  // If not visible, don't render anything
  if (!passSwitch) return null;

  const handleSave = () => {
    if (!oldPass || !newPass || !confirmNewPass || confirmNewPass !== newPass) {
      setErrorText("Passwords do not match or fields are empty");
    } else {
      onPreview({ oldPass, newPass });
      onPasswordChanged(oldPass, newPass);
      setErrorText("");
    }
  };

  // Check if mobile is set by global variable
  const isMobile = window.$ISMOBILE === true;

  const ContainerEl = isMobile ? MobileContainer : Container;

  return (
    <ContainerEl>
      <Heading>Change Password</Heading>
      <Divider />

      <FieldRow>
        <Label htmlFor="oldPass">Old Password</Label>
        <StyledInput
          id="oldPass"
          type="password"
          placeholder="Enter old password"
          value={oldPass}
          onChange={(e) => setOldPass(e.target.value)}
        />
      </FieldRow>

      <FieldRow>
        <Label htmlFor="newPass">New Password</Label>
        <StyledInput
          id="newPass"
          type="password"
          placeholder="Enter new password"
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
        />
      </FieldRow>

      <FieldRow>
        <Label htmlFor="confirmNewPass">Confirm New Password</Label>
        <StyledInput
          id="confirmNewPass"
          type="password"
          placeholder="Re-enter new password"
          value={confirmNewPass}
          onChange={(e) => setConfirmNewPass(e.target.value)}
        />
      </FieldRow>

      {errorText && <ErrorText>{errorText}</ErrorText>}

      <ButtonRow>
        <IconButton cancel
          onClick={() => {
            onClose();
            setErrorText("");
          }} bgcolor={ColorPalette.PrimaryRed}>Cancel</IconButton>
      <IconButton onClick={handleSave} src={'/saveiconwhite_icon.svg'}>Save Changes</IconButton>
      </ButtonRow>
    </ContainerEl>
  );
};

/* Default props, ensuring the same API usage as before */
ChangePass.defaultProps = {
  onClose: () => {},
  onPreview: () => {},
  onPasswordChanged: () => {},
};

export default ChangePass;
