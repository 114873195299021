import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Menu from "../../comps/MenuComps/Menu";
import EditStoreWindow from "../../comps/Windows/EditStore";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetStoreInfo } from "../../API_Functions/Store_Functions";
import {
  FlexColumnCont80vwAutoHeight, FlexCont100vw100vh,
  FlexDiv, FlexRowCont60PercentSpaceEvenly, FlexRowCont70PercentSpaceBetween, FlexRowContFullWidth, LeftCont
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexColumnDiv, FlexDiv70x7Percent, FlexDivSpaceBetween } from "../../StylesheetComps/Div";
import { Icon20x20, Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import {
  LeagueSpartanExtraLargeBold,
  RegularText
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { InputField } from "../../StylesheetComps/Inputs";
import { InputLabel } from "@material-ui/core";
import  Dropdown  from '../../comps/DropdownMenu'
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { GetStoreProducts, GetStoreTools } from "../../API_Functions/CatProduct_Functions";
import  StoreCard  from '../../comps/StoreCard'
import { GetStoreToolKits } from "../../API_Functions/Tool_Functions";

const StoreScreen = () => {
  //=============================================================================
  // Store Screen - Manager views basic information about their store
  //=============================================================================

  //=============================================================================
  // Manager can click to view Product Categories, ToolKits, Staff, & Zones
  //=============================================================================

  const [storeObj, setStoreObj] = useState([]);
  const [originalProductData, setOriginalProductData] = useState([]);
const [productData, setProductData] = useState([]);

const [originalToolData, setOriginalToolData] = useState([]);
const [toolData, setToolData] = useState([]);

const [originalStaffData, setOriginalStaffData] = useState([]);
const [staffData, setStaffData] = useState([]);

const [originalZoneData, setOriginalZoneData] = useState([]);
const [zoneData, setZoneData] = useState([]);

const [toolkitData, setToolkitData] = useState([]);

const history = useHistory()

  const [roleNum, setRoleNum] = useState(0);
  const [managerName, setManagerName] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [productSelected, setProductSelected] = useState(true);
  const [toolSelected, setToolSelected] = useState(true);
  const [staffSelected, setStaffSelected] = useState(true);
  const [zoneSelected, setZoneSelected] = useState(true);

  const params = useParams();

  // const GetStore = async (storeId) => {
  //   try {
  //     // Attempt to get store information
  //     const data = await GetStoreInfo(storeId);
  //     const productData = await GetStoreProducts(storeId)
  //     const toolData = await GetStoreTools(storeId)
  //     const staffData = await axios.get(window.$DBURL + "users/roles");
  //     const zoneData = await axios.get(`${window.$DBURL}store/zonelist/${storeId}`)
  //     const toolkitData = await GetStoreToolKits(storeId)
  //     // Check if data is not null
  //     if (data !== null) {
  //       setStoreObj(data);
  //       setOriginalProductData(productData.products)
  //       setProductData(productData.products)
  //       setOriginalToolData(toolData.tools)
  //       setToolData(toolData.tools)
  //       setOriginalStaffData(staffData.data.roles)
  //       setStaffData(staffData.data.roles)
  //       setOriginalZoneData(zoneData.data.zones)
  //       setZoneData(zoneData.data.zones)
  //       setToolkitData(toolkitData.toolkit)
  //       console.log(toolkitData.toolkit)
  //     }
  //   } catch (error) {
  //     // Handle error if something goes wrong during the `await` operation
  //     console.error(error);
  //   }
  // };

  const GetStore = async (storeId) => {
    try {
      const data = await GetStoreInfo(storeId);
      const productData = await GetStoreProducts(storeId);
      const toolData = await GetStoreTools(storeId);
      const staffData = await axios.get(window.$DBURL + "users/roles");
      const zoneData = await axios.get(`${window.$DBURL}store/zonelist/${storeId}`);
      const toolkitData = await GetStoreToolKits(storeId);
  
      if (data !== null) {
        setStoreObj(data);
  
        // Updated sortByName function to sort names starting with numbers to the bottom
        const sortByName = (a, b) => {
          const startsWithNumberA = /^\d/.test(a.Name);
          const startsWithNumberB = /^\d/.test(b.Name);
  
          if (startsWithNumberA && !startsWithNumberB) {
            return 1; // A should come after B
          } else if (!startsWithNumberA && startsWithNumberB) {
            return -1; // A should come before B
          }
          // If both start with a number or both start with a letter, sort alphabetically
          return a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
        };
  
        const sortedProductData = productData.products.sort(sortByName);
        setOriginalProductData(sortedProductData);
        setProductData(sortedProductData);
  
        const sortedToolData = toolData.tools.sort(sortByName);
        setOriginalToolData(sortedToolData);
        setToolData(sortedToolData);
  
        // Adjust the sorting for staff data if necessary, assuming 'Role' is the sorting key
        const sortedStaffData = staffData.data.roles.sort((a, b) => {
          return a.Role.localeCompare(b.Role, undefined, {sensitivity: 'base'});
        });
        setOriginalStaffData(sortedStaffData);
        setStaffData(sortedStaffData);
  
        const sortedZoneData = zoneData.data.zones.sort(sortByName);
        setOriginalZoneData(sortedZoneData);
        setZoneData(sortedZoneData);
  
        // No sorting applied to toolkitData in this example, adjust if needed
        setToolkitData(toolkitData.toolkit);
        console.log(toolkitData.toolkit);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  const handleSearch = (searchText) => {
    const lowerCaseText = searchText.toLowerCase();
    const textArray = lowerCaseText.split(" ");
  
    // This is a generalized filter function
    const filterData = (dataList, keyToFilter) => {
      return dataList.filter((item) => {
        // Exclude items with 'DNU' in uppercase in their name
        if (item[keyToFilter] && item[keyToFilter].toUpperCase().includes('DNU')) {
          return false;
        }
  
        // Check for each word in the searchText
        // Ensuring item[keyToFilter] exists to avoid errors on undefined properties
        return textArray.every((word) => item[keyToFilter] && item[keyToFilter].toLowerCase().includes(word));
      });
    };
  
    // Apply the filter to each data set and update the respective states
    // Assuming 'Name' is the key for productData, toolData, and zoneData, and 'Role' for staffData
    setProductData(filterData(originalProductData, 'Name'));
    setToolData(filterData(originalToolData, 'Name'));
    setStaffData(filterData(originalStaffData, 'Role')); // Use 'Role' or the correct key for filtering staffData
    setZoneData(filterData(originalZoneData, 'Name'));
  };  

  const GetProfileInfo = async () => {
    setManagerName(JSON.parse(sessionStorage.getItem("userInfo")).Name);
  };

  const GetSuppliers = async () => {
    const resp = await axios.get(
      window.$DBURL + "store/" + params.Id + "/supplier"
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.suppliers !== null) {
          // getZoneSuppliers(resp.data.suppliers)
        }
      }
    }
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  useEffect(() => {
    GetStore(params.Id);
    GetProfileInfo();
    GetSuppliers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (roleNum === UserRole.Manager) {
    return (
      <FlexCont100vw100vh>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

        <FlexColumnCont80vwAutoHeight>
          <RightViewFullWidth>
            <FlexDiv70x7Percent height='auto' margin='15px'>
              <FlexColumnDiv>
                <FlexDiv>
              <LeagueSpartanExtraLargeBold>
                {storeObj.Name} Inventory Management
              </LeagueSpartanExtraLargeBold>
              <Icon30x40pxPointer onClick={() => {
                  setIsEditOpen(true);
                }} src='/pencil.svg'></Icon30x40pxPointer>
               </FlexDiv>
                <FlexDiv>
                  <RegularText>{storeObj.Address} - {storeObj.Phone}</RegularText>
                </FlexDiv>
                <FlexDiv 
              
              >
                <Link
                  to={"/productcategory/" + params.Id}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Products"
                    StoreCardImgUrl="/Inventory.svg"
                  ></StoreCard>
                </Link>
                <Link
                  to={"/toolkit/" + params.Id}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Tools"
                    StoreCardImgUrl="/Tools.svg"
                  ></StoreCard>
                </Link>
                <Link
                  to={"/rolecat/" + params.Id}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Staff"
                    StoreCardImgUrl="/Workforce.svg"
                  ></StoreCard>
                </Link>
                <Link
                  to={"/Zonelist/" + params.Id}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Zones"
                    StoreCardImgUrl="/Zones.svg"
                  ></StoreCard>
                </Link>
              </FlexDiv>
                </FlexColumnDiv>
            </FlexDiv70x7Percent>
            <EditStoreWindow
              storeobj={storeObj}
              open={isEditOpen}
              onClose={() => {
                setIsEditOpen(false);
              }}
            ></EditStoreWindow>
            <hr></hr>
            <br></br>
            <FlexDiv70x7Percent>
            <FlexColumnDiv width="90%">
            <FlexDivSpaceBetween>
              <div>
              <InputLabel>Search for what you are looking for...</InputLabel>
              </div>
              <FlexDiv>
              <RegularResponsiveButton
  bgcolor={productSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
  onClick={() => setProductSelected(!productSelected)}
>
  Products
</RegularResponsiveButton>

<RegularResponsiveButton
  bgcolor={toolSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
  onClick={() => setToolSelected(!toolSelected)}
>
  Tools
</RegularResponsiveButton>

<RegularResponsiveButton
  bgcolor={staffSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
  onClick={() => setStaffSelected(!staffSelected)}
>
  Staff
</RegularResponsiveButton>

<RegularResponsiveButton
  bgcolor={zoneSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
  onClick={() => setZoneSelected(!zoneSelected)}
>
  Zones
</RegularResponsiveButton>


              </FlexDiv>
            </FlexDivSpaceBetween>
            <br></br>
<InputField onChange={(e) => {
  const searchText = e.target.value;
  if (!searchText.trim()) {
    // If search text is empty, reset to original data
    setProductData(originalProductData);
    setToolData(originalToolData);
    setStaffData(originalStaffData);
    setZoneData(originalZoneData);
  } else {
    // Otherwise, filter data
    handleSearch(searchText);
  }
}} />
            </FlexColumnDiv>
            </FlexDiv70x7Percent>
            <FlexDiv70x7Percent width='100%' height='60%' overflowy='scroll' alignitems='baseline'>
            <FlexColumnDiv>
  {productSelected && productData?.map((o, i) =>
    <div onClick={()=>{
      history.push(
        "/productlist/" + params.Id + "/" + o.Category_Id +'/'+o.Id
      );
    }}>
    <FlexRowContFullWidth key={`product-${i}`}>
    <FlexDivSpaceBetween width='63vw'>
    <FlexDiv width='30vw'>
      <Icon20x20 src='/Inventory.svg' />
      <RegularText>
        {o.Name}
      </RegularText>
      </FlexDiv>
      <FlexDiv>
        ${o.UnitPrice.toFixed(2)}
         {' '}
        {o.Supplier.map((supplier, index)=>
        <RegularText>
          {supplier.SupplierName}
        </RegularText>
        )}
        {o.Unit}
      </FlexDiv>
      </FlexDivSpaceBetween>
    </FlexRowContFullWidth>
    <hr></hr>
    </div>
  )}

  {toolSelected && toolData?.map((o, i) =>
    <FlexDiv key={`tool-${i}`} onClick={()=>{
      var toolKitName = toolkitData.find(toolKit => toolKit.Id === o.ToolKit_Id)?.Name
      history.push(
        "/toollist/" + params.Id + "/" + o.ToolKit_Id + '/' + toolKitName
      );
    }
    }>
      <Icon20x20 src='/Tools.svg' />
      <RegularText>
        {o.Name}
      </RegularText>
    </FlexDiv>
  )}

  {staffSelected && staffData?.map((o, i) =>
    <FlexDiv key={`staff-${i}`} onClick={()=>{
      history.push("/rolelist/" + params.Id + "/" + o.Role)
    }}>
      <Icon20x20 src='/Workforce.svg' />
      <RegularText>
        {o.Role}
      </RegularText>
    </FlexDiv>
  )}

  {zoneSelected && zoneData?.map((o, i) =>
    <FlexDiv key={`zone-${i}`} onClick={()=>{
      history.push("/Zone/" + params.Id + "/" + o.Id)
    }}>
      <Icon20x20 src='/Zones.svg' />
      <RegularText>
        {o.Name}
      </RegularText>
    </FlexDiv>
  )}
</FlexColumnDiv>
<FlexDiv></FlexDiv>
            </FlexDiv70x7Percent>
          
          </RightViewFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexCont100vw100vh>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

StoreScreen.defaultProps = {};

export default StoreScreen;
