/* eslint-disable */

import { useEffect, useState } from "react";
import {
  BackgroundColorDiv,
  FixedItemPropertyDiv,
} from "../../MobileComponents/Divs";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnFullWidth,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFlexStart,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanRegular,
  LeagueSpartanRegularMinWidth,
  LeagueSpartanTiny,
  RegularText,
  SmallGreenLeagueText,
  StyledHRPropColour,
  TinyHeaderDarkGrey,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import SpeechBubbleComponent from "../../comps/Windows/UpdateBubble";
import Dropdown from "../../comps/DropdownMenu";
import { decimalHoursToTimeString } from "../../API_Functions/Utility_Functions";
import { FlexDivFlexEnd, FlexDivSpaceBetween } from "../../StylesheetComps/Div";
import { Icon15x15 } from "../../StylesheetComps/Imgs/Icons";
const MobileEstimateQuoteItem = ({
  header1,
  header2,
  subheader2notes,
  subheader2,
  header3,
  header3title,
  header4,
  header4title,
  header5,
  header5title,
  header6,
  header6title,
  header7,
  header7title,
  header8,
  quoteItemObj,
  inputheaderposition,
  noChange,
  OutputValue,
  RemoveItemFromLocalQuoteObject,
  parentid,
  itemLink,
  bgColor,
  onClick,
  header10,
  isHeader,
  showRelatedItems,
  constructionView,
  hideBidQuoteHeaders,
  onToggleDisplay,
  hourlyRate,
  CategoryType,
  ShowItemSelection,
  EditTag,
  DeleteTag,
  DeliveryItems,
  RemovalItems,
  showAddOnsSelection,
  deleteItemFromEstimate,
  RefreshComponentState,
  adjustAccess,
}) => {
  const [itemPropertyDisplay, setItemPropertyDisplay] = useState("none");
  const [itemHRDisplay, setItemHRDisplay] = useState("none");
  const [itemPropertyToggle, setItemProperyToggle] = useState(false);

  const [subheader2Display] = useState("flex");
  const [header3Display, setHeader3Display] = useState("block");

  const [header3InputDisplay, setHeader3InputDisplay] = useState("none");

  const [descriptionOverflow, setDescriptionOverflow] = useState("hidden");
  const [descriptionWhiteSpace, setDescriptionWhiteSpace] = useState("nowrap");

  const UpdateDescriptionUI = async (overflow) => {
    if (overflow === "hidden") {
      setDescriptionOverflow("visible");
      setDescriptionWhiteSpace("normal");
    } else {
      setDescriptionOverflow("hidden");
      setDescriptionWhiteSpace("nowrap");
    }
  };

  const DetermineInputHeaderLogic = async () => {
    if (parentid === 0) {
      if (noChange === false) {
        InitializeSetup();
        switch (inputheaderposition) {
          case 1:
            break;
          case 2:
            break;
          case 3:
            setHeader3Display("none");
            UpdateItemPropertyView(false);
            setHeader3InputDisplay("flex");
            break;
          case 5:
            break;
          case 6:
            break;
          default:
            break;
        }
      }
    }
  };

  const InitializeSetup = async () => {
    setHeader3Display("block");

    setHeader3InputDisplay("none");
  };

  useEffect(() => {
    DetermineInputHeaderLogic(inputheaderposition);
  }, [inputheaderposition]); // eslint-disable-line react-hooks/exhaustive-deps

  const CheckIfValidLink = async (link) => {
    if (link !== null && link !== undefined && link !== "") {
      var p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (link.match(p)) {
        setYoutubeLinkProp(link);
        setLinkHeaderDisplay("underline");
        setPointerEvent("auto");
      } else {
        setLinkHeaderDisplay("none");
      }
    }
  };

  const UpdateItemPropertyView = async (itemtoggle) => {
    UpdateDescriptionUI(descriptionOverflow, descriptionWhiteSpace);
    if (itemtoggle === false) {
      setItemPropertyDisplay("flex");
      setItemHRDisplay("block");
      setItemProperyToggle(true);
    } else {
      setItemPropertyDisplay("none");
      setItemHRDisplay("none");
      setItemProperyToggle(false);
    }
  };

  const shouldDisplayPencil =
    isHeader === true &&
    header2 !== "Subtotal" &&
    header2 !== "General Materials" &&
    header2 !== "Delivery" &&
    header2 !== "Removals" &&
    header2 !== "Access Area" &&
    header2 !== "Tools/Machines" &&
    header2 !== "Labour Breakdown";

  const shouldDisplayGreenButton =
    header2 === "General Materials" ||
    header2 === "Delivery" ||
    header2 === "Removals" ||
    header2 === "Access Area" ||
    header2 === "Tools/Machines" ||
    header2 === "Labour Breakdown";

  let iconSrc = "";

  if (shouldDisplayPencil) {
    iconSrc = "/Pencil.svg";
  } else if (shouldDisplayGreenButton) {
    iconSrc = "/Green Button.svg";
  }

  const itemIconSRC = isHeader
    ? null // Don't display anything when isHeader is true
    : CategoryType === CategoryTypes.Material
    ? "/Material_EstimateIcon.svg"
    : CategoryType === "Delivery"
    ? "/Delivery_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Removal
    ? "/Removal_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Access
    ? "/AccessArea_EstimateIcon.svg"
    : CategoryType === "Tool"
    ? "/Tools_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Labor
    ? "/Labour_EstimateIcon.svg"
    : ""; // Set default to an empty string

  useEffect(() => {
    CheckIfValidLink(itemLink);
  }, [itemLink]);

  const [isOpen, setIsOpen] = useState(false);

  // Toggle open/closed on click
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };


  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item, field) => {
    setSelectedItem({ item, field });
  };

  const determineSpeechBubbleDisplayValue = () => {
    if (selectedItem) return "block";
    return "none";
  };

  const determineDivDisplayValue = () => {
    if (hideBidQuoteHeaders === true) return "none";

    if (DeliveryItems.length > 0 || RemovalItems.length > 0) return "none";

    const nonLaborRelatedItemsExist =
      quoteItemObj?.RelatedItems?.filter(
        (o) => o.CateType !== CategoryTypes.Labor
      ).length > 0;

    if (quoteItemObj?.Parent_Id === 0 && nonLaborRelatedItemsExist) {
      return "block";
    }

    if (quoteItemObj?.Parent_Id === 0) {
      return "block";
    }

    if (quoteItemObj?.Parent_Id !== 0 && isHeader === false) {
      return "block";
    }

    if (CategoryType === CategoryTypes.Removal) {
      return "block";
    }

    if (
      quoteItemObj?.Parent_Id !== 0 &&
      CategoryType === CategoryTypes.Material
    ) {
      return "block";
    }

    if (quoteItemObj.RentalLength) {
      return "block";
    }

    return "none";
  };

  return (
    <div onClick={toggleOpen}    >
      <SpeechBubbleComponent
        itemObj={selectedItem?.item}
        display={determineSpeechBubbleDisplayValue()}
        RefreshComponentState={() => {
          RefreshComponentState();
        }}
        cancelItemChange={() => {
          setSelectedItem(null);
        }}
        fieldType={selectedItem?.field}
      />
      <StyledHRPropColour
        margin="0"
        border={
          parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
            ? `0.25px solid ${ColorPalette.PrimaryButtonBlue}`
            : `0.25px solid white`
        }
      ></StyledHRPropColour>
      <BackgroundColorDiv
        width={isHeader === true ? "94.5vw" : "92.5vw"}
        padding={isHeader === true ? "0px" : parentid === 0 ? "8px" : "8px"}
        bgcolor={
          parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
            ? ColorPalette.LightGreen
            : ColorPalette.White
        }
      >
        {/* <StyledHRPropColour
          border="0.5px solid white"
          mobiledisplay={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? "none"
              : itemHRDisplay
          }
        ></StyledHRPropColour> */}
        <FlexRowCont100PercentSpaceBetween mobilewidth="100%">
        <FlexDivSpaceBetween textalign='left' width='94vw'>
  {/* Item Name */}
  {isHeader ? (
    <FlexDiv>
    <LeagueSpartanRegular
      margin="0px"
      onClick={() => {
        onToggleDisplay();
        UpdateItemPropertyView(itemPropertyToggle);
      }}
    >
      {header2}
    </LeagueSpartanRegular>
   
      <FlexDiv>
        {shouldDisplayPencil ? (
          <>
            <Icon30px20pxPointer
              display="block"
              src="/Green Button.svg"
              onClick={ShowItemSelection}
            />
            <Icon30px20pxPointer
              display="block"
              src="/Pencil.svg"
              onClick={() => {
                if (header2 === "Machines, Disposals, & Access") {
                  adjustAccess();
                } else {
                  EditTag();
                }
              }}
            />
          </>
        ) : (
          shouldDisplayGreenButton && (
            <Icon30px20pxPointer
              display="block"
              src="/Green Button.svg"
              onClick={ShowItemSelection}
            />
          )
        )}
      </FlexDiv>

      <Icon30px20pxPointer
            display={
              isHeader === true &&
              header2 !== "General Materials" &&
              header2 !== "Delivery" &&
              header2 !== "Removals" &&
              header2 !== "Access Area" &&
              header2 !== "Tools/Machines" &&
              header2 !== "Labour Breakdown" &&
              header2 !== "Subtotal"
                ? "block"
                : "none"
            }
            src="/Garbage_Icon.svg"
            onClick={() => {
              DeleteTag();
            }}
          ></Icon30px20pxPointer>

      </FlexDiv>
  ) : (
    <RegularText
      maxwidth="63vw"
      mobilefontsize='12px'
      fontweight='bold'
      onClick={() => {
        onToggleDisplay();
        UpdateItemPropertyView(itemPropertyToggle);
      }}
    >
      {header2}
    </RegularText>
  )}

  <FlexDiv>
  {
  isHeader && header2 === 'Subtotal' ? (
    <div>
      {/* <StyledHRPropColour border="0.5px solid white" /> */}
      <LeagueSpartanTiny
        fontsize={isHeader ? '18px' : '12px'}
        margintop="auto"
        marginbottom="auto"
      >
        {header8}
      </LeagueSpartanTiny>
      {/* <StyledHRPropColour border="0.5px solid white" /> */}
    </div>
  ) : isHeader && header2 !== 'Subtotal' ? (
  null
  ) : (
    <div>
      {/* <StyledHRPropColour border="0.5px solid white" /> */}
      <LeagueSpartanTiny
        fontsize={isHeader ? '18px' : '12px'}
        margintop="auto"
        marginbottom="auto"
      >
        {header8}
      </LeagueSpartanTiny>
      {/* <StyledHRPropColour border="0.5px solid white" /> */}
    </div>
  )
}

  {isHeader === true && header2 == 'Subtotal' ? (
    null ) : 
  <div style={{ display: determineDivDisplayValue() }}>
    <Dropdown
      currentPage="itemCard"
      addOns={showAddOnsSelection}
      deleteItem={() => deleteItemFromEstimate(quoteItemObj.Id)}
      changeTag={() => handleItemClick(quoteItemObj, "Tag")}
    />
   
  </div>
 }

 
  </FlexDiv>

  
</FlexDivSpaceBetween>

        </FlexRowCont100PercentSpaceBetween>
      
        <TinyTextItalics
          marginleft="-2px"
          margintop="0px"
          fontsize='10px'
          onClick={() => {
            handleItemClick(quoteItemObj, "Notes");
          }}
        >
          {subheader2notes}
        </TinyTextItalics>

        <FlexRowCont100PercentSpaceBetween
          onClick={() => {
            UpdateItemPropertyView(itemPropertyToggle);
            onToggleDisplay();
          }}
        >
          <FlexDivFlexEnd
          width='91vw'
          >
          <TinyHeaderDarkGrey
            display={subheader2Display}
            mobiledisplay="block"
            overflow={descriptionOverflow}
            whitespace={descriptionWhiteSpace}
          >
            {header7}
          </TinyHeaderDarkGrey>
          {!isHeader ? (
        <Icon15x15
         padding="0px 0px 0px 0px"
          src={isOpen ? "/uparrow.svg" : "/downarrow.svg"}
          // Toggle state on click
        />
      ) : null}
          </FlexDivFlexEnd>
        </FlexRowCont100PercentSpaceBetween>

        <StyledHRPropColour
                width='100%'
          display={itemHRDisplay}
          mobiledisplay={itemHRDisplay}
          border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
        ></StyledHRPropColour>
        <StyledHRPropColour
          display={itemHRDisplay}
          mobiledisplay={itemHRDisplay}
          border={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? `0.25px solid ${ColorPalette.LightGreen}`
              : `0.25px solid white`
          }
        ></StyledHRPropColour>

        <FlexRowCont100PercentSpaceBetween display={itemPropertyDisplay}>
          <FixedItemPropertyDiv
            onClick={() => {
              handleItemClick(quoteItemObj, "Quantity");
            }}
          >
            <TinyHeaderDarkGrey>{header3title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey mobiledisplay={header3Display}>
              {header3}
            </TinyHeaderDarkGrey>
            <TextInputProp
              inputmargintop="5px"
              mobilewidth="18.5vw"
              mobiledisplay={header3InputDisplay}
              type="number"
              onBlur={(e) => {
                OutputValue(e.target.valueAsNumber);
              }}
              defaultValue={quoteItemObj?.Quant}
            ></TextInputProp>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header4title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header4}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv
            onClick={() => {
              handleItemClick(quoteItemObj, "Removal Quantity");
              console.log(quoteItemObj);
            }}
          >
            <TinyHeaderDarkGrey>{header5title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header5}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>
        </FlexRowCont100PercentSpaceBetween>

        <StyledHRPropColour
          display={itemHRDisplay}
          mobiledisplay={itemHRDisplay}
          border={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? `0.25px solid ${ColorPalette.LightGreen}`
              : `0.25px solid white`
          }
        ></StyledHRPropColour>

        <FlexRowCont100PercentSpaceBetween display={itemPropertyDisplay}>
          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey></TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header6title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header6}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv
            onClick={() => {
              if (!quoteItemObj?.ToolName) {
                handleItemClick(quoteItemObj, "Install");
              } else {
                handleItemClick(quoteItemObj, "Rental Hours");
              }
            }}
          >
            <TinyHeaderDarkGrey>{header7title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header7}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>
        </FlexRowCont100PercentSpaceBetween>
      </BackgroundColorDiv>

      {/* <StyledHRPropColour
        border={`0.5px solid ${ColorPalette.LightBlack}`}
        mobiledisplay={
          parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
            ? "none"
            : "block"
        }
      ></StyledHRPropColour> */}

      {DeliveryItems.map((o, i) => {
        return (
          <FlexColumnFullWidth
            margin="0"
            key={i}
            mobiledisplay={showRelatedItems ? "block" : "none"}
          >
            <FlexDiv>
              <MobileEstimateQuoteItem
                header1="Delete"
                key={i}
                RefreshComponentState={() => {
                  RefreshComponentState();
                }}
                deleteItemFromEstimate={() => {
                  deleteItemFromEstimate(o.Id);
                }}
                quoteItemObj={o}
                CategoryType={"Delivery"}
                header2={o.Notes}
                header3={o?.Quant + " Load(s)"}
                header4={`$${o.BasePrice.toFixed(2).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}`}
                header8={
                  "$" + o.Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              ></MobileEstimateQuoteItem>
              <Dropdown
                currentPage={"deliveryItemCard"}
                deleteItem={() => {
                  deleteItemFromEstimate(o.Id);
                }}
              ></Dropdown>
            </FlexDiv>
          </FlexColumnFullWidth>
        );
      })}

      {RemovalItems.map((o, i) => {
        return (
          <FlexColumnFullWidth
            margin="0"
            key={i}
            mobiledisplay={showRelatedItems ? "block" : "none"}
          >
            <MobileEstimateQuoteItem
              header1="Delete"
              quoteItemObj={o}
              RefreshComponentState={() => {
                RefreshComponentState();
              }}
              CategoryType={CategoryTypes.Removal}
              deleteItemFromEstimate={() => {
                deleteItemFromEstimate(o.Id);
              }}
              header2={o.Name}
              header3={
                typeof o?.Quant === "number" && !isNaN(o.Quant)
                  ? o.Quant.toFixed(2) + " " + o.DisplayUnit
                  : ""
              }
              header4={
                "$" + ((o.Price + o.LaborSubtotal) / o?.Quant).toFixed(4)
              }
              // header5={
              //   "$" +
              //   (o.Price + o.LaborSubtotal)
              //     .toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // }
              header5={o.removalTime}
              header8={
                "$" +
                (o.Price + o.LaborSubtotal)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              header3title={"Quantity"}
              header4title={"Removal Rate"}
            ></MobileEstimateQuoteItem>
          </FlexColumnFullWidth>
        );
      })}

      {quoteItemObj?.RelatedItems?.map((o, i) => {
        if (o.CateType !== CategoryTypes.Labor) {
          return (
            <FlexColumnFullWidth
              margin="0"
              //   display={
              //     parentid !== 0 &&
              //     quoteItemObj?.CateType === CategoryTypes.Material
              //       ? "block"
              //       : itemPropertyDisplay
              //   }
              mobiledisplay={showRelatedItems ? "block" : "none"}
            >
              <MobileEstimateQuoteItem
                quoteItemObj={o}
                key={i}
                parentid={o.Parent_Id}
                noChange={o.NoChange}
                header2={o.Name}
                RefreshComponentState={() => {
                  RefreshComponentState();
                }}
                showRelatedItems={showRelatedItems}
                subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                subheader2={o.Description == null ? "" : o.Description}
                header3={
                  typeof o?.Quant === "number" && !isNaN(o.Quant)
                    ? o.Quant.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " " +
                      o.DisplayUnit
                    : "0.00 " + o.DisplayUnit
                }
                header4={
                  "$" +
                  o.UnitPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header6={
                  "$" +
                  (o.LaborSubtotal / o?.Quant)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header7={decimalHoursToTimeString(o.LaborSubtotal / hourlyRate)}
                header8={
                  "$" +
                  (
                    parseFloat(o.Price.toFixed(2)) +
                    parseFloat(o.LaborSubtotal.toFixed(2))
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header3title="Quantity"
                header4title="Material Rate"
                header5title="Install Rate"
                header7title="Install Time"
              ></MobileEstimateQuoteItem>
            </FlexColumnFullWidth>
          );
        } else {
          return null;
        }
      })}

      {/* <MobileEstimateQuoteItem header8={header8} isHeader={true}>
      </MobileEstimateQuoteItem> */}
    </div>
  );
};

MobileEstimateQuoteItem.defaultProps = {
  header1: "",
  header2: "",
  subheader2: "",
  header3: "",
  header3title: "",
  header4: "",
  header4title: "",
  header4input: "",
  header5: "",
  header5title: "",
  header6: "",
  header6title: "",
  header7: "",
  header7title: "",
  header8: "",
  showRelatedItems: false,
  bgColor: ColorPalette.White,
  inputheaderposition: 0,
  OutputValue: () => {},
  RemoveItemFromLocalQuoteObject: () => {},
  RefreshComponentState: () => {},
  onToggleDisplay: () => {},
  deleteItemFromEstimate: () => {},
  adjustAccess: () => {},
  hourlyRate: 0,
  CategoryType: CategoryTypes.Material,
  DeliveryItems: [],
  RemovalItems: [],
};

export default MobileEstimateQuoteItem;
