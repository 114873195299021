import React from 'react';
import styled from 'styled-components';
import { ColorPalette } from '../../StylesheetComps/Colors';

const StyledIconButton = styled.div`
  min-width: ${(props) => (props.minwidth ? props.minwidth : "95px")};
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "30px")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "12px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 2px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "2.5")};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  pointer-events: ${(props) => (props.pointerevent ? props.pointerevent : "")};
  border-radius: 10px;
  transition: 0.3s;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center both icon and text */

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);

  &:hover {
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  }
`;

const Icon = styled.img`
  height: ${(props) => (props.iconheight ? props.iconheight : "20px")};
  width: ${(props) => (props.iconwidth ? props.iconwidth : "20px")};
  margin-right: 8px; /* Consistent spacing between icon and text */
  flex-shrink: 0; /* Prevent the icon from shrinking */
`;

const TextContainer = styled.span`
  flex-grow: 1; /* Ensures consistent alignment of text */
  text-align: center; /* Keeps the text centered */
`;

const IconButton = ({
    src,
    alt,
    text,
    minwidth,
    width,
    height,
    color,
    bgcolor,
    fontsize,
    margin,
    margintop,
    marginbottom,
    marginright,
    marginleft,
    lineheight,
    cursor,
    pointerevent,
    hoveropacity,
    display,
    mobilewidth,
    mobiledisplay,
    iconheight,
    iconwidth,
    children,
    ...props
  }) => {
    return (
      <StyledIconButton
        minwidth={minwidth}
        width={width}
        height={height}
        color={color}
        bgcolor={bgcolor}
        fontsize={fontsize}
        margin={margin}
        margintop={margintop}
        marginbottom={marginbottom}
        marginright={marginright}
        marginleft={marginleft}
        lineheight={lineheight}
        cursor={cursor}
        pointerevent={pointerevent}
        hoveropacity={hoveropacity}
        display={display}
        mobilewidth={mobilewidth}
        mobiledisplay={mobiledisplay}
        {...props}
      >
        {src && <Icon src={src} alt={alt || "icon"} iconheight={iconheight} iconwidth={iconwidth} />}
        <TextContainer>{text || children}</TextContainer>
      </StyledIconButton>
    );
  };  

export default IconButton;
