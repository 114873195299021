// /* eslint-disable */

// import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
// import { useHistory, useParams } from "react-router-dom";
// import {
//     GetCityList,
//     GetMetroList,
// } from "../../../API_Functions/Utility_Functions";
// import { Header } from "../../../StylesheetComps/AuthSheet";
// import {
//     FlexColumnLeftAlign,
//     FlexRowCont,
//     FlexRowContAutox100PercentMobileColumn
// } from "../../../StylesheetComps/Cont";
// import {
//     DatalistMedium,
//     DatalistOption,
//     EstimatorDiv,
//     GreenNavigationButton,
//     GreyNavigationButton,
//     Section1,
//     TextAreaRegular,
//     TextInputReusable
// } from "../../../StylesheetComps/LandscapeForm";
// import { FlexColumnCont } from "../../../StylesheetComps/Menu";
// import {
//     LeagueSpartanTiny,
//     RegularTextBoldRed
// } from "../../../StylesheetComps/Titles";
// import { AddCaseOBO, SetCaseTo_Submit } from "../../../API_Functions/CaseQuote_Functions";
// import { GetStoreByMetro } from "../../../API_Functions/Store_Functions";
// // CSS for modal
// const modalStyles = {
//   position: 'fixed',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   backgroundColor: '#FFF',
//   padding: '50px',
//   height: '90vh',
//   zIndex: 1000,
//   overflowY: 'auto', // This line allows the content to be scrollable if it overflows
//   WebkitOverflowScrolling: 'touch', // This line allows smooth scrolling on iOS devices
// };


// const overlayStyles = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, .7)',
//     zIndex: 1000,
// };

// const QuickProjectForm = ({ open, onNext, onBack, onPreview, onPersonalInfo, onClose }) => {

//     const [isModalOpen, setIsModalOpen] = useState(true);
//     const history = useHistory();
//     const params = useParams();
//     const projectInfoMargin = "30px";
//     const mobileHeaderWidth = "90px";

//     const [Title, setTitle] = useState("");
//     const [homeownerUsername, setHomeownerUsername] = useState("");
//     const [cityList, setCityList] = useState([]);
//     const [metroList, setMetroList] = useState([]);
//     const [ErrorText, setErrorText] = useState("");
//     const [firstName, setFirstName] = useState("");
//     const [name, setName] = useState("");
//     const [isSubmitting, setIsSubmitting] = useState(false);

//     let newProjectObj = useRef({
//         FullName: "",
//         Email: "",
//         StreetAddress:"",
//         City: "",
//         City_Id: 0,
//         Region: "",
//         Metro_Id: 0,
//         Phone: "",
//         PropertyType:"",
//         Details:"",
//         RequestName: ""
//     });

//     const GetLocationInfo = async () => {
//         let metrodata = await GetMetroList();
//         let citydata = await GetCityList(newProjectObj.current.Metro_Id);
//         setCityList(citydata);
//         setMetroList(metrodata);
//     };

//     const UpdateCityList = async (metroid) => {
//         if (metroid !== 0 && metroid !== 3) {
//             newProjectObj.current.City = "";
//             newProjectObj.current.City_Id = 0;
//             let citydata = await GetCityList(metroid);
//             setCityList(citydata);
//         }
//     };

//     useEffect(() => {
//         GetLocationInfo();
//     }, []); // eslint-disable-line react-hooks/exhaustive-deps

//     // const DefinePersonalInfo = async () => {
//     //     const D = new Date();
//     //     const currentYear = D.getFullYear();
//     //     const currentMonth = ("0" + (D.getMonth() + 1)).slice(-2);
//     //     const currentDay = ("0" + D.getDate()).slice(-2);
//     //     const currentHour = ("0" + D.getHours()).slice(-2);
//     //     const currentMinute = ("0" + D.getMinutes()).slice(-2);   
//     //     newProjectObj.current.Name = firstName + " " + lastName;
//     //     if (
//     //         firstName !== null &&
//     //         firstName !== "" &&
//     //         lastName !== null &&
//     //         lastName !== "" &&
//     //         newProjectObj.current.Name !== null &&
//     //         newProjectObj.current.Name !== "" &&
//     //         newProjectObj.current.Street !== null &&
//     //         newProjectObj.current.Street !== "" &&
//     //         newProjectObj.current.CityId !== 0 &&
//     //         newProjectObj.current.Phone !== null &&
//     //         newProjectObj.current.Phone !== "" &&
//     //         newProjectObj.current.Email !== null &&
//     //         newProjectObj.current.Email !== "" &&
//     //         Title !== null &&
//     //         Title !== "" &&
//     //         newProjectObj.current.Metro_Id !== 0 &&
//     //         newProjectObj.current.Metro_Id !== 3
//     //     ) {
//     //         newProjectObj.current.Tittle = Title
//     //         newProjectObj.current.CreateTime = (currentYear +
//     //             "-" +
//     //             currentMonth +
//     //             "-" +
//     //             currentDay +
//     //             "T" +
//     //             currentHour +
//     //             ":" +
//     //             currentMinute);
//     //         newProjectObj.current.UpdateTime = (currentYear +
//     //             "-" +
//     //             currentMonth +
//     //             "-" +
//     //             currentDay +
//     //             "T" +
//     //             currentHour +
//     //             ":" +
//     //             currentMinute);
//     //         newProjectObj.current.Description = ""
//     //         newProjectObj.current.ImageUrl = []
//     //         const stores = await GetStoreByMetro(newProjectObj.current.Metro_Id)
//     //         if(stores !== null){
//     //         newProjectObj.current.Store_Id = stores[0].Id
//     //         const data = await AddCaseOBO(newProjectObj.current, homeownerUsername )
//     //         if(data){
//     //             const submit = await SetCaseTo_Submit(data.Case.Id)
//     //             if(submit !== null){
//     //                 onClose()
//     //             }
//     //         } else {
//     //             setErrorText("Username not found");
//     //         }
//     //         }
      
//     //     } else {
//     //         setErrorText("Please fill in all of the boxes");
//     //         window.scrollTo(0, 0);
//     //     }
//     // };



//     const DefinePersonalInfo = async () => {
//       if (isSubmitting) return; // Prevent further clicks if already submitting
  
//       setIsSubmitting(true); // Disable button on click
//       try {
//         newProjectObj.current.RequestName = JSON.parse(sessionStorage.getItem("userInfo")).Name;
//         const automationData = newProjectObj.current;
  
//         await axios.post(window.$DBURL + '/users/forwardToZapier', automationData);
//         history.push('/home');
//       } catch (error) {
//         console.error("Error sending data to backend:", error);
//       } finally {
//         setIsSubmitting(false); // Re-enable if necessary (optional)
//       }
//     };
  

//     const SelectCity = async (e) => {
//       newProjectObj.current.City_Id = e.target.value;
  
//       const selectedCity = cityList.find(o => o.Id === Number(e.target.value));
      
//       if (selectedCity) {
//           newProjectObj.current.City = selectedCity.Name;
//       }
//   };
  

//     const SelectMetro = async (e) => {
//       newProjectObj.current.Metro_Id = e.target.value;
      
//       const selectedMetro = metroList.find(o => o.Id === Number(e.target.value));
      
//       if (selectedMetro) {
//           newProjectObj.current.Region = selectedMetro.Name;
//       } else {
//       }
//   };
  

//     return (
//         <>
//             {isModalOpen && (
//                 <div style={overlayStyles}>
//                     <div style={modalStyles}>
//                         <EstimatorDiv>
//                         <Section1>
//           <Header fontsize="22px" margintop="0%">
//           Create a Quick Project on Behalf of a Homeowner:
//           </Header>
//           <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>
    
//           <FlexRowContAutox100PercentMobileColumn>
          
//                <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="name">
//                 Name
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 id="name"
//                 placeholder={"Full Name"}
//                 defaultValue={name}
//                 onChange={(e) => {
//                   newProjectObj.current.FullName = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="address">Address</LeagueSpartanTiny>
//               <TextInputReusable
//                 id="address"
//                 placeholder={"Address"}
//                 onChange={(e) => {
//                   newProjectObj.current.StreetAddress = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowContAutox100PercentMobileColumn>
    
//           <FlexRowContAutox100PercentMobileColumn>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="cellphone">
//                 Phone Number
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 type="tel"
//                 id="cellphone"
//                 placeholder={"Cell Phone"}
//                 defaultValue={newProjectObj.current.Phone}
//                 onChange={(e) => {
//                   newProjectObj.current.Phone = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="emailaddress">
//                 Email Address
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 type="email"
//                 id="emailaddress"
//                 placeholder={"Email Address"}
//                 defaultValue={newProjectObj.current.Email}
//                 onChange={(e) => {
//                   newProjectObj.current.Email = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowContAutox100PercentMobileColumn>

//           <FlexRowContAutox100PercentMobileColumn>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="type">
//                 Home Type (Single Family, Townhouse, etc.)
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 id="type"
//                 placeholder={"Home Type"}
//                 defaultValue={newProjectObj.current.PropertyType}
//                 onChange={(e) => {
//                   newProjectObj.current.PropertyType = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="details">
//                 Notes
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 type="email"
//                 id="details"
//                 placeholder={"Notes"}
//                 defaultValue={newProjectObj.current.Details}
//                 onChange={(e) => {
//                   newProjectObj.current.Details = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowContAutox100PercentMobileColumn>
    
    
//           <FlexRowContAutox100PercentMobileColumn>
//           <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="metros">Region</LeagueSpartanTiny>
//               <DatalistMedium
//                 defaultValue={newProjectObj.current.Region}
//                 id="metros"
//                 onChange={(e) => {
//                   SelectMetro(e);
//                   UpdateCityList(e.target.value);
//                 }}
//               >
//                 <DatalistOption value={0} >Select Region</DatalistOption>
//                 {metroList.map((o, i) => (
//                   <DatalistOption
//                     key={o.Id}
//                     value={o.Id}
//                     defaultValue={o.Id === newProjectObj.current.Metro_Id}
//                     selected={o.Id === newProjectObj.current.Metro_Id}
//                   >
//                     {o.Name}
//                   </DatalistOption>
//                 ))}
//               </DatalistMedium>
//             </FlexColumnLeftAlign>

//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="cities">City</LeagueSpartanTiny>
    
//               <DatalistMedium
//                 defaultValue={newProjectObj.current.City}
//                 id="cities"
//                 onChange={(e) => {
//                   SelectCity(e);
//                 }}
//               >
//                 <DatalistOption value={0}>Select City</DatalistOption>
//                 {cityList.map((o, i) => (
//                   <DatalistOption
//                     id="city"
//                     defaultValue={o.Id === newProjectObj.current.City_Id}
//                     selected={o.Id === newProjectObj.current.City_Id}
//                     key={o.Id}
//                     value={o.Id}
//                   >
//                     {o.Name}
//                   </DatalistOption>
//                 ))}
//               </DatalistMedium>
//             </FlexColumnLeftAlign>

//           </FlexRowContAutox100PercentMobileColumn>
    
//           <br></br>
    
//           <FlexColumnCont
//             height="8%"
//             mobilewidth="auto"
//             margin="0"
//             margintop="auto"
//             flexdirection="row"
//             justifycontent="space-around"
//             width="100%"
//           >
//             <GreyNavigationButton
//               onClick={() => {
//                 onClose();
//               }}
//             >
//               Close
//             </GreyNavigationButton>
//             <GreenNavigationButton
//             disabled={isSubmitting}
//               onClick={() => {
//                 DefinePersonalInfo();
//               }}
//             >
//               Submit
//             </GreenNavigationButton>
//           </FlexColumnCont>
//         </Section1>
//                         </EstimatorDiv>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
//             }

// QuickProjectForm.defaultProps = {
//     onChange: () => {},
//     onPreview: () => {},
//     onClose: () => {},
//     onPersonalInfo: () => {},
//     fullName: "",
//     addressofUser: "",
//     emailofUser: "",
//     phoneofUser: "",
//   };

// export default QuickProjectForm;

/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  GetCityList,
  GetMetroList,
} from "../../../API_Functions/Utility_Functions";
import { AddCaseOBO, SetCaseTo_Submit } from "../../../API_Functions/CaseQuote_Functions";
import { GetStoreByMetro } from "../../../API_Functions/Store_Functions";

// Keep this import as requested:
import { EstimatorDiv } from "../../../StylesheetComps/LandscapeForm";
import IconButton from "../../IconButton";
import { ColorPalette } from "../../../StylesheetComps/Colors";

/**
 * 
 * Apple-Inspired Minimal Styling
 * 
 * By design, Apple likes a clean, spacious, and slightly translucent or 
 * well-contrasted look, with subtle shadows, and minimal borders. 
 * The following components are an example approach.
 * 
 */

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #ccc;
  border-top: 3px solid #007aff;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Overlay for the modal (dimming background)
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

// Centered container for the modal
const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 90%;
  max-width: 600px;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
  padding: 40px;
  padding-top: 120px;
  padding-bottom: 120px;
  z-index: 1001;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  /* By default on desktop, let height be as big as content requires */
  height: auto;
  max-height: 90vh; 
    /* This ensures it won't exceed the viewport height on large screens, 
       but normally it'll size to fit content. Remove if you don't want any cap. */

  /* On mobile, force it to be 90vh */
  @media (max-width: 600px) {
    height: 90vh;
  }
`;


// Basic heading style
const AppleHeading = styled.h2`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #000;
  font-size: 22px;
  margin: 0 0 24px 0;
`;

// Error text in red
const ErrorText = styled.p`
  color: #d92b2b;
  font-size: 14px;
  margin-bottom: 20px;
`;

// Flex row container (for your form rows)
const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Column to hold label + input
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 45%;
`;

// Label styled
const AppleLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #333;
`;

// Apple-like input style
const AppleInput = styled.input`
  font-size: 16px;
  padding: 10px 12px;
  border: 1px solid #ccd1d9;
  border-radius: 8px;
  background-color: #fdfdfd;
  margin-bottom: 2px;

  &:focus {
    outline: none;
    border-color: #007aff; // Apple's signature blue
    box-shadow: 0 0 0 2px rgba(0,122,255,0.2);
  }
`;

// Apple-like select (datalist replacement)
const AppleSelect = styled.select`
  font-size: 16px;
  padding: 10px 12px;
  border: 1px solid #ccd1d9;
  border-radius: 8px;
  background-color: #fdfdfd;

  &:focus {
    outline: none;
    border-color: #007aff;
    box-shadow: 0 0 0 2px rgba(0,122,255,0.2);
  }
`;

// Container for the buttons
const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 30px;
`;

// Grey Button
const GreyButton = styled.button`
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #e2e2e2;
  }

  &:active {
    background-color: #ccc;
  }
`;

// Green Button
const GreenButton = styled.button`
  background-color: #34c759;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #28a745;
  }

  &:active {
    background-color: #218838;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

/**
 * The QuickProjectForm Component
 */
const QuickProjectForm = ({ open, onNext, onBack, onPreview, onPersonalInfo, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const history = useHistory();
  const params = useParams();

  const [Title, setTitle] = useState("");
  const [homeownerUsername, setHomeownerUsername] = useState("");
  const [cityList, setCityList] = useState([]);
  const [metroList, setMetroList] = useState([]);
  const [ErrorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  let newProjectObj = useRef({
    FullName: "",
    Email: "",
    StreetAddress: "",
    City: "",
    City_Id: 0,
    Region: "",
    Metro_Id: 0,
    Phone: "",
    PropertyType: "",
    Details: "",
    RequestName: "",
  });

  // Fetch location info on mount
  const GetLocationInfo = async () => {
    let metrodata = await GetMetroList();
    // If newProjectObj.current.Metro_Id was set, load city data
    let citydata = [];
    if (newProjectObj.current.Metro_Id !== 0) {
      citydata = await GetCityList(newProjectObj.current.Metro_Id);
    }
    setMetroList(metrodata);
    setCityList(citydata);
  };

  // Update city list if region changes
  const UpdateCityList = async (metroid) => {
    if (metroid !== 0 && metroid !== 3) {
      newProjectObj.current.City = "";
      newProjectObj.current.City_Id = 0;
      let citydata = await GetCityList(metroid);
      setCityList(citydata);
    }
  };

  useEffect(() => {
    GetLocationInfo();
    // eslint-disable-next-line
  }, []);

  const SelectCity = (e) => {
    newProjectObj.current.City_Id = e.target.value;
    const selectedCity = cityList.find(
      (o) => o.Id === Number(e.target.value)
    );
    if (selectedCity) {
      newProjectObj.current.City = selectedCity.Name;
    }
  };

  const SelectMetro = (e) => {
    newProjectObj.current.Metro_Id = e.target.value;
    const selectedMetro = metroList.find(
      (o) => o.Id === Number(e.target.value)
    );
    if (selectedMetro) {
      newProjectObj.current.Region = selectedMetro.Name;
    }
  };

  /**
   *  Submits to Zapier (or your endpoint) then navigates away
   */
  const DefinePersonalInfo = async () => {
    if (isSubmitting) return; // Prevent multiple clicks if already submitting

    setIsSubmitting(true); 
    try {
      // We assume user info is stored in sessionStorage (per your logic).
      newProjectObj.current.RequestName = JSON.parse(
        sessionStorage.getItem("userInfo")
      )?.Name;

      const automationData = newProjectObj.current;
      // Send to your backend
      await axios.post(window.$DBURL + "/users/forwardToZapier", automationData);

      // On success, navigate to home or close the modal
      history.push("/home");
    } catch (error) {
      console.error("Error sending data to backend:", error);
      setErrorMsg("An error occurred, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {open && (
        <ModalOverlay>
          <ModalContainer>
            {/* Keep the EstimatorDiv as requested */}
            {/* <EstimatorDiv> */}
              <AppleHeading>Create a Project for a Homeowner</AppleHeading>
              {ErrorMsg && <ErrorText>{ErrorMsg}</ErrorText>}

              {/* 
                We group form fields in rows for a more Apple-like minimal layout
              */}

              {/* FULL NAME + ADDRESS */}
              <FormRow>
                <FormColumn>
                  <AppleLabel htmlFor="name">Full Name</AppleLabel>
                  <AppleInput
                    id="name"
                    placeholder="Full Name"
                    onChange={(e) => {
                      newProjectObj.current.FullName = e.target.value;
                    }}
                  />
                </FormColumn>

                <FormColumn>
                  <AppleLabel htmlFor="address">Address</AppleLabel>
                  <AppleInput
                    id="address"
                    placeholder="Address"
                    onChange={(e) => {
                      newProjectObj.current.StreetAddress = e.target.value;
                    }}
                  />
                </FormColumn>
              </FormRow>

              {/* PHONE + EMAIL */}
              <FormRow>
                <FormColumn>
                  <AppleLabel htmlFor="phone">Phone Number</AppleLabel>
                  <AppleInput
                    id="phone"
                    type="tel"
                    placeholder="Phone Number"
                    onChange={(e) => {
                      newProjectObj.current.Phone = e.target.value;
                    }}
                  />
                </FormColumn>

                <FormColumn>
                  <AppleLabel htmlFor="email">Email Address</AppleLabel>
                  <AppleInput
                    id="email"
                    type="email"
                    placeholder="Email Address"
                    onChange={(e) => {
                      newProjectObj.current.Email = e.target.value;
                    }}
                  />
                </FormColumn>
              </FormRow>

              {/* PROPERTY TYPE + DETAILS */}
              <FormRow>
                <FormColumn>
                  <AppleLabel htmlFor="type">
                    Home Type (Single Family, Townhouse, etc.)
                  </AppleLabel>
                  <AppleInput
                    id="type"
                    placeholder="Home Type"
                    onChange={(e) => {
                      newProjectObj.current.PropertyType = e.target.value;
                    }}
                  />
                </FormColumn>

                <FormColumn>
                  <AppleLabel htmlFor="details">Notes</AppleLabel>
                  <AppleInput
                    id="details"
                    placeholder="Notes"
                    onChange={(e) => {
                      newProjectObj.current.Details = e.target.value;
                    }}
                  />
                </FormColumn>
              </FormRow>

              {/* REGION + CITY */}
              <FormRow>
                <FormColumn>
                  <AppleLabel htmlFor="metros">Region</AppleLabel>
                  <AppleSelect
                    id="metros"
                    onChange={(e) => {
                      SelectMetro(e);
                      UpdateCityList(e.target.value);
                    }}
                  >
                    <option value={0}>Select Region</option>
                    {metroList.map((metro) => (
                      <option
                        key={metro.Id}
                        value={metro.Id}
                        selected={metro.Id === newProjectObj.current.Metro_Id}
                      >
                        {metro.Name}
                      </option>
                    ))}
                  </AppleSelect>
                </FormColumn>

                <FormColumn>
                  <AppleLabel htmlFor="cities">City</AppleLabel>
                  <AppleSelect
                    id="cities"
                    onChange={(e) => SelectCity(e)}
                  >
                    <option value={0}>Select City</option>
                    {cityList.map((city) => (
                      <option
                        key={city.Id}
                        value={city.Id}
                        selected={city.Id === newProjectObj.current.City_Id}
                      >
                        {city.Name}
                      </option>
                    ))}
                  </AppleSelect>
                </FormColumn>
              </FormRow>

              {/* BUTTONS */}
              <ButtonRow>
                <IconButton bgcolor={ColorPalette.PrimaryGrey} onClick={() => {
                    setIsModalOpen(false);
                    onClose();
                  }}>Close</IconButton>
                  {isSubmitting ? (
                <SpinnerContainer>
                  <Spinner />
                  <span>Submitting...</span>
                </SpinnerContainer>
              ) : (
                <IconButton
                  onClick={DefinePersonalInfo}
                  src="/whiteplus_icon.svg"
                >
                  Submit
                </IconButton>
              )}
              </ButtonRow>
            {/* </EstimatorDiv> */}
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
};

QuickProjectForm.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onClose: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default QuickProjectForm;


