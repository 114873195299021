import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon30px20pxPointer } from '../../../StylesheetComps/Quote';
import { Icon40x40Percent } from '../../../StylesheetComps/Imgs/Icons';
import { GreenNavigationButton } from '../../../StylesheetComps/LandscapeForm';
import { RegularResponsiveButton } from '../../../StylesheetComps/Components';
import { ColorPalette } from '../../../StylesheetComps/Colors';
import { FlexDiv } from '../../../StylesheetComps/Cont';
import IconButton from '../../IconButton';
import { FlexColumnDiv } from '../../../StylesheetComps/Div';

const GridOverlayContainer = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  grid-template-columns: repeat(2, 1fr); /* Adjusted to 2 columns for a 2x2 grid */
  grid-gap: 16px;
  position: absolute;
  width: 17.5vw;
  height: auto;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  padding: 16px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  // top: -30vh;
  bottom: -97px;
  left: -7px;
  background: rgba(0, 0, 0, 0.5);
  z-Index: 9999;
  padding: 16px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1006px) {
    width: 93.5vw;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto; /* Center each grid item */
  background: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
  border-radius: 4px; /* Rounded corners for a modern look */
  padding: 30px; /* Increased padding for better spacing */
  width: calc(50% - 24px); /* Adjust width to fit in grid taking padding and gap into account */
  height: auto; /* Height can be auto to maintain aspect ratio */
  text-align: center;
`;

// Main GridOverlay functional component
const GridOverlay = ({ isOpen, onGridClick, showDialog, closeWindow }) => {

  const [isEstimateShownHere, setIsEstimateShownHere] = useState(false);

  const [minButtonWidth, setMinButtonWidth] = useState('75px');


  return (
    <GridOverlayContainer isOpen={isOpen}>
      <FlexColumnDiv>
        <FlexDiv>
        <IconButton minwidth={'115px'} bgcolor={ColorPalette.PrimaryBlue} src='/texturewhite_plus.svg' onClick={() => {onGridClick('Material')}} text={'Material'}></IconButton>
        <IconButton minwidth={'115px'} bgcolor={ColorPalette.PrimaryRed} src='/texturewhite_minus.svg' onClick={() => {onGridClick('Removal')}} text={'Removals'}></IconButton>
        </FlexDiv>
        <FlexDiv>
        <IconButton minwidth={'115px'} bgcolor={ColorPalette.PrimaryYellow} src='/fencewhite_icon.svg' onClick={() => {onGridClick('Access')}} text={'Access'}></IconButton>
        <IconButton minwidth={'115px'} bgcolor={ColorPalette.PrimaryOrange} src='/customwhite_icon.svg' onClick={() => {onGridClick('Open')}} text={'Custom'}></IconButton>
        </FlexDiv>
        {/* <RegularResponsiveButton onClick={() => {onGridClick('Material')}} minwidth={minButtonWidth}  bgcolor={ColorPalette.PrimaryButtonBlue}>Material</RegularResponsiveButton>
        <RegularResponsiveButton onClick={() => {onGridClick('Removal')}} minwidth={minButtonWidth} bgcolor={ColorPalette.PrimaryRed}>Removals</RegularResponsiveButton>
        <RegularResponsiveButton onClick={() => {onGridClick('Access')}} minwidth={minButtonWidth} bgcolor={ColorPalette.PrimaryYellow}>Access</RegularResponsiveButton>
        <RegularResponsiveButton onClick={() => {onGridClick('Open')}} minwidth={minButtonWidth} bgcolor={ColorPalette.PrimaryOrange}>Custom</RegularResponsiveButton> */}
      </FlexColumnDiv>
    </GridOverlayContainer>
  );
};

export default GridOverlay;
