import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GetProduct, GetStoreCategories, GetStoreProducts } from '../../API_Functions/CatProduct_Functions';
import { useParams } from 'react-router-dom';
import { CategoryTypes } from '../../StylesheetComps/CategoryTypes';
import QuoteItemWindow from '../../comps/Windows/QuoteItemWindow';
import QuoteAddOnSelection from '../../comps/Windows/QuoteAddOnSelection';
import { ColorPalette } from '../../StylesheetComps/Colors';
import { LeagueHeader14px } from '../../MobileComponents/Text';
import { BodyPara } from '../../StylesheetComps/AuthSheet';
import { RegularResponsiveButton } from '../../StylesheetComps/Components';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Icon30x40pxPointer } from '../../StylesheetComps/Imgs/Icons';
import { LeagueSpartanRegular, RegularGreenText, TinyTextItalics } from '../../StylesheetComps/Titles';
import { FlexDiv } from '../../StylesheetComps/Cont';
import { FlexCenterDiv100PercentCenter } from '../../StylesheetComps/Div';

// Sample data
const sampleArray = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];

// Styled Components
const PopupBackdrop = styled.div`
  display: ${({visible}) => visible ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const PopupContainer = styled.div`
  position: fixed;
  bottom: ${({visible}) => visible ? '0' : '-100%'};
  left: 0;
  width: 90vw;
  border-radius: 25px 25px 0px 0px;
  height: 95%;
  background: #fff;
  padding: 0px 20px 20px 20px;
  transition: bottom 0.3s ease-out;
  z-index: 999;
  overflow: auto;
`;

const Input = styled.input`
  width: 92%;
  padding: 10px;
  margin-bottom: 20px;
`;

const ListItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  
  &:last-child {
    border-bottom: none;
  }
`;

const AlertBackdrop = styled.div`
  display: ${({visible}) => visible ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`;

const AlertPopup = styled.div`
  display: ${({visible}) => visible ? 'flex' : 'none'};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  z-index: 10001;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:250px;
`;


// Popup Component
const AccessItemPopupWindow = ({visible, accessCallback, removalCallback,
    itemCategory, onClose, squareFeetSM, perimeterSM, AddItemToPolygon, shapeData, plottingCallback, iconCount, plottingSelected}) => {
  const [inputValue, setInputValue] = useState('');
  const [accessCat, setAccessCat] = useState([]);
  const params = useParams();

  const [visibleState, setVisible] = useState(false);
  const [filteredItems, setFilteredItems] = useState(null);


  //QuoteItem Popup
  const [showQuoteItem, setShowQuoteItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddOnsWindowVisible, setIsAddOnsWindowVisible] = useState(false);

  const [item, setItem] = useState({});
  const [primaryItemEstimateId, setPrimaryItemEstimateId] = useState(0);
  const [removalAlert, setRemovalAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // Add this state variable to track the loading state
  const [isAddOnPlottingSelected, setIsAddOnPlottingSelected] = useState(false); 
  


  const FilterItems = (inputValue) => {
    setInputValue(inputValue); // preserve input value
  
    function find(items, text) {
      text = text.toLowerCase().split(" ");
      return items.filter(function (item) {
        return text.every(function (char) {
          return item.Name.toLowerCase().includes(char);
        });
      });
    }
  
    let newFilteredItems = accessCat.map(item => {
      // If item has 'Products', filter the products
      if (item.Products) {
        return {
          ...item,
          Products: find(item.Products, inputValue)
        };
      } 
      // If item doesn't have 'Products', directly filter the items
      else {
        if (item.Name.toLowerCase().includes(inputValue.toLowerCase())) {
          return item;
        }
      }
    }).filter(Boolean); // Remove undefined items from the array (items that didn't pass the filter)
  
    setFilteredItems(newFilteredItems);
  }
  
  const GetItems = async (storeid) => {
    const isAccess = itemCategory === 'Access';
    var data = isAccess ? await GetStoreCategories(storeid) : await GetStoreProducts(storeid);

    // Add error handling here. If data is undefined, return to prevent errors
    if (!data) return;

    const filteredItems = isAccess
        ? data.cate.filter((o) => o.CateType === CategoryTypes[itemCategory] && o.Products !== null)
        : data.products.filter((o) => o.CateType === CategoryTypes[itemCategory]);
    console.log(filteredItems)
    setAccessCat(filteredItems);
    setFilteredItems(filteredItems);
};

const onListItemClick = (item) => {
    if(itemCategory === 'access') {
        accessCallback(item)
    } else if (plottingSelected == true) {
        setSelectedItem(item); // set the selected item
        plottingCallback()
    } else {
        setSelectedItem(item); // set the selected item
        setShowQuoteItem(true); // set the state to true to show the QuoteItemWindow
    }
  };

  const CheckIfProductHasAddons = async (item) => {
    setPrimaryItemEstimateId(item.Id); 
    const data = await GetProduct(item.Product_Id);
    if (data !== null) {
      if (data.Addons.length > 0) {
        setItem(data)
        setIsAddOnsWindowVisible(true);
      }
      if(data.CateType === CategoryTypes.Removal){
        setRemovalAlert(true);
      }
    }
    setShowQuoteItem(false);
  };
  

  useEffect(() => {
    if (itemCategory === 'removals' && filteredItems) {
      setVisible(true);
    }
  }, [filteredItems]);

  useEffect(() => {
    setVisible(visible)
  }, [visible]);

  useEffect(() => {
    GetItems(params.storeId)
  }, [itemCategory]);

  useEffect(() => {
    if(iconCount >= 1 && plottingSelected == true) {
      console.log(selectedItem)
      console.log(iconCount)
      console.log(plottingSelected)
      console.log('addons');
      setIsAddOnPlottingSelected(true)
      setIsAddOnsWindowVisible(true)
    } else if (selectedItem !== null && isAddOnPlottingSelected == true){
      setIsAddOnPlottingSelected(true)
      setIsAddOnsWindowVisible(true)
      // setShowQuoteItem(true);
      console.log(selectedItem)
      console.log(iconCount)
      console.log(plottingSelected)
      console.log('addons')
    } else if (selectedItem !== null && isAddOnPlottingSelected == false) {
      setShowQuoteItem(true);
      console.log(selectedItem)
      console.log(iconCount)
      console.log(plottingSelected)
      console.log('none')
    } else {

    }
  }, [iconCount]);

  return (
    <>
    <Backdrop style={{ zIndex: 999999 }} open={isLoading} onClick={()=>{setIsLoading(false)}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PopupBackdrop visible={visibleState} 
      onClick={() => {onClose()}} 
      />
      <PopupContainer visible={visibleState}>
        <FlexCenterDiv100PercentCenter>
      <Icon30x40pxPointer onClick={() => {onClose()}}  src='/downarrow.svg'></Icon30x40pxPointer>
      </FlexCenterDiv100PercentCenter>
      <LeagueSpartanRegular>Add New Item</LeagueSpartanRegular>
        <Input
          value={inputValue}
          onChange={e => FilterItems(e.target.value)}
          placeholder="Search for Products"
        />
   {filteredItems && (filteredItems.slice(0, 30)).map((item, index) => {
  let itemsToMap = item.Products ? item.Products : [item];
  return itemsToMap.map((o, i) => (
    <ListItem key={i} onClick={() => {onListItemClick(o); setFilteredItems(accessCat); setInputValue('');}}>
      {o.Name}
    </ListItem>
  ))
})}
{showQuoteItem && <QuoteItemWindow iconCount={iconCount} siteMapper={true} squareFeetSM={squareFeetSM} perimeterSM={perimeterSM} open={showQuoteItem} productobj={selectedItem} itemObj={selectedItem} itemId={0} RefreshItemList={(item)=>{CheckIfProductHasAddons(item); AddItemToPolygon(item); setIsLoading(false); }} onClose={() => { setShowQuoteItem(false); setIsLoading(true); 
}} onCloseNoAdd={() => { setShowQuoteItem(false);
}} />}
<QuoteAddOnSelection
iconCount={iconCount}
isAddOnPlottingSelected={isAddOnPlottingSelected}
primaryitemid={primaryItemEstimateId}
squareFeetSM={squareFeetSM} perimeterSM={perimeterSM}
            open={isAddOnsWindowVisible}
            shapeData={shapeData}
            productObj={item}
            siteMapper={true}
            onClose={() => {
              setIsAddOnsWindowVisible(false);
              setIsAddOnPlottingSelected(false)
            }}
            CloseForPlotting={()=>{
              setIsAddOnsWindowVisible(false)
              setIsAddOnPlottingSelected(true)
              plottingCallback()
            }}
            ></QuoteAddOnSelection>
              <AlertBackdrop visible={removalAlert} 
      onClick={() => { setRemovalAlert(false); removalCallback();}} 
    />
            <AlertPopup visible={removalAlert}>
      <LeagueHeader14px>Notice:</LeagueHeader14px>
      <br></br>
      <BodyPara>You've just added a removal, we recommend you add the item that you will be installing in its place.</BodyPara>
      <br></br>
      <RegularResponsiveButton onClick={() => {setRemovalAlert(false); removalCallback()}}>OK</RegularResponsiveButton>
    </AlertPopup>
      </PopupContainer>
    </>
  );
}
  
AccessItemPopupWindow.defaultProps = {
visible:false,
itemCategory: 'Access', // set 'access' as the default category
plottingSelected: false,
accessCallback:()=>{},
removalCallback:()=>{},
onClose:()=>{},
AddItemToPolygon:()=>{},
plottingCallback:()=>{},
squareFeetSM: 0,
perimeterSM: 0,
shapeData:{
    perimeter:0,
    area:0
}
}

export default AccessItemPopupWindow;
