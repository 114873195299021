/* eslint-disable */

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AddCategory,
  DeleteCategory,
  GetCategory,
  GetStoreCategories,
  GetStoreProducts,
} from "../../API_Functions/CatProduct_Functions";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import ActionMenu from "../../comps/ActionMenu";
import CategoryCard from "../../comps/CategoryCard";
import Menu from "../../comps/MenuComps/Menu";
import ProductCard from "../../comps/ProductCard";
import CategoryAdd from "../../comps/Windows/CategoryAdd";
import CategoryEdit from "../../comps/Windows/CategoryEdit";
import DeleteCatWindow from "../../comps/Windows/DeleteCat";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  DivWithDisplay,
  DivWithMarginPadding,
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexDiv,
  GridDiv,
  Grid_300Div,
  LeftCont,
  OverflowDiv,
} from "../../StylesheetComps/Cont";
import {
  FlexDiv70x7Percent,
  GridContainer200pxWidth,
} from "../../StylesheetComps/Div";
import {
  SmallHeaderLeagueSpartan,
  TextInput72Percent,
} from "../../StylesheetComps/LandscapeForm";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { StyledHRGreyBreak } from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { InputField } from "../../StylesheetComps/Inputs";

const ProductCategoryScreen = () => {
  //=============================================================================
  // Product Category Screen - Manager can view / edit Product Categories
  //=============================================================================

  //=============================================================================
  // Click on Category to view Products / Subcategories in this Category.
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const [roleNum, setRoleNum] = useState(1000);
  const [unfilteredProducts, setUnfilteredProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [storeCategories, getStoreCategories] = useState([]);
  const [isEditCatOpen, setisEditCatOpen] = useState(false);
  const [isAddCatOpen, setisAddCatOpen] = useState(false);
  const [catId, setCatId] = useState(0);
  const [delCatOpen, setDelCatOpen] = useState(false);
  const [maxPos, setMaxPos] = useState(0);
  const [category, setCategory] = useState();

  const GetStoreCategoriesFunc = async (category_id) => {
    var data = await GetStoreCategories(category_id);
    if (data != null) {
      getStoreCategories(data.cate);
      setMaxPos(
        Math.max.apply(
          Math,
          data.cate.map(function (o) {
            return o.Position;
          })
        )
      );
      setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
    }
  };

  const GetSingleCat = async (category_id) => {
    var data = await GetCategory(category_id);
    if (data !== null) {
      setCategory(data);
      setisEditCatOpen(true);
    }
  };

  const FilterProducts = (productlist, text) => {
    if (text === "") {
      setProductList([]);
    } else {
      setProductList(
        productlist.filter((o) => {
          return o.Name.toLowerCase().includes(text.toLowerCase());
        })
      );
    }
  };

  const setStoreProducts = async (storeid) => {
    var data = await GetStoreProducts(storeid);
    if (data != null) {
      setUnfilteredProducts(data.products);
    }
  };

  const DeleteCategoryFunc = async (category_id) => {
    var data = await DeleteCategory(category_id);
    if (data === null) {
      GetStoreCategoriesFunc(params.Id);
    }
  };

  const HandleNewCat = async (categoryobj) => {
    categoryobj.Store_Id = params.Id;
    categoryobj.Position = maxPos + 1;
    var data = await AddCategory(categoryobj);
    if (data !== null) {
      GetStoreCategoriesFunc(params.Id);
    }
  };

  useEffect(() => {
    SetHeaderAuthorization();
    if (
      JSON.parse(sessionStorage.getItem("userInfo")).Role === UserRole.User ||
      JSON.parse(sessionStorage.getItem("userInfo")).Role === UserRole.VIP
    ) {
      // GetStoreCategoriesFuncUser();
    } else {
      GetStoreCategoriesFunc(params.Id);
    }
  }, [isEditCatOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStoreProducts(params.Id);
  }, []);

  if (roleNum === UserRole.Admin || roleNum === UserRole.Manager) {
    return (
      <DivWithDisplay flexdirection="row"  >
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

      <FlexColumnFullWidth height="100vh">
        <DivWithDisplay width="100%">
          <DivWithDisplay  >
            <DivWithDisplay alignitems="left" flexdirection='row'>
                            <Icon30px30pxPointer
                            margin="10px"
                src="/backarrow.svg"
                onClick={() => {
                  history.push("/Store/" + params.Id);
                }}
              ></Icon30px30pxPointer>
              <SmallHeaderLeagueSpartan width="auto">
                Product Categories
              </SmallHeaderLeagueSpartan>
              <RegularResponsiveButton
              margin='8px'
                onClick={() => {
                  setisAddCatOpen(true);
                }}
              >
                {" "}
                Add Category{" "}
              </RegularResponsiveButton>
            </DivWithDisplay>
            <DivWithDisplay flexdirection="row">
             
              <InputField
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  FilterProducts(unfilteredProducts, e.target.value);
                }}
                inputwidth="70%"
                inputheight="25%"
                inputborderadius="10px"
                placeholder="Type in product name to search "
              ></InputField>
            </DivWithDisplay>
            <StyledHRGreyBreak></StyledHRGreyBreak>

            <CategoryAdd
              open={isAddCatOpen}
              CatPosProp={maxPos}
              parentId={0}
              onClose={() => {
                setisAddCatOpen(false);
              }}
              onCategoryAdd={HandleNewCat}
            ></CategoryAdd>
            <CategoryEdit
              category={category}
              open={isEditCatOpen}
              onClose={() => {
                setisEditCatOpen(false);
                GetStoreCategoriesFunc(params.Id);
              }}
            ></CategoryEdit>
            <DivWithDisplay flexdirection="column" overflowy="auto">
              {/* <Grid_300Div> */}
                {productList.map((o, i) => (
                  
                    <ProductCard
                      key={i}
                      ProdUrl={o.ImageUrl}
                      ProdId={o.Id}
                      ProdHeadText={o.Name}
                      ProdSuppliers={o.Supplier}
                      ProdPrice={o.UnitPrice}
                      relProduct={() => {
                        history.push(
                          "/productlist/" + params.Id + "/" + o.Category_Id+"/0"
                        );
                      }}
                      relTool={() => {
                        history.push(
                          "/productlist/" + params.Id + "/" + o.Category_Id+"/0"
                        );
                      }}
                      onView={() => {
                        history.push(
                          "/productlist/" + params.Id + "/" + o.Category_Id+"/0"
                        );
                      }}
                    ></ProductCard>
                  
                ))}   
              {/* </Grid_300Div> */}
            </DivWithDisplay>
              <DeleteCatWindow
                deleteOpen={delCatOpen}
                closeDel={() => setDelCatOpen(false)}
                onDeleteFunction={() => {
                  DeleteCategoryFunc(catId);
                }}
              ></DeleteCatWindow>
              
              {storeCategories.map((o, i) => (
                <CategoryCard
                  key={i}
                  catId={o.Id}
                  CatHeadText={o.Name}
                  categoryId={o.Id}
                  CatName={o.Name}
                  CatParaText={o.Description}
                  CatUrl={o.ImageUrl == null ? null : o.ImageUrl}
                  DelCat={() => {
                    setDelCatOpen(true);
                    setCatId(o.Id);
                  }}
                  EditCat={() => {
                    GetSingleCat(o.Id);
                    setCatId(o.Id);
                  }}
                ></CategoryCard>
              ))}
          </DivWithDisplay>
        </DivWithDisplay>
        </FlexColumnFullWidth>
      </DivWithDisplay>
    
    );
  } else {
    return (
      <Backdrop
        style={{ zIndex: 2 }}
        //  className={classes.Backdrop}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

ProductCategoryScreen.defaultProps = {
  ProfileName: "Default Name",
  MetroName: "EXAMPLE Toronto",
};

export default ProductCategoryScreen;
