// /* eslint-disable */

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import styled from "styled-components";
// import ActionMenu from "../../comps/ActionMenu";
// import ChangePass from "../../comps/ChangePass";
// import Menu from "../../comps/MenuComps/Menu";
// import UpdateProfile from "../../comps/UpdateProfile";
// import { UserRole } from "../../StylesheetComps/UserRoles";

// import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
// import {
//   MobileGreenButton,
//   MobileSmallGreenButton,
// } from "../../MobileComponents/Buttons";
// import {
//   Div100vw20vh,
//   FlexColumnDiv90vw,
//   TopDiv40vh,
//   WhiteTopRoundedDiv,
// } from "../../MobileComponents/Divs";
// import { LogoRelativeLeft, TopImage40vh } from "../../MobileComponents/Images";
// import {
//   MenuHeader,
//   WhiteAvenirHeader,
//   WhiteLeagueHeader,
// } from "../../MobileComponents/Text";
// import {
//   FlexColumnCont80vwAutoHeight,
//   FlexColumnLeftAlign,
//   FlexContMobileColumn,
//   FlexContzIndex1,
//   FlexDiv,
//   FlexRowContFullWidth,
//   FullWidthMobileCont100vw100vh,
//   LeftCont,
// } from "../../StylesheetComps/Cont";
// import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
// import { RegularTextBoldRed } from "../../StylesheetComps/Titles";

// const ProfileDiv = styled.div`
//   display: flex;
//   width: 100%;
//   @media (max-width: 1006px) {
//     width: 95%;
//     height: auto;
//     display: flex;
//     flex-direction: column;
//   }
// `;

// const ProfileInfo = styled.div`
//   background-color: white;
//   border-radius: 10px;
//   padding: 2%;
//   margin: 2%;
//   margin-left: auto;
//   margin-right: auto;
//   width: 85%;
//   @media (max-width: 1006px) {
//     display: ;
//     width: 100%;
//     margin: 0%;
//     height: 60%;
//     box-shadow: 0px 0px 0px #d3d3d3;
//   }
// `;

// const UpdatePass = styled.a`
//   background-color: #169ADB;
//   padding: 1%;
//   color: white;
//   width: 8vw;
//   border-radius: 10px;
//   font-family: Futura;
//   font-size: 100%;
//   margin-top: auto;
//   margin-bottom: auto;
//   cursor: pointer;
//   margin: 1%;
//   @media (max-width: 1006px) {
//     width: 50%;
//     margin-top: 20px;
//   }
// `;

// const Clickables = styled.div`
//   display: flex;
//   // height:80%;
//   width: 100%;
//   @media (max-width: 1006px) {
//     flex-direction: column;
//   }
// `;

// const UpdateClickables = styled.div`
//   @media (max-width: 1006px) {
//     flex-direction: column;
//     position: relative;
//     height: 0%;
//     display: ${(props) => (props.changeView ? props.changeView : "none")};
//   }
// `;

// const HeaderDiv = styled.div`
//   display: none;
//   @media (max-width: 1006px) {
//     width: 40%;
//     display: flex;
//     justify-content: space-around;
//   }
// `;

// const UpdateDiv = styled.div`
// display:flex;
// flex-direction:column;
// @media (max-width: 1006px) {
// +}
// `;

// const ProfileScreen = ({ onPreview, onProfileUpdated }) => {
//   //=============================================================================
//   // Profile Screen - User can view and edit their own Profile Information here
//   //=============================================================================

//   //=============================================================================
//   // All users have access to this page via the ActionMenu or Left Menu
//   //=============================================================================

//   const history = useHistory();

//   const [passSwitch, setPassSwitch] = useState(false);
//   const [editProfile, setEditProfile] = useState(false);
//   const [metroSwitch, setMetroSwitch] = useState(false);
//   const [citySwitch, setCitySwitch] = useState(false);

//   const [cpCurrentUsername, setcpCurrentUsername] = useState("");
//   const [cpOldPass, setcpOldPass] = useState("");
//   const [cpNewPass, setcpNewPass] = useState("");
//   const [cpConfirmNewPass, setcpConfirmNewPass] = useState("");
//   const [confirmMessage] = useState("");
//   const [updated] = useState(0);

//   const [passView, setPassView] = useState("none");
//   const [metroView, setMetroView] = useState("none");

//   const [emailValue, setEmailValue] = useState("");
//   const [email, setEmail] = useState("");
//   const [NameValue, setNameValue] = useState("");
//   const [AddressValue, setAddressValue] = useState("");
//   const [CityValue, setCityValue] = useState("");
//   const [UsernameValue, setUsernameValue] = useState("");
//   const [PhoneValue, setPhoneValue] = useState("");
//   const [MetroValue, setMetroValue] = useState("");
//   const [RoleValue, setRoleValue] = useState("");
//   const [userId, setUserId] = useState(0);
//   const [roleNum, setRoleNum] = useState(1000);
//   const [roleName, setRoleName] = useState("");
//   const [ImgUrl, setImgUrl] = useState("");

//   const [userinfo, setUserinfo] = useState({});

//   const [ErrorText, setErrorText] = useState("");

//   const HandlePasswordChange = async ({
//     currentUsername,
//     oldPass,
//     newPass,
//     confirmNewPass,
//     onPreview,
//     onChange,
//   }) => {
//     setcpCurrentUsername(currentUsername);
//     setcpOldPass(oldPass);
//     setcpNewPass(newPass);
//     setcpConfirmNewPass(confirmNewPass);
//   };

//   const HandleProfileUpdated = async ({
//     emailValue2,
//     NameValue2,
//     AddressValue2,
//     PhoneValue2,
//     onPreview,
//     onChange,
//   }) => {
//     setEmailValue(emailValue2);
//     setNameValue(NameValue2);
//     setAddressValue(AddressValue2);
//     setPhoneValue(PhoneValue2);
//   };

//   const ChangePasswordFunc = async (cpOldPass, cpNewPass) => {
//     try {
//       setErrorText("Old Password Incorrect");

//       // Log out from the current session
//       const resplogout = await axios.post(
//         `${window.$DBURL}users/${userId}/session/logout`
//       );

//       // Change the password
//       const resp = await axios.put(`${window.$DBURL}users/password`, {
//         UserName: UsernameValue,
//         OldPassword: cpOldPass,
//         NewPassword: cpNewPass,
//       });

//       if (resp.status === 200) {
//         // Clear the error text
//         setErrorText("");

//         // Delete the current authorization token
//         delete axios.defaults.headers.common["Authorization"];

//         // Log in with the new password
//         const resplogin = await axios.post(
//           `${window.$DBURL}users/session/login`,
//           {
//             Username: UsernameValue,
//             Password: cpNewPass,
//           }
//         );

//         if (resplogin.data !== undefined || resplogin.data !== null) {
//           // Save the new authorization token
//           const token = resplogin.data.Session.AuthToken;
//           axios.defaults.baseURL = window.$DBURL;
//           sessionStorage.setItem("token", token);
//           axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
//         }

//         // Update the UI
//         setPassSwitch(false);
//         setPassView("none");
//         window.scrollTo(0, 0);
//       }
//     } catch (error) {
//       // Handle any errors that may have occurred
//       console.error(error);
//     }
//   };

//   const UpdateAccount = async (email, name, address, phone, image) => {
//     const resp = await axios.put(`${window.$DBURL}users`, {
//       Name: name,
//       Phone: phone,
//       Id: userId,
//       Street: address,
//       Email: email,
//       Username: UsernameValue,
//       Image: image,
//     });
//     GetProfileInfo();
//   };

//   const GetProfileInfo = async () => {
//     const resp = await axios.get(window.$DBURL + "users/profile", {});
//     const { user } = resp.data;

//     sessionStorage.setItem("userInfo", JSON.stringify(user));
//     setUserinfo(JSON.parse(sessionStorage.getItem("userInfo")));

//     setEmail(user.Email);
//     setUserId(user.Id);
//     setEmailValue(user.Email);
//     setUsernameValue(user.Username);
//     setNameValue(user.Name);
//     setAddressValue(user.Street);
//     setCityValue(user.City);
//     setPhoneValue(user.Phone);
//     setMetroValue(user.Metro);
//     setImgUrl(user.Image);

//     switch (JSON.parse(sessionStorage.getItem("userInfo")).Role) {
//       case UserRole.User:
//         setRoleName("Homeowner");
//         break;
//       case UserRole.VIP:
//         setRoleName("VIP Homeowner");
//         break;
//       case UserRole.Worker:
//         setRoleName("Worker");
//         break;
//       case UserRole.OwnerOperator:
//         setRoleName("Owner / Operator");
//         break;
//       case UserRole.Estimator:
//         setRoleName("Guide");
//         break;
//       case UserRole.Installer:
//         setRoleName("Installer");
//         break;
//       case UserRole.Manager:
//         setRoleName("Manager");
//         break;
//       case UserRole.Admin:
//         setRoleName("Admin");
//         break;
//       case UserRole.Accountant:
//         setRoleName("Accountant");
//         break;
//       case UserRole.Supplier:
//         setRoleName("Supplier");
//         break;
//       default:
//         break;
//     }

//     setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
//   };

//   useEffect(() => {
//     SetHeaderAuthorization();
//     GetProfileInfo();
//   }, []);

//   if (window.$ISMOBILE === true) {
//     if (roleNum !== UserRole.Loading) {
//       return (
//         <FlexContzIndex1>
//           <LeftCont>
//             <Menu> </Menu>
//           </LeftCont>

//           <FlexColumnCont80vwAutoHeight>
//             <ActionMenu mobilePageProp="Profile"></ActionMenu>

//             <Div100vw20vh>
//               <TopImage40vh src="/DarkProfileImage2.png"></TopImage40vh>
//             </Div100vw20vh>

//             <TopDiv40vh>
//               <LogoRelativeLeft
//                 src={
//                   userinfo.Image === null
//                     ? "/PersonIcon.svg"
//                     : window.$IMGURL + userinfo.Image
//                 }
//               ></LogoRelativeLeft>
//               <FlexColumnLeftAlign>
//                 <WhiteLeagueHeader>
//                   {userinfo.Name !== null ? userinfo.Name : ""}
//                 </WhiteLeagueHeader>
//                 <WhiteAvenirHeader>{roleName}</WhiteAvenirHeader>
//                 <br></br>
//                 <MobileGreenButton
//                   onClick={() => {
//                     setEditProfile(true);
//                   }}
//                 >
//                   Edit Profile
//                 </MobileGreenButton>
//               </FlexColumnLeftAlign>
//             </TopDiv40vh>

//             <WhiteTopRoundedDiv>
//               <FlexDiv>
//                 <Icon30px30pxPointer
//                   onClick={() => {
//                     history.push("/myaccount");
//                   }}
//                   src="/leftarrow.svg"
//                 ></Icon30px30pxPointer>
//                 <MenuHeader>Profile</MenuHeader>
//                 <MobileSmallGreenButton
//                   onClick={() => {
//                     setPassSwitch(true);
//                     setPassView("block");
//                     window.scrollTo(0, 0);
//                   }}
//                 >
//                   Change Password{" "}
//                 </MobileSmallGreenButton>
//               </FlexDiv>

//               <FlexColumnDiv90vw>
//                 <ProfileDiv>
//                   <UpdateClickables changeView={passView}></UpdateClickables>
//                   <UpdateClickables changeView={metroView}></UpdateClickables>

//                   <ProfileInfo>
//                     <UpdateDiv changeView={passView}>
//                       <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>
//                       <ChangePass
//                         passSwitch={passSwitch}
//                         onClose={() => {
//                           setPassSwitch(false);
//                           setPassView("none");
//                           window.scrollTo(0, 0);
//                         }}
//                         onPasswordChanged={ChangePasswordFunc}
//                         onPreview={HandlePasswordChange}
//                       ></ChangePass>
//                       <br></br>
//                     </UpdateDiv>
//                     <hr></hr>
//                     <Clickables>
//                       <UpdateProfile
//                         editprofile={editProfile}
//                         CallProfileAgain={() => {
//                           GetProfileInfo();
//                         }}
//                         role={roleNum}
//                         imgurl={ImgUrl}
//                         metro={MetroValue}
//                         city={CityValue}
//                         email={emailValue}
//                         name={NameValue}
//                         phone={PhoneValue}
//                         address={AddressValue}
//                         updated={updated}
//                         onProfileUpdated={UpdateAccount}
//                         ChangeCity={() => {
//                           setMetroSwitch(false);
//                           setCitySwitch(true);
//                           setMetroView("block");
//                           window.scrollTo(0, 0);
//                         }}
//                         ChangeMetro={() => {
//                           setCitySwitch(false);
//                           setMetroSwitch(true);
//                           setMetroView("block");
//                           window.scrollTo(0, 0);
//                         }}
//                         onPreview={HandleProfileUpdated}
//                       ></UpdateProfile>
//                     </Clickables>
//                   </ProfileInfo>
//                 </ProfileDiv>
//               </FlexColumnDiv90vw>
//             </WhiteTopRoundedDiv>
//           </FlexColumnCont80vwAutoHeight>
//         </FlexContzIndex1>
//       );
//     } else {
//       return (
//         <Backdrop style={{ zIndex: 2 }} open>
//           <CircularProgress color="inherit" />
//         </Backdrop>
//       );
//     }
//   } else {
//     //Desktop
//     if (roleNum !== UserRole.Loading) {
//       return (
//         <FlexContMobileColumn>
//           <FullWidthMobileCont100vw100vh>
//             <Menu> </Menu>
//           </FullWidthMobileCont100vw100vh>

//           <FlexColumnCont80vwAutoHeight>
//             <ActionMenu mobilePageProp="Profile"></ActionMenu>
//             <FlexRowContFullWidth>
//               <HeaderDiv></HeaderDiv>
//               <p>{confirmMessage}</p>
//               <ProfileDiv>
//                 <UpdateClickables changeView={passView}></UpdateClickables>
//                 <UpdateClickables changeView={metroView}></UpdateClickables>
//                 <ProfileInfo>
//                   <UpdateDiv>
//                     <UpdatePass
//                       onClick={() => {
//                         setPassSwitch(true);
//                         setPassView("block");
//                         window.scrollTo(0, 0);
//                       }}
//                     >
//                       Change Password{" "}
//                     </UpdatePass>
//                     <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>
//                     <ChangePass
//                       passSwitch={passSwitch}
//                       onClose={() => {
//                         setPassSwitch(false);
//                         setPassView("none");
//                         window.scrollTo(0, 0);
//                       }}
//                       onPasswordChanged={ChangePasswordFunc}
//                       onPreview={HandlePasswordChange}
//                     ></ChangePass>
//                     <br></br>
//                   </UpdateDiv>
//                   <hr></hr>
//                   <Clickables>
//                     <UpdateProfile
//                       CallProfileAgain={() => {
//                         GetProfileInfo();
//                       }}
//                       role={roleNum}
//                       imgurl={ImgUrl}
//                       metro={MetroValue}
//                       city={CityValue}
//                       email={emailValue}
//                       name={NameValue}
//                       phone={PhoneValue}
//                       address={AddressValue}
//                       updated={updated}
//                       onProfileUpdated={UpdateAccount}
//                       ChangeCity={() => {
//                         setMetroSwitch(false);
//                         setCitySwitch(true);
//                         setMetroView("block");
//                         window.scrollTo(0, 0);
//                       }}
//                       ChangeMetro={() => {
//                         setCitySwitch(false);
//                         setMetroSwitch(true);
//                         setMetroView("block");
//                         window.scrollTo(0, 0);
//                       }}
//                       onPreview={HandleProfileUpdated}
//                     ></UpdateProfile>
//                   </Clickables>
//                 </ProfileInfo>
//               </ProfileDiv>
//             </FlexRowContFullWidth>
//           </FlexColumnCont80vwAutoHeight>
//         </FlexContMobileColumn>
//       );
//     } else {
//       return (
//         <Backdrop style={{ zIndex: 2 }} open>
//           <CircularProgress color="inherit" />
//         </Backdrop>
//       );
//     }
//   }
// };

// ProfileScreen.defaultProps = {
//   ProfileName: "Default Name",
//   onPreview: () => {},
//   onProfileUpdated: () => {},
// };

// export default ProfileScreen;

/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// Existing / Reused Components
import ActionMenu from "../../comps/ActionMenu";
import ChangePass from "../../comps/ChangePass";
import Menu from "../../comps/MenuComps/Menu";
import UpdateProfile from "../../comps/UpdateProfile";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";

// Mobile / Helper Components (unchanged)
import {
  MobileGreenButton,
  MobileSmallGreenButton,
} from "../../MobileComponents/Buttons";
import {
  Div100vw20vh,
  FlexColumnDiv90vw,
  TopDiv40vh,
  WhiteTopRoundedDiv,
} from "../../MobileComponents/Divs";
import { LogoRelativeLeft, TopImage40vh } from "../../MobileComponents/Images";
import {
  MenuHeader,
  WhiteAvenirHeader,
  WhiteLeagueHeader,
} from "../../MobileComponents/Text";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnLeftAlign,
  FlexContMobileColumn,
  FlexContzIndex1,
  FlexDiv,
  FlexRowContFullWidth,
  FullWidthMobileCont100vw100vh,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { RegularTextBoldRed } from "../../StylesheetComps/Titles";
import IconButton from "../../comps/IconButton";

/* -------------------------------------------
   Animations
------------------------------------------- */
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to   { opacity: 1; transform: translateY(0); }
`;

const slideDown = keyframes`
  from { opacity: 0; transform: translateY(-2rem); }
  to   { opacity: 1; transform: translateY(0); }
`;

const hoverTransition = css`
  transition: all 0.2s ease-in-out;
`;

/* -------------------------------------------
   Shared Styled Components
------------------------------------------- */
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  animation: ${fadeIn} 0.4s ease;
  @media (max-width: 1006px) {
    flex-direction: column;
    width: 95%;
  }
`;

const ContentCard = styled.div`
  background-color: #ffffffee;
  border-radius: 16px;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
  animation: ${slideDown} 0.4s ease;
  @media (max-width: 1006px) {
    width: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 16px 16px 0 0;
  }
`;

const SectionSeparator = styled.hr`
  border: none;
  height: 1px;
  background: #e2e2e2;
  margin: 2rem 0;
`;

const UpdatePassButton = styled.button`
  background-color: #007aff;
  padding: 0.75rem 1rem;
  color: #fff;
  border-radius: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  ${hoverTransition}

  &:hover {
    background-color: #006ae6;
  }

  @media (max-width: 1006px) {
    width: 50%;
  }
`;

const HorizFlex = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 1006px) {
    flex-direction: column;
    gap: 0;
  }
`;

const HiddenContent = styled.div`
  ${(props) => (props.isVisible ? "display: block;" : "display: none;")}
  animation: ${fadeIn} 0.3s ease;
`;

const HeaderDiv = styled.div`
  display: none;
  @media (max-width: 1006px) {
    width: 40%;
    display: flex;
    justify-content: space-around;
  }
`;

const SmallSpacer = styled.div`
  margin: 1rem 0;
`;

/* -------------------------------------------
   New Layout Subcomponents
------------------------------------------- */

/**
 * ProfileHeader
 * Renders the user's name & role with a hero image in the background (mobile).
 */
const ProfileHeader = ({ userinfo, roleName, onEdit }) => {
  return (
    <>
      <Div100vw20vh>
        <TopImage40vh src="/DarkProfileImage2.png" />
      </Div100vw20vh>

      <TopDiv40vh>
        <LogoRelativeLeft
          src={
            userinfo.Image === null
              ? "/PersonIcon.svg"
              : window.$IMGURL + userinfo.Image
          }
        />
        <FlexColumnLeftAlign>
          <WhiteLeagueHeader>
            {userinfo.Name !== null ? userinfo.Name : ""}
          </WhiteLeagueHeader>
          <WhiteAvenirHeader>{roleName}</WhiteAvenirHeader>
          <SmallSpacer />
          <MobileGreenButton onClick={onEdit}>Edit Profile</MobileGreenButton>
        </FlexColumnLeftAlign>
      </TopDiv40vh>
    </>
  );
};

/**
 * ProfileActions
 * Renders the top row actions for Desktop or Mobile:
 * - “Back” arrow
 * - Page Title
 * - “Change Password” button
 */
const ProfileActions = ({ onBack, onChangePassword }) => {
  return (
    <FlexDiv>
      <Icon30px30pxPointer onClick={onBack} src="/leftarrow.svg" />
      <MenuHeader>Profile</MenuHeader>
      <IconButton onClick={onChangePassword} src='/passwordwhite_icon.svg'>Change Password</IconButton>
    </FlexDiv>
  );
};

/**
 * DesktopHeaderPlaceholder
 * Displays an empty div on mobile or any placeholder if needed for desktop.
 */
const DesktopHeaderPlaceholder = () => {
  return <HeaderDiv />;
};

/**
 * ProfileCard
 * The main content card that includes:
 * - "Change Password" button (desktop)
 * - The password form (conditionally shown)
 * - UpdateProfile form
 */
const ProfileCard = ({
  isMobile,
  passView,
  passSwitch,
  onClosePass,
  errorText,
  onPasswordChanged,
  onPreviewPassword,
  onTogglePassView,
  // For updating user info
  editProfile,
  onUpdateProfile,
  userProps,
  updated,
  onPreviewProfile,
  roleNum,
}) => {
  return (
    <ContentCard>
      {/* Desktop: "Change Password" at top */}
      {!isMobile && (
        <>
          <IconButton width='150px' onClick={onTogglePassView} src='/passwordwhite_icon.svg'>Change Password</IconButton>
          <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
          <ChangePass
            passSwitch={passSwitch}
            onClose={onClosePass}
            onPasswordChanged={onPasswordChanged}
            onPreview={onPreviewPassword}
          />
          <SectionSeparator />
        </>
      )}

      {/* Mobile: "Change Password" is triggered via the top bar. Render form conditionally below */}
      {isMobile && (
        <HiddenContent isVisible={passView === "block"}>
          <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
          <ChangePass
            passSwitch={passSwitch}
            onClose={onClosePass}
            onPasswordChanged={onPasswordChanged}
            onPreview={onPreviewPassword}
          />
          <SectionSeparator />
        </HiddenContent>
      )}

      <HorizFlex>
        <UpdateProfile
          editprofile={editProfile}
          updated={updated}
          {...userProps}
          role={roleNum}
          onProfileUpdated={onUpdateProfile}
          onPreview={onPreviewProfile}
        />
      </HorizFlex>
    </ContentCard>
  );
};

/* -------------------------------------------
   Main ProfileScreen
------------------------------------------- */

const ProfileScreen = () => {
  const history = useHistory();

  // States
  const [passSwitch, setPassSwitch] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [ErrorText, setErrorText] = useState("");
  const [passView, setPassView] = useState("none");

  const [userinfo, setUserinfo] = useState({});
  const [roleName, setRoleName] = useState("");
  const [roleNum, setRoleNum] = useState(1000);

  // Existing Profile Info
  const [userId, setUserId] = useState(0);
  const [UsernameValue, setUsernameValue] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [AddressValue, setAddressValue] = useState("");
  const [CityValue, setCityValue] = useState("");
  const [PhoneValue, setPhoneValue] = useState("");
  const [MetroValue, setMetroValue] = useState("");
  const [ImgUrl, setImgUrl] = useState("");
  const [EmailValue, setEmailValue] = useState("");

  // For re-renders
  const [updated] = useState(0);

  // For password
  const [cpCurrentUsername, setcpCurrentUsername] = useState("");
  const [cpOldPass, setcpOldPass] = useState("");
  const [cpNewPass, setcpNewPass] = useState("");
  const [cpConfirmNewPass, setcpConfirmNewPass] = useState("");

  // Detect Mobile
  const isMobile = window.$ISMOBILE === true;

  /* -------------------------------
     Load User Info
  --------------------------------*/
  const GetProfileInfo = async () => {
    try {
      const resp = await axios.get(window.$DBURL + "users/profile", {});
      const { user } = resp.data;

      sessionStorage.setItem("userInfo", JSON.stringify(user));
      setUserinfo(user);

      setUserId(user.Id);
      setUsernameValue(user.Username);
      setNameValue(user.Name);
      setAddressValue(user.Street);
      setCityValue(user.City);
      setPhoneValue(user.Phone);
      setMetroValue(user.Metro);
      setImgUrl(user.Image);
      setEmailValue(user.Email);

      // Determine role name
      switch (user.Role) {
        case UserRole.User:
          setRoleName("Homeowner");
          break;
        case UserRole.VIP:
          setRoleName("VIP Homeowner");
          break;
        case UserRole.Worker:
          setRoleName("Worker");
          break;
        case UserRole.OwnerOperator:
          setRoleName("Owner / Operator");
          break;
        case UserRole.Estimator:
          setRoleName("Guide");
          break;
        case UserRole.Installer:
          setRoleName("Installer");
          break;
        case UserRole.Manager:
          setRoleName("Manager");
          break;
        case UserRole.Admin:
          setRoleName("Admin");
          break;
        case UserRole.Accountant:
          setRoleName("Accountant");
          break;
        case UserRole.Supplier:
          setRoleName("Supplier");
          break;
        default:
          break;
      }
      setRoleNum(user.Role);
    } catch (err) {
      console.error(err);
    }
  };

  /* -------------------------------
     Password Change Logic
  --------------------------------*/
  const HandlePasswordChangePreview = ({
    currentUsername,
    oldPass,
    newPass,
    confirmNewPass,
  }) => {
    setcpCurrentUsername(currentUsername);
    setcpOldPass(oldPass);
    setcpNewPass(newPass);
    setcpConfirmNewPass(confirmNewPass);
  };

  const ChangePasswordFunc = async (oldPass, newPass) => {
    try {
      setErrorText("Old Password Incorrect");
      // Log out
      await axios.post(`${window.$DBURL}users/${userId}/session/logout`);

      // Attempt to update password
      const resp = await axios.put(`${window.$DBURL}users/password`, {
        UserName: UsernameValue,
        OldPassword: oldPass,
        NewPassword: newPass,
      });

      if (resp.status === 200) {
        setErrorText("");

        // Remove token & re-login
        delete axios.defaults.headers.common["Authorization"];
        const resplogin = await axios.post(`${window.$DBURL}users/session/login`, {
          Username: UsernameValue,
          Password: newPass,
        });

        if (resplogin.data) {
          const token = resplogin.data.Session.AuthToken;
          axios.defaults.baseURL = window.$DBURL;
          sessionStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
        }

        setPassSwitch(false);
        setPassView("none");
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* -------------------------------
     Profile Update Logic
  --------------------------------*/
  const HandleProfileUpdatePreview = ({
    emailValue2,
    NameValue2,
    AddressValue2,
    PhoneValue2,
  }) => {
    setEmailValue(emailValue2);
    setNameValue(NameValue2);
    setAddressValue(AddressValue2);
    setPhoneValue(PhoneValue2);
  };

  const UpdateAccount = async (email, name, address, phone, image) => {
    try {
      await axios.put(`${window.$DBURL}users`, {
        Name: name,
        Phone: phone,
        Id: userId,
        Street: address,
        Email: email,
        Username: UsernameValue,
        Image: image,
      });
      // Refresh profile info
      GetProfileInfo();
    } catch (err) {
      console.error(err);
    }
  };

  /* -------------------------------
     Effects
  --------------------------------*/
  useEffect(() => {
    SetHeaderAuthorization();
    GetProfileInfo();
    // eslint-disable-next-line
  }, []);

  /* -------------------------------
     Conditionally Render
  --------------------------------*/
  if (roleNum === UserRole.Loading) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  /* -------------------------------
     Mobile Layout
  --------------------------------*/
  if (isMobile) {
    return (
      <FlexContzIndex1>
        <LeftCont>
          <Menu />
        </LeftCont>

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu mobilePageProp="Profile" />

          {/* Mobile Hero */}
          <ProfileHeader
            userinfo={userinfo}
            roleName={roleName}
            onEdit={() => setEditProfile(true)}
          />

          <WhiteTopRoundedDiv>
            {/* Top bar actions & Title */}
            <ProfileActions
              onBack={() => history.push("/myaccount")}
              onChangePassword={() => {
                setPassSwitch(true);
                setPassView("block");
                window.scrollTo(0, 0);
              }}
            />

            <FlexColumnDiv90vw>
              <MainContainer>
                <ProfileCard
                  isMobile
                  passView={passView}
                  passSwitch={passSwitch}
                  onClosePass={() => {
                    setPassSwitch(false);
                    setPassView("none");
                    window.scrollTo(0, 0);
                  }}
                  errorText={ErrorText}
                  onPasswordChanged={ChangePasswordFunc}
                  onPreviewPassword={HandlePasswordChangePreview}
                  onTogglePassView={() => {
                    setPassSwitch(true);
                    setPassView("block");
                  }}
                  editProfile={editProfile}
                  onUpdateProfile={UpdateAccount}
                  userProps={{
                    imgurl: ImgUrl,
                    metro: MetroValue,
                    city: CityValue,
                    email: EmailValue,
                    name: NameValue,
                    phone: PhoneValue,
                    address: AddressValue,
                  }}
                  updated={updated}
                  onPreviewProfile={HandleProfileUpdatePreview}
                  roleNum={roleNum}
                />
              </MainContainer>
            </FlexColumnDiv90vw>
          </WhiteTopRoundedDiv>
        </FlexColumnCont80vwAutoHeight>
      </FlexContzIndex1>
    );
  }

  /* -------------------------------
     Desktop Layout
  --------------------------------*/
  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu />
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu mobilePageProp="Profile" />

        <FlexRowContFullWidth>
          <DesktopHeaderPlaceholder />

          <MainContainer>
            <ProfileCard
              isMobile={false}
              passView={passView}
              passSwitch={passSwitch}
              onClosePass={() => {
                setPassSwitch(false);
                setPassView("none");
                window.scrollTo(0, 0);
              }}
              errorText={ErrorText}
              onPasswordChanged={ChangePasswordFunc}
              onPreviewPassword={HandlePasswordChangePreview}
              onTogglePassView={() => {
                setPassSwitch(true);
                setPassView("block");
              }}
              editProfile={editProfile}
              onUpdateProfile={UpdateAccount}
              userProps={{
                imgurl: ImgUrl,
                metro: MetroValue,
                city: CityValue,
                email: EmailValue,
                name: NameValue,
                phone: PhoneValue,
                address: AddressValue,
              }}
              updated={updated}
              onPreviewProfile={HandleProfileUpdatePreview}
              roleNum={roleNum}
            />
          </MainContainer>
        </FlexRowContFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

export default ProfileScreen;
