import styled from "styled-components";
import { ColorPalette } from "../Colors";

const nullProp = null;

export const Cont = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "")};
  overflow: visible; /* Ensure sticky works */
  @media (max-width: 1006px) {
    flex-direction: column;
    height: auto;
    position: ${(props) =>
      props.positionmobile ? props.positionmobile : ""};
  }
`;

export const ContentCont77vw = styled.div`
  width: ${(props) => (props.width ? props.width : "75vw")};
  height:  ${(props) => (props.height ? props.height : "auto")};
  margin: 1%;
  padding: ${(props) => (props.padding ? props.padding : "2vw")};
  padding-top:  ${(props) => (props.paddingTop ? props.paddingTop : "20px")};
  padding-bottom: ${(props) => (props.paddingbottom ? props.paddingbottom : "25px")};
  border: ${(props) => (props.border ? props.border : "")};
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.boxshadow
      ? props.boxshadow
      : "0px 4px 6px " + ColorPalette.PrimaryGrey};
  display: ${(props) => (props.display ? props.display : "block")};
  justify-content: ${(props) => (props.justifycontent ? props.justifycontent : "")};
  background-color: white;
  flex-direction:column;
  @media (max-width: 1006px) {
    width: 92vw;
    margin: ${(props) =>
      props.mobilemargin ? props.mobilemargin : "3%"};
    height: auto;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    padding-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : "5vh"};
  }
`;

export const ContentCont44Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "50%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: 1%;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.boxshadow
      ? props.boxshadow
      : "0px 4px 6px " + ColorPalette.PrimaryGrey};
  display: ${(props) => (props.display ? props.display : "block")};
  position: ${(props) => (props.position ? props.position : "")};
  background-color: white;
  @media (max-width: 1006px) {
    width: 95vw;
    margin: 5%;
    height: auto;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    padding-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : "5vh"};
  }
`;

export const ContentContQuote = styled.div`
  width: ${(props) => (props.width ? props.width : "95%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: 1%;
  padding: 1vw;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.boxshadow
      ? props.boxshadow
      : "0px 4px 6px " + ColorPalette.PrimaryGrey};
  display: ${(props) => (props.display ? props.display : "block")};
  background-color: white;
  @media (max-width: 1006px) {
    width: 95vw;
    margin: 5%;
    height: auto;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    padding-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : "5vh"};
  }
  flex-direction: ${(props) =>
    props.mobileflexdirection ? props.mobileflexdirection : ""};
`;

export const ContentContPropWidth = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  min-height: 70vh;
  margin: 1%;
  padding: 1vw;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.boxshadow
      ? props.boxshadow
      : "0px 4px 6px " + ColorPalette.PrimaryGrey};
  display: ${(props) => (props.display ? props.display : "block")};
  background-color: white;
  @media (max-width: 1006px) {
    width: 98vw;
    margin: 0%;
    height: auto;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : "5vh"};
  }
`;

export const CaseCardCont = styled.div`
width:${(props) => (props.width ? props.width : "auto")};
height:${(props) => (props.height ? props.height : "5vh")};
margin:${(props) => (props.margin ? props.margin : "")};
position:${(props) => (props.position ? props.position : "")};
top:${(props) => (props.top ? props.top : "")};
bottom:${(props) => (props.bottom ? props.bottom : "")};
left:${(props) => (props.left ? props.left : "")};
right:${(props) => (props.right ? props.right : "")};
border-bottom:${(props) =>
  props.borderbottom ? props.borderbottom : "1px solid #d3d3d3"};
background-color:${(props) => (props.bgcolor ? props.bgcolor : "white")};
align-items:${(props) => (props.alignitems ? props.alignitems : "center")};
display:${(props) => (props.display ? props.display : "flex")};
padding:${(props) => (props.padding ? props.padding : "20px")};
cursor:${(props) => (props.cursor ? props.cursor : "pointer")};
margin-bottom:${(props) => (props.marginbottom ? props.marginbottom : "")};
margin-top:${(props) => (props.margintop ? props.margintop : "")};
border:${(props) => (props.border ? props.border : "")};
flex-direction:${(props) => (props.flexdirection ? props.flexdirection : "")};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "flex-start"};
margin-left:${(props) => (props.marginleft ? props.marginleft : "")};
border-radius${(props) => (props.borderradius ? props.borderradius : "")};
border-bottom:${(props) => (props.borderbottom ? props.borderbottom : "")};
overflow-y:${(props) => (props.overflowy ? props.overflowy : "")};
transition: padding 0.2s ease, background-color 0.2s ease;
&:hover {
  padding: 25px; 
  background-color: #F8F6F6;
}
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "90%")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "10vh")};
  margin:${(props) => (props.mobilemargin ? props.mobilemargin : "-1%")};
  margin-top:${(props) =>
    props.mobilemargintop ? props.mobilemargintop : "5%"};
  justify-content:${(props) =>
    props.mobilejustifycontent ? props.mobilejustifycontent : "space-between"};
  box-shadow:0px 2px 4px #b7b7b7;
  border-radius:10px;
}
`;

export default nullProp;
