import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { GetUserCases } from "../../../API_Functions/CaseQuote_Functions";
import MobileSlideMenu from "../../../comps/MobileSlideMenu";
import { GreenButtonWidth12VWHeight40 } from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import {
  Cont17vw0vh,
  FlexColumnCont70x18PercentAlignLeft,
  FlexColumnCont80x100Percent,
  FlexColumnCont80x10PercentAlignLeft,
  FlexColumnCont98x50PercentJustifyLeft,
  FlexColumnCont98xAutoFlexEnd,
  FlexColumnContCenterAlign,
  FlexColumnContFullWidthOverflowY,
  FlexRowContAutox100Percent,
} from "../../../StylesheetComps/Cont";
import { Icon30x30 } from "../../../StylesheetComps/Imgs/Icons";
import {
  CircularImage125px,
  Icon,
  Icon20x80Percent,
  MenuCont,
  MenuNRCLogo,
  MobileIcon100x130Percent,
  SmallHeader,
} from "../../../StylesheetComps/Menu";
import { StyledHRPropColour } from "../../../StylesheetComps/Titles";
import { UserRole } from "../../../StylesheetComps/UserRoles";
import IconButton from "../../IconButton";
import QuickProjectForm from "../../Windows/AddProjectOBO";

const MobileFlexMenu = styled.div`
  justify-content: space-around;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px;
  display: none;
  @media (max-width: 1006px) {
    display: flex;
  }
`;

const SecDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
`;

const MobileLinkText = styled.p`
  display: none;
  @media (max-width: 1006px) {
    font-family: LeagueSpartan-Bold;
    display: block;
    font-size: 10px;
    color: #333;
    z-index: 1001;
    left: 2px;
  }
`;

const StyledLink = styled(Link)`
  color: black;
  &:hover {
    color: #606060;
  }
`;

const Menu = () => {
  const history = useHistory();

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [roleNum, setRoleNum] = useState(1000);
  const [metroId, setMetroId] = useState(0);
  const [storeId, setStoreId] = useState(0);
  const [profileIconUrl, setProfileIconUrl] = useState("/PersonIcon.svg");
  const [moreToggle, setMoreToggle] = useState(false);
  const [displayMetro, setDisplayMetro] = useState("none");
  const [displayStoreCalendar, setDisplayStoreCalendar] = useState("none");
  const [displayInstallers, setDisplayInstallers] = useState("none");
  const [displayWorkders, setDisplayWorkers] = useState("none");
  //const [displayBilling, setDisplayBilling] = useState("none");
  const [displayClockSum, setDisplayClocksum] = useState("none");
  const [displaySchedule, setDisplayschedule] = useState("none");
  const [displayStoreProjects, setDisplaystoreprojects] = useState("none");
  const [displayStoreManagement, setDisplaystoremanagement] = useState("none");
  const [displayBidding, setDisplaybidding] = useState("none");
  const [displayHoursetup, setDisplayHoursetup] = useState("none");
  const [displayClockInout, setDisplayClockinout] = useState("none");
  const [displayClockRecord, setDisplayClockrecord] = useState("none");
  const [displayMySchedule, setDisplayMyschedule] = useState("none");
  const [displayCalendar, setDisplayCalendar] = useState("none");
  const [displayQuickProject, setDisplayQuickProject] = useState("none");
  const [displayQuickProjectButton, setDisplayQuickProjectButton] = useState("none");

  const Logout = async (userId) => {
    const resp = await axios.post(
      `${window.$DBURL}users/${userId}/session/logout`
    );
    if (resp.status === 200) {
      axios.defaults.headers.common["Authorization"] = "";
      sessionStorage.removeItem("token");
      history.push("/");
    }
  };

  const CheckStoreEstimates = async (metroId, storeId) => {
    const resp = await axios.get(`${window.$DBURL}store/metro/${metroId}`);
    if (
      resp.status === 200 &&
      resp.data !== null &&
      resp.data.stores !== null
    ) {
      if (storeId === 0) {
        if (resp.data.stores.length !== 1) {
          history.push(`/storelistestimate/${metroId}`);
        } else {
          history.push(`/estimate/${metroId}/${resp.data.stores[0].Id}`);
        }
      } else {
        history.push(`/estimate/${metroId}/${storeId}`);
      }
    }
  };

  const CheckInstallers = async (metroId, storeId) => {
    const resp = await axios.get(`${window.$DBURL}store/metro/${metroId}`);
    if (
      resp.status === 200 &&
      resp.data !== null &&
      resp.data.stores !== null
    ) {
      if (storeId === 0) {
        if (resp.data.stores.length !== 1) {
          history.push(`/storelistinstallers/${metroId}`);
        } else {
          history.push(`/storeinstallers/${metroId}/${resp.data.stores[0].Id}`);
        }
      } else {
        history.push(`/storeinstallers/${metroId}/${storeId}`);
      }
    }
  };

  const RedirectUserToHome = async () => {
    // Fetch the user's cases using their ID from session storage
    const data = await GetUserCases(
      JSON.parse(sessionStorage.getItem("userInfo")).Id
    );

    // If the data is not null, check if the user has any cases or if the window is a mobile device
    if (data !== null) {
      if (data.Cases.length > 0 || window.$ISMOBILE === true) {
        // Redirect to the home page
        history.push("/home");
      } else {
        // Redirect to the home page
        history.push("/home");
      }
    }
  };

  useEffect(() => {
    var user = JSON.parse(sessionStorage.getItem("userInfo"));
    if (user != null) {
      setUserName(user.Username);
      setRoleNum(user.Role);
      setMetroId(user.Metro_Id);
      setStoreId(user.Store_Id);
      setUserId(user.Id);
      if (user.Image !== null) {
        setProfileIconUrl(window.$IMGURL + user.Image);
      }
      if(user.Role === UserRole.Manager)
      {      
        setDisplayClocksum("flex");
        setDisplayInstallers("flex");
        setDisplayStoreCalendar("flex");
        setDisplayWorkers("flex");
        setDisplayschedule("flex");
        setDisplaystoreprojects("flex");
        setDisplaystoremanagement("flex");
        setDisplaybidding("flex");
        setDisplayQuickProjectButton('flex')
      }
      else if(user.Role === UserRole.Admin)
      {
        setDisplayMetro("flex");
      }
      else if(user.Role === UserRole.Installer)
      {
        setDisplayClocksum("flex");
        setDisplayWorkers("flex");
        setDisplaybidding("flex");
        setDisplayHoursetup("flex");
      }
      else if(user.Role === UserRole.Worker)
      {
        setDisplayClocksum("flex");
        setDisplayClockinout("flex");
        setDisplayClockrecord("flex");
        setDisplayMyschedule("flex");
      }
      else if(user.Role === UserRole.Accountant)
      {
        setDisplayClocksum("flex");
        setDisplayWorkers("flex");
        setDisplayschedule("flex");
        setDisplaystoreprojects("flex");
      }
      else if(user.Role === UserRole.Estimator)
      {
        setDisplaystoreprojects("flex");
        setDisplaybidding("flex");
        setDisplayQuickProjectButton('flex')
      }
      /* else if(user.Role === UserRole.Manager)
      {
        setDisplayClocksum("flex");
        setDisplayInstallers("flex");
        setDisplayMetro("flex");
        setDisplayStoreCalendar("flex");
        setDisplayWorkers("flex");
        setDisplayschedule("flex");
        setDisplaystoreprojects("flex");
        setDisplaystoremanagement("flex");
        setDisplaybidding("flex");
        setDisplayHoursetup("flex");
      } */

    }
  }, []);

  if (roleNum !== 1000) {
    return (
      <div>
        {" "}
        {/* <Cont17vw0vh> */}
          <MobileSlideMenu
            open={moreToggle}
            onCloseMenu={() => {
              setMoreToggle(false);
            }}
          ></MobileSlideMenu>

          <div style={{display:displayQuickProject}}>
          <QuickProjectForm open={displayQuickProject === 'flex' ? true : false} onClose={()=>{setDisplayQuickProject('none')}}></QuickProjectForm>
          </div>

          {/* <MobileFlexMenu>
            <SecDiv>
              <StyledLink
                onClick={() => {
                  history.push("/home");
                }}
                to={"#"}
                className="nav"
                style={{ textDecoration: "none" }}
              >
                <MobileIcon100x130Percent
                  src={"/Dashboard.png"}
                ></MobileIcon100x130Percent>
              </StyledLink>
              <MobileLinkText>Home</MobileLinkText>
            </SecDiv>

            <SecDiv>
              <StyledLink
                to={"/CasesPersonal/" + metroId + "/" + storeId}
                className="nav"
                style={{ textDecoration: "none" }}
              >
                <MobileIcon100x130Percent
                  src={"/Projects.png"}
                ></MobileIcon100x130Percent>
              </StyledLink>
              <MobileLinkText>Projects</MobileLinkText>
            </SecDiv>
                
            <SecDiv>
              <StyledLink
                to={"/calendar"}
                className="nav"
                style={{ textDecoration: "none" }}
              >
                <MobileIcon100x130Percent
                  src={"/Calendar_Icon.png"}
                ></MobileIcon100x130Percent>
              </StyledLink>
              <MobileLinkText>Calendar</MobileLinkText>
            </SecDiv>

            <SecDiv>
              <StyledLink
                to={"#"}
                onClick={() => {
                  history.push("/billing");
                }}
                className="nav"
                style={{ textDecoration: "none" }}
              >
                <MobileIcon100x130Percent
                  src={"/Billing_Icon.png"}
                ></MobileIcon100x130Percent>
              </StyledLink>
              <MobileLinkText>Billing</MobileLinkText>
            </SecDiv>
          </MobileFlexMenu> */}
        {/* </Cont17vw0vh> */}
        <MenuCont mobiledisplay="none">
          <MenuNRCLogo
            onClick={() => {
              history.push("/home");
            }}
            src="/newrhodeslogo.svg"
          ></MenuNRCLogo>

          <br></br>

          <FlexColumnContCenterAlign>
            <CircularImage125px src={profileIconUrl}></CircularImage125px>
            <SmallHeader>{userName}</SmallHeader>
            <SmallHeader
              fontsize="14px"
              color="#797979"
              cursor="pointer"
              onClick={() => {
                history.push("/profile");
              }}
            >
              View Profile
            </SmallHeader>
          </FlexColumnContCenterAlign>
          <br></br>

          <FlexColumnCont98x50PercentJustifyLeft>
            {/* <GreenButtonWidth12VWHeight40
              bgcolor={ColorPalette.PrimaryButtonBlue}
              onClick={() => {
                CheckStoreEstimates(metroId, storeId);
              }}
            >
              New Project
            </GreenButtonWidth12VWHeight40> */}

            {/* <br></br> */}

              <FlexColumnCont80x10PercentAlignLeft display={displayQuickProjectButton}>
                <FlexRowContAutox100Percent>
                  {/* <Icon20x80Percent
                    src={"/Landscaper1.svg"}
                  ></Icon20x80Percent> */}
                  {/* <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    
                  >
                    New Project
                  </SmallHeader> */}

                <IconButton width={'200px'} onClick={() => {
                      setDisplayQuickProject('flex')
                    }} text='New Project' src='/whiteplus_icon.svg'></IconButton>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>



  
            <FlexColumnCont70x18PercentAlignLeft>
              <SmallHeader fontfamily="LeagueSpartan-Bold" fontsize="1vw">
                Navigation
              </SmallHeader>
            </FlexColumnCont70x18PercentAlignLeft>

            <FlexColumnContFullWidthOverflowY>
              <FlexColumnCont80x10PercentAlignLeft>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Dashboard_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/home");
                    }}
                  >
                    Book a Site Visit
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>      

              <FlexColumnCont80x10PercentAlignLeft>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Projects_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/CasesPersonal/" + metroId + "/" + storeId);
                    }}
                  >
                    Projects
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>

              <FlexColumnCont80x10PercentAlignLeft display={displayHoursetup}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/manageavailability.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/installersetup");
                    }}
                  >
                    Business Hours
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>

              <FlexColumnCont80x10PercentAlignLeft display={displayMetro}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent src={"/metroicon.svg"}></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/metrolist");
                    }}
                  >
                    Region Management
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>

              <FlexColumnCont80x10PercentAlignLeft display={displayStoreProjects}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Store_Projects_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/cases/" + metroId + "/" + storeId);
                    }}
                  >
                    Store Projects
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>

              <FlexColumnCont80x10PercentAlignLeft display={displayStoreManagement}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Store_Management_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/Store/" + storeId);
                    }}
                  >
                    Store Management
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayStoreManagement}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Store_Management_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/Inventory/" + storeId);
                    }}
                  >
                    Inventory Management
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayBidding}>
                <FlexRowContAutox100Percent>
                  <Icon30x30
                    marginleft="3.5%"
                    src={"/BiddingList_Icon.svg"}
                  ></Icon30x30>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push(`/projectbidlist/${metroId}/${storeId}`);
                    }}
                  >
                    Project Bidding
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayCalendar}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Calendar_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/calendar");
                    }}
                  >
                    Calendar
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Billing_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/billing");
                    }}
                  >
                    Billing
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayStoreCalendar}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Store_Calendar_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/storecalendar/" + metroId + "/" + storeId);
                    }}
                  >
                    Store Calendar
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}
{/* 
              <FlexColumnCont80x10PercentAlignLeft display={displaySchedule}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Store_Schedule.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/storeschedule");
                    }}
                  >
                    Store Schedule
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayInstallers}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Installers_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      CheckInstallers(metroId, storeId);
                    }}
                  >
                    View Installers
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayWorkders}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Workers_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/storeworkers");
                    }}
                  >
                    View Workers
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}
{/* 
              <FlexColumnCont80x10PercentAlignLeft display={displayClockInout}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent src={"/clockinout.svg"}></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/clockinout");
                    }}
                  >
                    Clock In/Out
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayClockRecord}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/clockrecords.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push(
                        "/clockschedule/" +
                          storeId +
                          "/" +
                          userId +
                          "/" +
                          roleNum +
                          "/" +
                          userName
                      );
                    }}
                  >
                    View Clock Records
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}

              {/* <FlexColumnCont80x10PercentAlignLeft display={displayMySchedule}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/viewmyschedule.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push(
                        "/workerschedule/" +
                          storeId +
                          "/" +
                          userId +
                          "/" +
                          userName
                      );
                    }}
                  >
                    View My Schedule
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft>
             */}
              {/* <FlexColumnCont80x10PercentAlignLeft display={displayClockSum}>
                <FlexRowContAutox100Percent>
                  <Icon20x80Percent
                    src={"/Workers_Summary_Icon.svg"}
                  ></Icon20x80Percent>
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                    onClick={() => {
                      history.push("/clockschedulesummary/" + storeId);
                    }}
                  >
                    Worker Summary
                  </SmallHeader>
                </FlexRowContAutox100Percent>
              </FlexColumnCont80x10PercentAlignLeft> */}
            </FlexColumnContFullWidthOverflowY>
          </FlexColumnCont98x50PercentJustifyLeft>
          <StyledHRPropColour
            border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
          ></StyledHRPropColour>
          <FlexColumnCont98xAutoFlexEnd>
            <FlexColumnCont80x100Percent>
              {/* <FlexRowContAutox100Percent>
                <Icon width="20%" height="60%" src={"/NRCLogoRegularBlack.png"}></Icon>
                <a
                  href="https://www.newrhodesconstruction.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SmallHeader
                    cursor="pointer"
                    fontsize="1vw"
                    hovercolor={ColorPalette.PrimaryGrey}
                  >
                    NRC Website
                  </SmallHeader>
                </a>
              </FlexRowContAutox100Percent> */}

              <FlexRowContAutox100Percent>
                <Icon width="20%" height="60%" src={"/LogOutIcon.svg"}></Icon>
                <SmallHeader
                  cursor="pointer"
                  fontsize="1vw"
                  hovercolor={ColorPalette.PrimaryGrey}
                  onClick={() => {
                    Logout(userId);
                  }}
                >
                  Log Out
                </SmallHeader>
              </FlexRowContAutox100Percent>
            </FlexColumnCont80x100Percent>
          </FlexColumnCont98xAutoFlexEnd>
        </MenuCont>
      </div>
    );
  }  else {
    return (
      null
    );
  }
};

Menu.defaultProps = {};

export default Menu;
