//  React Imports ---------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCityList,
  GetMetroList,
} from "../../API_Functions/Utility_Functions";
import {
  AssignCase,
  CaseStatus,
  DeleteCaseById,
  GetCaseById,
  GetInvoiceObject,
  QuoteStatus,
  SetCaseTo_Accept,
  SetCaseTo_Cancel,
  SetCaseTo_CancelRequested,
  SetCaseTo_Close,
  SetCaseTo_Construction,
  SetCaseTo_Processed,
  SetCaseTo_Submit,
  UpdateACase,
} from "../../API_Functions/CaseQuote_Functions";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import ProposalCardGuide from "../../comps/ProposalCardGuide";
import AddInvoiceWindow from "../../comps/Windows/AddInvoice";
import DeleteInvoiceWindow from "../../comps/Windows/DeleteInvoice";
import DeleteProjectWindow from "../../comps/Windows/DeleteProject";
import DeleteProposalWindow from "../../comps/Windows/DeleteProposal";
import GuideCancelProjectWindow from "../../comps/Windows/GuideCancelProject";
import { LargeResponsiveButton } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  EditActionButtonsRow,
  EditButton,
  EditFieldLabel,
  EditFieldRow,
  EditFormColumn,
  EditFormContainer,
  EditSelect,
  EditTextInput,
  Option16px,
  RegularResponsiveButton,
  Select16px,
} from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import {
  FlexColumn81vwLightGrey,
  FlexColumnFullWidth,
  FlexColumnPropWidth,
  FlexCont90PercentAutoHeight,
  FlexContFullWidthJustifyCenter,
  FlexDiv,
  FlexRowCont300x200,
  FlexRowContAutox100Percent,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnDiv, FlexDivFlexEnd, GridContainer2Wide } from "../../StylesheetComps/Div";
import {
  Icon20x20,
  Icon30x30,
  Icon45x45,
} from "../../StylesheetComps/Imgs/Icons";
import { Image80x100px } from "../../StylesheetComps/Imgs/Images";
import {
  DatalistMedium,
  DatalistOption,
  FlexContBottomGrey,
  FlexDivFlexStartCenter,
  FlexInfoCont,
  Image300x200,
  LandscapePara,
  PhotoContExtra,
  SmallHeaderLeagueSpartan,
  TextInputReusable,
  WideFlexInfoCont,
} from "../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";
import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import {
  Icon30px20pxPointer,
  Icon30px30pxPointer,
} from "../../StylesheetComps/Quote";
import {
  BlueUnderlinePointerPara,
  LeagueSpartanHeaderSmall,
  LeagueSpartanRegular,
  MediumHeader100PercentWidth,
  MediumHeader100PercentWidthWhiteText,
  MobileOnlyRegularHeader,
  RegularText,
  SmallTextPadding,
  StyledHRGreenBreakDashed,
  StyledHRPropColour,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import AreaInputPopup from "../../sitemappercomps/accessPopup";
import { Button } from "@material-ui/core";
import ProjectPhotoUploadPreviewWindow from "../../comps/ProjectPhotoUploadPreviewWindow";
import IconButton from "../../comps/IconButton";

const ViewProjectGuideView = () => {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [draftCaseVisible, setDraftCaseVisible] = useState("none");
  const [expiredProposalVisible, setExpiredProposalVisible] = useState("block");
  const [invoiceVisible] = useState("block");

  const [projectName, setProjectName] = useState("");
  const [projectAreaString, setProjectAreaString] = useState("");
  const [projectScopeString, setProjectScopeString] = useState("");
  const [ProjectTypeString, setProjectTypeString] = useState("");
  const [ProjectStyleString, setProjectStyleString] = useState("");
  const [ProjectBudgetString, setProjectBudgetString] = useState("");
  const [projectDescriptionString, setProjectDescriptionString] = useState("");

  const [projectProposalsDisplay, setProjectProposalsDisplay] =
    useState("none");
  const [expiredProjectProposalsDisplay, setExpiredProjectProposalsDisplay] =
    useState("none");
  const [projectInvoicesDisplay, setProjectInvoicesDisplay] = useState("none");

  const [projectInfoArrow, setProjectInfoArrow] = useState("/downarrow.svg");
  const [projectProposalArrow, setProjectProposalArrow] =
    useState("/downarrow.svg");
  const [expiredProjectProposalArrow, setExpiredProjectProposalArrow] =
    useState("/downarrow.svg");
  const [projectInvoiceArrow, setProjectInvoiceArrow] =
    useState("/downarrow.svg");

  const [deleteProjectDisplay, setDeleteProjectDisplay] = useState("none");
  const [deleteProjectWindowDisplay, setDeleteProjectWindowDisplay] =
    useState(false);

  const [cancelProjectWindowDisplay, setCancelProjectWindowDisplay] =
    useState(false);

  const [addInvoiceWindowDisplay, setAddInvoiceWindowDisplay] = useState(false);
  const [deleteInvoiceWindowDisplay, setDeleteInvoiceWindowDisplay] =
    useState(false);
  const [invoiceIdToDelete, setInvoiceIdToDelete] = useState(0);

  const [projectObj, setProjectObj] = useState({});
  const [projectInvoices, setProjectInvoices] = useState([]);
  const [projectProposals, setProjectProposals] = useState([]);

  const [constructionButtonDisplay, setConstructionButtonDisplay] =
    useState("none");
  const [closeButtonDisplay, setCloseButtonDisplay] = useState("none");
  const [cancelButtonDisplay, setCancelButtonDisplay] = useState("none");
  const [createQuoteDisplay, setCreateQuoteDisplay] = useState("none");
  const [takeOverDisplay, setTakeOverDisplay] = useState("none");
  const [changeStatusContDisplay, setChangeStatusContDisplay] =
    useState("none");

  const [statusValue, setStatusValue] = useState(0);

  const [projectPhotos, setProjectPhotos] = useState([]);
  const [PDFMap, setPDFMap] = useState([]);
  const [userId, setUserId] = useState(0);
  const [guideUserId, setGuideUserId] = useState(0);

  const [listOfEstimators, setListOfEstimators] = useState([]);
  const [changeEstimatorDisplay, setChangeEstimatorDisplay] = useState("none");

  const [deleteProposalDisplay, setDeleteProposalDisplay] = useState(false);
  const [deleteProposalId, setDeleteProposalId] = useState(0);

  const [invoiceObj, setInvoiceObj] = useState({});

  const [editCaseInfo, setEditCaseInfo] = useState(false);
  const [editCaseDesc, setEditCaseDesc] = useState(false);

  const [createQuoteWindowDisplay, setCreateQuoteWindowDisplay] =
    useState(false);

  const [lockedEstimateIds, setLockedEstimateIds] = useState([]);

  const [isUploadProjectPhotosOpen, setIsUploadProjectPhotosOpen] = useState(false);


  //Mobile View States
  const [estimateTextMobileColor, setEstimateTextMobileColor] = useState(ColorPalette.PrimaryButtonBlue);
  const [proposalVisible, setProposalVisible] = useState("none");

  const [projectInfoTextMobileColor, setProjectInfoTextMobileColor] = useState(ColorPalette.PrimaryGrey);
  const [projectInfoDisplay, setProjectInfoDisplay] = useState("none");

  
  const DeleteProject = async (caseId) => {
    let text =
      "Are you sure you want to delete this project? This action cannot be undone";
    if (window.confirm(text) == true) {
      try {
        setIsLoading(true);
        // Delete the case using the provided caseId
        const response = await axios.delete(window.$DBURL + "case/" + caseId);

        // If the case was successfully deleted, get the updated cases and sort them
        if (response.status === 200) {
          let alerttext = "This project has been deleted.";
          if (window.confirm(alerttext) == true) {
            history.push(`/cases/${params.Id}/${params.storeId}`);
          } else {
            history.push(`/cases/${params.Id}/${params.storeId}`);
          }
        }
      } catch (error) {
        // Handle any errors that may have occurred
        console.error(error);
      }
    } else {
      console.log("Cancelled");
    }
  };

  const DisplayProjectInfo = async (arrow) => {
    if (arrow === "/uparrow.svg") {
      setProjectInfoDisplay("none");
      setProjectInfoArrow("/downarrow.svg");
    } else {
      setProjectInfoDisplay("flex");
      setProjectInfoArrow("/uparrow.svg");
    }
  };

  const DisplayProjectProposals = async (arrow, type) => {
    // For non-expired proposals
    if (type === "nonExpired") {
      if (arrow === "/uparrow.svg") {
        setProjectProposalsDisplay("none");
        setProjectProposalArrow("/downarrow.svg");
      } else {
        setProjectProposalsDisplay("flex");
        setProjectProposalArrow("/uparrow.svg");
      }
    }
    // For expired proposals
    else if (type === "expired") {
      if (arrow === "/uparrow.svg") {
        setExpiredProjectProposalsDisplay("none");
        setExpiredProjectProposalArrow("/downarrow.svg");
      } else {
        setExpiredProjectProposalsDisplay("flex");
        setExpiredProjectProposalArrow("/uparrow.svg");
      }
    }
  };

  const DisplayProjectInvoices = async (arrow) => {
    if (arrow === "/uparrow.svg") {
      setProjectInvoicesDisplay("none");
      setProjectInvoiceArrow("/downarrow.svg");
    } else {
      setProjectInvoicesDisplay("flex");
      setProjectInvoiceArrow("/uparrow.svg");
    }
  };

  const SetupProjectForDisplay = async (caseid) => {
    var data = await GetCaseById(caseid);

    if (data !== null) {
      var project = data.Case;
      if (project !== null) {
        setProjectObj(project);
        console.log(project);
        SetupProjectDescriptionStrings(project.Description);
        SetProposalVisibility(project);
        SetDefaultButtons(project);
        if (project.Invoices !== null) {
          setProjectInvoices(project.Invoices);
        }
        if (data.Case.Tittle !== null && data.Case.Tittle !== undefined) {
          setProjectName(project.Tittle);
        }
        if (project.Photos !== null) {
          setPDFMap(
            project.Photos.filter(
              (o) =>
                o.File?.slice(-4) === ".pdf" || o.File?.slice(-4) === ".PDF"
            )
          );
          setProjectPhotos(
            project.Photos.filter(
              (o) =>
                !o.File?.includes(".pdf") || !(o.File?.slice(-4) !== ".PDF")
            )
          );
        }
      }
      const resp = await axios.get(
        `${window.$LOCKINURL}?caseId=eq.${params.caseId}`,
        {
          headers: {
            apikey: window.$POLYGONAPIKEY,
            authorization: window.$POLYGONAUTHKEY,
          },
        }
      );
      const estimateIds = resp.data.map((item) => item.estimateId);
      if (project.Quotes !== null) {
        const updatedQuotes = project.Quotes.map((quote) => {
          // Check if the current quote's Quote_Id matches any of the estimateIds
          const isLocked = estimateIds.includes(quote.Id);

          // Return a new object with all original quote properties plus the lockedEstimate
          // Set lockedEstimate to true if a match is found, false otherwise
          return { ...quote, lockedEstimate: isLocked };
        });

        // Update your project's proposals with the modified quotes array
        setProjectProposals(updatedQuotes);
        console.log(updatedQuotes);
      }
    }
  };

  const SetupProjectDescriptionStrings = async (projectdesc) => {
    setProjectDescriptionString(
      projectdesc.substring(projectdesc.indexOf("Description:") + 12)
    );
    setProjectAreaString(
      projectdesc.substring(
        projectdesc.indexOf("Project Area") + 13,
        projectdesc.indexOf(",") - 1
      )
    );
    setProjectScopeString(
      projectdesc.substring(
        projectdesc.indexOf("Project Scope") + 14,
        projectdesc.indexOf(",", projectdesc.indexOf(",") + 1)
      )
    );
    setProjectTypeString(
      projectdesc.substring(
        projectdesc.indexOf("Project Type") + 13,
        projectdesc.indexOf(", Project Style") - 1
      )
    );
    setProjectStyleString(
      projectdesc.substring(
        projectdesc.indexOf("Project Style") + 14,
        projectdesc.indexOf(",Project Budget") - 1
      )
    );
    setProjectBudgetString(
      projectdesc.substring(
        projectdesc.indexOf("Project Budget:") + 15,
        projectdesc.indexOf(", Description")
      )
    );
  };

  var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const SetDefaultButtons = async (project) => {
    setDraftCaseVisible("none");

    setChangeStatusContDisplay("none");

    if (
      (userinfo.Role === UserRole.Manager ||
        project.Estimator_Id === userinfo.Id) &&
      project.CaseStatus !== CaseStatus.Closed
    ) {
      setCloseButtonDisplay("block");
    } else {
      setCloseButtonDisplay("none");
    }

    if (
      (userinfo.Role === UserRole.Manager ||
        project.Estimator_Id === userinfo.Id) &&
      project.CaseStatus === CaseStatus.Closed
    ) {
      setDeleteProjectDisplay("flex");
      setChangeStatusContDisplay("flex");
    } else {
      setDeleteProjectDisplay("none");
    }

    if (
      project.CaseStatus === CaseStatus.PayPending &&
      userinfo.Role === UserRole.Manager
    ) {
      setConstructionButtonDisplay("block");
    }

    if (
      (project.Estimator_Id === 0 || userinfo.Role === UserRole.Manager) &&
      project.CaseStatus !== CaseStatus.Drafted
    ) {
      setTakeOverDisplay("block");
    } else setTakeOverDisplay("none");

    if (project.Estimator_Id === userinfo.Id) {
      setCreateQuoteDisplay("block");
    } else setCreateQuoteDisplay("none");

    if (
      (userinfo.Role === UserRole.Manager ||
        project.Estimator_Id === userinfo.Id) &&
      project.CaseStatus === CaseStatus.CancelRequested
    )
      setCancelButtonDisplay("block");
    else setCancelButtonDisplay("none");
  };

  const SetProposalVisibility = async (projectobj) => {
    if (projectobj.Quotes !== null) {
      if (projectobj.Quotes.length > 0) {
        setProposalVisible("block");
        setProjectProposalArrow("/uparrow.svg");
        setProjectProposalsDisplay("flex");
      } else {
        setProjectInfoDisplay("flex");
        setProjectInfoArrow("/downarrow.svg");
        setProposalVisible("none");
        setProjectProposalsDisplay("block");
      }
    }
  };

  const ChangeProjectStatusToSubmit = async (caseid) => {
    var data = await SetCaseTo_Submit(caseid);
    if (data != null) setStatusValue(CaseStatus.Submitted);
  };

  const CloseCase = async (caseid) => {
    if (caseid !== 0) {
      var data = await SetCaseTo_Close(caseid);
      if (data !== null) {
        alert("Case closed");
        setStatusValue(CaseStatus.Closed);
      }
    }
  };

  const GuideTakeoverCase = async (caseid, userid) => {
    if (caseid !== 0 && userid !== 0) {
      var data = await AssignCase(caseid, userid);
      if (data !== null) {
        if (data.Case.CaseStatus === CaseStatus.Submitted) {
          var accept = await SetCaseTo_Accept(caseid);
          if (accept !== null) {
            setStatusValue(CaseStatus.Accepted);
          }
        }
      }
    }
    SetupProjectForDisplay(caseid);
  };

  const SetProjectStatus = async (status, caseid) => {
    if (status === CaseStatus.Drafted) {
    } else if (status === CaseStatus.Submitted) {
      await SetCaseTo_Submit(caseid);
    } else if (status === CaseStatus.Accepted) {
      await SetCaseTo_Accept(caseid);
    } else if (status === CaseStatus.Processed) {
      await SetCaseTo_Processed(caseid);
    } else if (status === CaseStatus.Closed) {
      await SetCaseTo_Close(caseid);
    } else if (status === CaseStatus.CancelRequested) {
      await SetCaseTo_CancelRequested(caseid);
    } else if (status === CaseStatus.Canceled) {
      await SetCaseTo_Cancel(caseid);
    }
  };

  const GetListOfEstimators = async (storeid, role) => {
    if (role === UserRole.Manager || role === UserRole.Admin)
      if (storeid !== 0) {
        const resp = await axios.get(
          window.$DBURL + "store/" + storeid + "/estimators"
        );
        if (resp.status === 200) {
          if (resp.data !== null) {
            setListOfEstimators(resp.data.estimators);
            setChangeEstimatorDisplay("flex");
          }
        }
      }
  };

  const AssignToCase = async (caseid, userid) => {
    if (caseid !== 0) {
      var data = await AssignCase(caseid, userid);
      if (data !== null) {
        if (data.Case.CaseStatus === CaseStatus.Submitted) {
          var accept = await SetCaseTo_Accept(caseid);
          if (accept !== null) {
            SetupProjectForDisplay(caseid);
          }
        } else {
          SetupProjectForDisplay(caseid);
        }
      }
    }
  };

  const CaseToConstruction = async (caseid) => {
    var data = await SetCaseTo_Construction(caseid);
    if (data !== null) {
      SetupProjectForDisplay(caseid);
    }
  };

  const GetInvoiceObjectStructure = async () => {
    var data = await GetInvoiceObject();
    if (data !== null) {
      setInvoiceObj(data);
    }
  };

  const SaveCase = async (projectobj) => {
    var data = await UpdateACase(projectobj);
    await SetupProjectForDisplay(params.caseId);
  };

  const [cityList, setCityList] = useState([]);
  const [metroList, setMetroList] = useState([]);

  const GetLocationInfo = async () => {
    let metrodata = await GetMetroList();
    let citydata = await GetCityList(params.Id);
    setCityList(citydata);
    setMetroList(metrodata);
  };

  const UpdateCityList = async (metroid) => {
    if (metroid !== 0 && metroid !== 3) {
      // ContactInfo.current.City = "";
      // ContactInfo.current.City_Id = 0;
      let citydata = await GetCityList(metroid);
      setCityList(citydata);
    }
  };

  useEffect(() => {
    GetLocationInfo();
  }, []);

  const SelectCity = async (e) => {
    // Update projectObj.City_Id with the event target value.
    projectObj.City_Id = e.target.value;

    // Find the city object from cityList using the event target value.
    const selectedCity = cityList.find((o) => o.Id === e.target.value);

    // If a matching city object is found, update projectObj.City with its name.
    if (selectedCity) {
      projectObj.City = selectedCity.Name;
    }

    console.log(selectedCity); // Log the selected city object or undefined if not found.
  };

  const SelectMetro = async (e) => {
    // Update projectObj.Metro_Id with the event target value.
    projectObj.Metro_Id = e.target.value;

    // Find the metro object from metroList using the event target value.
    const selectedMetro = metroList.find((o) => o.Id === e.target.value);

    // If a matching metro object is found, update projectObj.Metro with its name.
    if (selectedMetro) {
      projectObj.Metro = selectedMetro.Name;
    }

    console.log(selectedMetro); // Log the selected metro object or undefined if not found.
  };

  useEffect(() => {
    SetHeaderAuthorization();
    var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
    setUserId(userinfo.Id);
    SetupProjectForDisplay(params.caseId);
    GetListOfEstimators(params.storeId, userinfo.Role);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reversedProposals = projectProposals?.slice()?.reverse();

  if (isLoading == true) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  } else if (projectObj !== undefined && projectObj !== null) {
    if(window.$ISMOBILE === true){
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu
            pageProp={"Projects - " + projectObj?.Tittle}
            mobilePageProp={projectObj?.Tittle}
          ></ActionMenu>

          <ContentCont77vw display={draftCaseVisible}>
            <FlexCont90PercentAutoHeight>
              <SmallHeaderLeagueSpartan>
                Ready to Submit?
              </SmallHeaderLeagueSpartan>
              <RegularResponsiveButton
                onClick={() => {
                  ChangeProjectStatusToSubmit(projectObj.Id);
                }}
              >
                Submit Project
              </RegularResponsiveButton>
            </FlexCont90PercentAutoHeight>
          </ContentCont77vw>

          {/* Project Information --------------------------------------------------------------- */}
          <FlexRowContFullWidth mobileflexdirection='row' mobilewidth='100vw' margintop='3%'>

          <FlexColumnDiv width='50%' alignitems='center' onClick={()=>{setEstimateTextMobileColor(ColorPalette.PrimaryButtonBlue); setProjectInfoTextMobileColor(ColorPalette.PrimaryGrey); setProposalVisible('block'); setProjectInfoDisplay('none')}}>
          <FlexDiv>
          <Icon30px20pxPointer color={estimateTextMobileColor} src="/newestimate_icon.svg"></Icon30px20pxPointer>
                  <LeagueSpartanHeaderSmall color={estimateTextMobileColor} margintop='auto' marginbottom='auto' mobilefontsize="12px" cursor="pointer">
                    Estimates
                  </LeagueSpartanHeaderSmall>
          </FlexDiv>
          <StyledHRPropColour border={`0.5px solid ${estimateTextMobileColor}`} width='100%'></StyledHRPropColour>
          </FlexColumnDiv>

          <FlexColumnDiv width='50%' alignitems='center' onClick={()=>{setEstimateTextMobileColor(ColorPalette.PrimaryGrey); setProjectInfoTextMobileColor(ColorPalette.PrimaryButtonBlue); setProposalVisible('none'); setProjectInfoDisplay('block')}}>
          <FlexDiv>
          <Icon30px20pxPointer color={projectInfoTextMobileColor} src="/Mobileprojects.svg"></Icon30px20pxPointer>
                  <LeagueSpartanHeaderSmall color={projectInfoTextMobileColor} margintop='auto' marginbottom='auto' mobilefontsize="12px" cursor="pointer">
                    Project Info
                  </LeagueSpartanHeaderSmall>
          </FlexDiv>
          <StyledHRPropColour border={`0.5px solid ${projectInfoTextMobileColor}`} width='100%'></StyledHRPropColour>
          </FlexColumnDiv>

          </FlexRowContFullWidth>


          <ContentCont77vw
            paddingbottom="0"
            paddingTop="5px"
            mobilepaddingbottom="0"
            mobilemargin="0%"
            margin="0%"
          >
            <ProjectPhotoUploadPreviewWindow open={isUploadProjectPhotosOpen} CloseWindow={()=>{
              setIsUploadProjectPhotosOpen(false); SetupProjectForDisplay(params.caseId);
              GetListOfEstimators(params.storeId, userinfo.Role);
            }}></ProjectPhotoUploadPreviewWindow>
            <FlexDiv mobilewidth='92.5vw'>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
                mobileflexdirection="row"
                mobilewidth='92.5vw'
                mobilejustifycontent="space-between"
                mobilealignitems="baseline"
              >
                <FlexRowContAutox100Percent
                  display={changeStatusContDisplay}
                  mobiledisplay="none"
                >
                  <Select16px
                    onChange={(e) => {
                      setStatusValue(e.target.value);
                    }}
                  >
                    <Option16px value={0}>Change Status</Option16px>
                    <Option16px value={1}>Submitted</Option16px>
                    <Option16px value={2}>Accepted</Option16px>
                    <Option16px value={3}>Processed</Option16px>
                    <Option16px value={4}>Closed</Option16px>
                    <Option16px value={5}>Request Cancel</Option16px>
                    <Option16px value={-1}>Canceled</Option16px>
                  </Select16px>
                  <BlueUnderlinePointerPara
                    onClick={() => {
                      SetProjectStatus(statusValue, params.caseId);
                    }}
                  >
                    Save
                  </BlueUnderlinePointerPara>
                </FlexRowContAutox100Percent>

                <GuideCancelProjectWindow
                  deleteOpen={cancelProjectWindowDisplay}
                  projectId={params.caseId}
                  CloseWindow={() => {
                    setCancelProjectWindowDisplay(false);
                  }}
                ></GuideCancelProjectWindow>
            
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  display={cancelButtonDisplay}
                  onClick={() => {
                    setCancelProjectWindowDisplay(true);
                  }}
                >
                  Cancel
                </RegularResponsiveButton>
                <div>
                  <FlexDiv>
                <FlexDiv
                  onClick={() => {
                    setCreateQuoteWindowDisplay(true);
                  }}
                >
                  <IconButton width='40vw' text='New Estimate' src='/paperwhite_icon.svg'></IconButton>
                </FlexDiv>
                <AreaInputPopup
                  visible={createQuoteWindowDisplay}
                  createRegular={true}
                  onClose={() => {
                    setCreateQuoteWindowDisplay(false);
                  }}
                ></AreaInputPopup>
              
                <FlexDiv
                  onClick={() => {
                    history.push(
                      `/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/0`
                    );
                  }}
                >
                  <IconButton width='40vw' text='New Site-Map' src='/pinwhite_icon.svg'></IconButton>
                  </FlexDiv>
                </FlexDiv>

                <FlexDiv>

                <FlexDiv
                  onClick={() => {
                    GuideTakeoverCase(params.caseId, userId);
                  }}
                >
                  <IconButton width='40vw' text='Take Over Project' src='/smilewhite_icon.svg'></IconButton>
                  </FlexDiv>
                <FlexDiv onClick={()=>{setIsUploadProjectPhotosOpen(true)}}>
                <IconButton width='40vw' text='Add Photos' src='/photowhite_icon.svg'></IconButton>
                </FlexDiv>
                </FlexDiv>
</div>
                <FlexRowContAutox100Percent
                  display={changeEstimatorDisplay}
                  mobiledisplay="none"
                >
                  <Select16px
                    onChange={(e) => {
                      setGuideUserId(e.target.value);
                    }}
                  >
                    <Option16px value={0}>Assign Guide</Option16px>
                    {listOfEstimators.map((o, i) => (
                      <Option16px key={i} value={o.User_Id}>
                        {o.Name}
                      </Option16px>
                    ))}
                  </Select16px>
                  <button
                    bgcolor={ColorPalette.SecondaryGreen}
                    onClick={() => {
                      AssignToCase(params.caseId, guideUserId);
                    }}
                  >
                    Assign
                  </button>
                </FlexRowContAutox100Percent>
               
              </FlexColumnCont>
          
            </FlexDiv>
            {window.$ISMOBILE === true ? <hr></hr> : null}
            <FlexColumnFullWidth
              display={projectInfoDisplay}
              mobiledisplay={projectInfoDisplay}
            >
              {/* <FlexColumnCont
                flexdirection="row"
                justifycontent="flex-start"
                height="auto"
                width="80%"
                margin="0"
                mobileflexdirection="column"
                mobilewidth="90vw"
                tabletflexdirection="column"
                tabletalignitems="baseline"
              >
                <FlexInfoCont mobilewidth="100%">
                  <FlexDiv>
  
                  </FlexDiv>
                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>Project Name:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader display={editCaseInfo ? "none" : "block"}>
                      {projectObj.Tittle}
                    </SmallHeader>
                    <TextInputReusable
                      display={editCaseInfo ? "block" : "none"}
                      defaultValue={projectObj.Tittle}
                      onChange={(e) => {
                        projectObj.Tittle = e.target.value;
                      }}
                    ></TextInputReusable>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>Full Name:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader display={editCaseInfo ? "none" : "block"}>
                      {projectObj.Name}
                    </SmallHeader>
                    <TextInputReusable
                      display={editCaseInfo ? "block" : "none"}
                      defaultValue={projectObj.Name}
                      onChange={(e) => {
                        projectObj.Name = e.target.value;
                      }}
                    ></TextInputReusable>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>Phone Number:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader display={editCaseInfo ? "none" : "block"}>
                      {projectObj.Phone}
                    </SmallHeader>
                    <TextInputReusable
                      display={editCaseInfo ? "block" : "none"}
                      defaultValue={projectObj.Phone}
                      onChange={(e) => {
                        projectObj.Phone = e.target.value;
                      }}
                    ></TextInputReusable>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>Email Address:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader display={editCaseInfo ? "none" : "block"}>
                      {projectObj.Email}
                    </SmallHeader>
                    <TextInputReusable
                      display={editCaseInfo ? "block" : "none"}
                      defaultValue={projectObj.Email}
                      onChange={(e) => {
                        projectObj.Email = e.target.value;
                      }}
                    ></TextInputReusable>
                  </WideFlexInfoCont>
                </FlexInfoCont>

                <FlexInfoCont mobilewidth="100%">
                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>Address:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader display={editCaseInfo ? "none" : "block"}>
                      {projectObj.Street}
                    </SmallHeader>
                    <TextInputReusable
                      display={editCaseInfo ? "block" : "none"}
                      defaultValue={projectObj.Street}
                      onChange={(e) => {
                        projectObj.Street = e.target.value;
                      }}
                    ></TextInputReusable>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont >
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>City:</SmallHeader>
                    </WideFlexInfoCont>

                    {editCaseInfo ? (
                      <DatalistMedium
                        defaultValue={projectObj.City}
                        inputwidth="100%"
                        inputheight="40%"
                        id="cities"
                        onChange={(e) => {
                          SelectCity(e);
                          // UpdateCityList(e.target.value);
                        }}
                      >
                        <DatalistOption value={0}>Select City</DatalistOption>
                        {cityList.map((o, i) => (
                          <DatalistOption
                            id="city"
                            key={o.Id}
                            value={o.Id}
                            defaultValue={o.Id === projectObj.City_Id}
                            selected={o.Id === projectObj.City_Id}
                          >
                            {o.Name}
                          </DatalistOption>
                        ))}
                      </DatalistMedium>
                    ) : (
                      <SmallHeader display="block">
                        {projectObj.City}
                      </SmallHeader>
                    )}
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader minwidth='100px'>Region:</SmallHeader>
                    </WideFlexInfoCont>

                    {editCaseInfo ? (
                      <DatalistMedium
                        defaultValue={projectObj.Metro}
                        inputwidth="100%"
                        inputheight="40%"
                        id="metros"
                        onChange={(e) => {
                          SelectMetro(e);
                          UpdateCityList(e.target.value); // Assuming you want to update something related to the metro selection.
                        }}
                      >
                        <DatalistOption value={0}>Select Region</DatalistOption>
                        {metroList.map((o) => (
                          <DatalistOption
                            key={o.Id}
                            value={o.Id}
                            defaultValue={o.Id === projectObj.Metro_Id}
                            selected={o.Id === projectObj.Metro_Id}
                          >
                            {o.Name}
                          </DatalistOption>
                        ))}
                      </DatalistMedium>
                    ) : (
                      <SmallHeader display="block">
                        {projectObj.Metro}
                      </SmallHeader>
                    )}
                  </WideFlexInfoCont>
                </FlexInfoCont>
                <WideFlexInfoCont display={editCaseInfo ? "flex" : "none"}>
                  <RegularResponsiveButton
                    width="100px"
                    onClick={() => {
                      setEditCaseInfo(false);
                      SaveCase(projectObj);
                    }}
                  >
                    Save
                  </RegularResponsiveButton>
                  <RegularResponsiveButton
                    width="100px"
                    onClick={() => {
                      setEditCaseInfo(false);
                    }}
                  >
                    Cancel
                  </RegularResponsiveButton>
                </WideFlexInfoCont>
              </FlexColumnCont> */}

                        {/* //START OF EDIT PROJECT */}
          
<EditFormContainer>

<FlexDiv onClick={() => {
                          setEditCaseInfo(true);
                        }}
                        >
                      <Icon30px20pxPointer
                      src="/editprojectinfo_icon.svg"
                      ></Icon30px20pxPointer>
                      <RegularText cursor="pointer">Edit Project</RegularText>
                    </FlexDiv>

      {/* LEFT COLUMN */}
      <EditFormColumn>
        {/* PROJECT NAME */}
        <EditFieldRow>
          <EditFieldLabel>Project Name:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Tittle}
              onChange={(e) => {
                projectObj.Tittle = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Tittle}</span>
          )}
        </EditFieldRow>

        {/* FULL NAME */}
        <EditFieldRow>
          <EditFieldLabel>Full Name:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Name}
              onChange={(e) => {
                projectObj.Name = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Name}</span>
          )}
        </EditFieldRow>

        {/* PHONE NUMBER */}
        <EditFieldRow>
          <EditFieldLabel>Phone Number:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Phone}
              onChange={(e) => {
                projectObj.Phone = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Phone}</span>
          )}
        </EditFieldRow>

        {/* EMAIL */}
        <EditFieldRow>
          <EditFieldLabel>Email Address:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Email}
              onChange={(e) => {
                projectObj.Email = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Email}</span>
          )}
        </EditFieldRow>
      </EditFormColumn>
      <br></br>

      {/* RIGHT COLUMN */}
      <EditFormColumn>
        {/* ADDRESS */}
        <EditFieldRow>
          <EditFieldLabel>Address:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Street}
              onChange={(e) => {
                projectObj.Street = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Street}</span>
          )}
        </EditFieldRow>

   {/* REGION */}
   <EditFieldRow>
          <EditFieldLabel>Region:</EditFieldLabel>
          {editCaseInfo ? (
            <EditSelect
              onChange={(e) => {
                SelectMetro(e);
                UpdateCityList(e.target.value);
              }}
              defaultValue={projectObj.Metro_Id}
            >
              <option value={0}>Select Region</option>
              {metroList.map((o) => (
                <option key={o.Id} value={o.Id}>
                  {o.Name}
                </option>
              ))}
            </EditSelect>
          ) : (
            <span>{projectObj.Metro}</span>
          )}
        </EditFieldRow>

        {/* CITY */}
        <EditFieldRow>
          <EditFieldLabel>City:</EditFieldLabel>
          {editCaseInfo ? (
            <EditSelect
              onChange={(e) => SelectCity(e)}
              defaultValue={projectObj.City_Id}
            >
              <option value={0}>Select City</option>
              {cityList.map((o) => (
                <option key={o.Id} value={o.Id}>
                  {o.Name}
                </option>
              ))}
            </EditSelect>
          ) : (
            <span>{projectObj.City}</span>
          )}
        </EditFieldRow>

        {/* SAVE/CANCEL (only when editing) */}
        {editCaseInfo && (
          <EditActionButtonsRow>
            <IconButton bgcolor={ColorPalette.PrimaryGrey} onClick={() => {
                setEditCaseInfo(false);
              }}
              style={{ backgroundColor: "#aaa" }}>Cancel</IconButton>
              <IconButton    onClick={() => {
                setEditCaseInfo(false);
                SaveCase(projectObj);
              }} src='/saveiconwhite_icon.svg'>Save</IconButton>
          </EditActionButtonsRow>
        )}
      </EditFormColumn>
    </EditFormContainer>

              {/* //END OF EDIT PROJECT */}

              <FlexContBottomGrey width="95%" height="1%"></FlexContBottomGrey>

              <br></br>

              {/* <FlexColumnCont
                alignitems="left"
                height="100%"
                margintop="2%"
                margin="0"
                width="100%"
              >
                <SmallHeader paddingbottom="1.5%">
                  Project Description:
                </SmallHeader>
                <LandscapePara
                  mobilewidth='85vw'
                  disabled={!editCaseDesc}
                  //disabled
                  defaultValue={projectObj.Description}
                  onChange={(e) => {
                    projectObj.Description = e.target.value;
                  }}
                ></LandscapePara>
              </FlexColumnCont> */}
              <WideFlexInfoCont>
                <RegularResponsiveButton
                  width="100px"
                  onClick={() => {
                    setEditCaseDesc(true);
                  }}
                  display={
                    editCaseDesc ||
                    projectObj.CaseStatus >= CaseStatus.Processed
                      ? "none"
                      : "block"
                  }
                >
                  Edit
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  width="100px"
                  onClick={() => {
                    SaveCase(projectObj);
                    setEditCaseDesc(false);
                  }}
                  display={editCaseDesc ? "block" : "none"}
                >
                  Save
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  width="100px"
                  onClick={() => {
                    setEditCaseDesc(false);
                  }}
                  display={editCaseDesc ? "block" : "none"}
                >
                  Cancel
                </RegularResponsiveButton>
              </WideFlexInfoCont>
              <FlexContFullWidthJustifyCenter>
                <GridContainer2Wide mobilewidth='95vw'>
                  {projectPhotos.map((o, i) => (
                    <FlexDivFlexStartCenter margin='1%' key={i}>
                      <a
                        rel="noreferrer"
                        href={window.$IMGURL + o.File}
                        target="_blank"
                      >
                        <FlexRowCont300x200>
                          <Image300x200
                            width='90vw'
                            src={window.$IMGURL + o.File}
                            alt={"File:" + o.File}
                          ></Image300x200>
                        </FlexRowCont300x200>
                      </a>
                      <SmallTextPadding width='85vw'>{o.Caption}</SmallTextPadding>
                      <StyledHRGreenBreakDashed></StyledHRGreenBreakDashed>
                    </FlexDivFlexStartCenter>
                  ))}

                  {PDFMap.map((o, i) => (
                    <FlexDivFlexStartCenter>
                      <FlexRowCont300x200>
                        <a
                          rel="noreferrer"
                          href={window.$IMGURL + o.File}
                          target="_blank"
                        >
                          <PhotoContExtra key={i}>
                            <FlexColumnCont height="auto">
                              <Image80x100px
                                src={"/pdficon.png"}
                                alt={"File:" + o.File}
                              ></Image80x100px>
                              <LargeResponsiveButton>
                                View PDF
                              </LargeResponsiveButton>
                            </FlexColumnCont>
                          </PhotoContExtra>
                        </a>
                      </FlexRowCont300x200>
                      <SmallTextPadding>{o.Caption}</SmallTextPadding>
                    </FlexDivFlexStartCenter>
                  ))}
                </GridContainer2Wide>
              </FlexContFullWidthJustifyCenter>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  setDeleteProjectWindowDisplay(true);
                }}
                display={deleteProjectDisplay}
              >
                <Icon20x20 src="/Garbage_Icon.svg"></Icon20x20>
                Delete Project
              </RegularResponsiveButton>

              <DeleteProjectWindow
                projectId={projectObj.Id}
                deleteOpen={deleteProjectWindowDisplay}
                CloseWindow={() => {
                  setDeleteProjectWindowDisplay(false);
                }}
              ></DeleteProjectWindow>
            </FlexColumnFullWidth>
          </ContentCont77vw>

          {/* Project Proposals --------------------------------------------------------------- */}

          <ContentCont77vw paddingTop='0px' mobilemargin='0%' display={proposalVisible}>
            <FlexDiv>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
              </FlexColumnCont>
            </FlexDiv>
            <FlexColumnFullWidth margin="0" display={projectProposalsDisplay}>
              <FlexColumnCont
                justifycontent="flex-start"
                width="73vw"
                height="auto"
                margin="0"
              >

                <FlexColumnCont
                  margin="0"
                  alignitems="left"
                  width="70vw"
                  height="auto"
                >
                  <DeleteProposalWindow
                    proposalid={deleteProposalId}
                    deleteOpen={deleteProposalDisplay}
                    CloseWindow={() => {
                      setDeleteProposalDisplay(false);
                    }}
                  ></DeleteProposalWindow>

{[...reversedProposals]
  .sort((a, b) => {
    // Sort by Buy_Install first
    if (a.QuoteStatus === QuoteStatus.Buy_Install && b.QuoteStatus !== QuoteStatus.Buy_Install) {
      return -1; // a should come before b
    }
    if (a.QuoteStatus !== QuoteStatus.Buy_Install && b.QuoteStatus === QuoteStatus.Buy_Install) {
      return 1; // b should come before a
    }
    // Secondary sorting criteria can be added here (e.g., by date or other priority)
    return 0;
  })
  .map((o, i) => {
    if (o.Revision === 0 && o.QuoteStatus !== QuoteStatus.Expired) {
      return (
        <div key={i}>
          <ProposalCardGuide
            quoteid={o.Id}
            proposalObj={o}
            DeleteProposal={() => {
              setDeleteProposalId(o.Id);
              setDeleteProposalDisplay(true);
            }}
            UpdateProjectUI={() => {
              SetupProjectForDisplay(params.caseId);
            }}
          />
          {reversedProposals.map((revisionobj, j) => {
            if (
              revisionobj.Revision !== 0 &&
              revisionobj.Proposal === o.Proposal &&
              revisionobj.QuoteStatus !== QuoteStatus.Expired
            ) {
              return (
                <ProposalCardGuide
                  key={`${i}-${j}`}
                  quoteid={revisionobj.Id}
                  proposalObj={revisionobj}
                  DeleteProposal={() => {
                    setDeleteProposalId(revisionobj.Id);
                    setDeleteProposalDisplay(true);
                  }}
                  UpdateProjectUI={() => {
                    SetupProjectForDisplay(params.caseId);
                  }}
                />
              );
            } else return null;
          })}
        </div>
      );
    } else return null;
  })}

                </FlexColumnCont>
              </FlexColumnCont>
            </FlexColumnFullWidth>
          </ContentCont77vw>

          {/* Expired Estimates */}

          <ContentCont77vw display={expiredProposalVisible}>
            {window.$ISMOBILE === true ? <hr></hr> : null}{" "}
            <FlexDiv>
              <FlexColumnCont
                height="8vh"
                mobilewidth="80vw"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Archived Estimates
                </SmallHeaderLeagueSpartan>
              </FlexColumnCont>
              <Icon30x30
                onClick={() => {
                  DisplayProjectProposals(
                    expiredProjectProposalArrow,
                    "expired"
                  );
                }}
                src={expiredProjectProposalArrow}
              ></Icon30x30>
            </FlexDiv>
            <FlexColumnFullWidth
              margin="0"
              display={expiredProjectProposalsDisplay}
            >
              <FlexColumnCont
                justifycontent="flex-start"
                width="73vw"
                height="auto"
                margin="0"
              >
                <FlexColumnCont
                  margin="0"
                  alignitems="left"
                  width="70vw"
                  height="auto"
                >
                  <DeleteProposalWindow
                    proposalid={deleteProposalId}
                    deleteOpen={deleteProposalDisplay}
                    CloseWindow={() => {
                      setDeleteProposalDisplay(false);
                    }}
                  ></DeleteProposalWindow>

                  {projectProposals.map((o, i) => {
                    if (
                      o.Revision === 0 &&
                      o.QuoteStatus === QuoteStatus.Expired
                    ) {
                      return (
                        <div key={i}>
                          <ProposalCardGuide
                            quoteid={o.Id}
                            proposalObj={o}
                            DeleteProposal={() => {
                              setDeleteProposalId(o.Id);
                              setDeleteProposalDisplay(true);
                            }}
                            UpdateProjectUI={() => {
                              SetupProjectForDisplay(params.caseId);
                            }}
                          ></ProposalCardGuide>
                          {projectProposals.map((revisionobj, i) => {
                            if (
                              revisionobj.Revision !== 0 &&
                              revisionobj.Proposal === o.Proposal &&
                              revisionobj.QuoteStatus === "QuoteStatus.Expired"
                            ) {
                              return (
                                <ProposalCardGuide
                                  key={i}
                                  quoteid={revisionobj.Id}
                                  proposalObj={revisionobj}
                                  DeleteProposal={() => {
                                    setDeleteProposalId(revisionobj.Id);
                                    setDeleteProposalDisplay(true);
                                  }}
                                  UpdateProjectUI={() => {
                                    SetupProjectForDisplay(params.caseId);
                                  }}
                                ></ProposalCardGuide>
                              );
                            } else return null;
                          })}
                        </div>
                      );
                    } else return null;
                  })}
                </FlexColumnCont>
              </FlexColumnCont>
            </FlexColumnFullWidth>
          </ContentCont77vw>

          <FlexDivFlexEnd>
            <BlueUnderlinePointerPara
              onClick={() => {
                DeleteProject(params.caseId);
              }}
              color={ColorPalette.PrimaryRed}
            >
              Delete Project
            </BlueUnderlinePointerPara>
          </FlexDivFlexEnd>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu
            pageProp={"Projects - " + projectObj?.Tittle}
            mobilePageProp={projectObj?.Tittle}
          ></ActionMenu>

          <ContentCont77vw display={draftCaseVisible}>
            <FlexCont90PercentAutoHeight>
              <SmallHeaderLeagueSpartan>
                Ready to Submit?
              </SmallHeaderLeagueSpartan>
              <RegularResponsiveButton
                onClick={() => {
                  ChangeProjectStatusToSubmit(projectObj.Id);
                }}
              >
                Submit Project
              </RegularResponsiveButton>
            </FlexCont90PercentAutoHeight>
          </ContentCont77vw>

          {/* Project Information --------------------------------------------------------------- */}

          <ContentCont77vw
            paddingbottom="0"
            paddingTop="5px"
            mobilepaddingbottom="0"
            mobilemargin="0%"
            margin="0%"
          >
            <ProjectPhotoUploadPreviewWindow open={isUploadProjectPhotosOpen} CloseWindow={()=>{
              setIsUploadProjectPhotosOpen(false); SetupProjectForDisplay(params.caseId);
              GetListOfEstimators(params.storeId, userinfo.Role);
            }}></ProjectPhotoUploadPreviewWindow>
            <FlexDiv mobilewidth='92.5vw'>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
                mobileflexdirection="row"
                mobilewidth='92.5vw'
                mobilejustifycontent="space-between"
                mobilealignitems="baseline"
              >
                <FlexRowContAutox100Percent
                  display={changeStatusContDisplay}
                  mobiledisplay="none"
                >
                  <Select16px
                    onChange={(e) => {
                      setStatusValue(e.target.value);
                    }}
                  >
                    <Option16px value={0}>Change Status</Option16px>
                    <Option16px value={1}>Submitted</Option16px>
                    <Option16px value={2}>Accepted</Option16px>
                    <Option16px value={3}>Processed</Option16px>
                    <Option16px value={4}>Closed</Option16px>
                    <Option16px value={5}>Request Cancel</Option16px>
                    <Option16px value={-1}>Canceled</Option16px>
                  </Select16px>
                  <BlueUnderlinePointerPara
                    onClick={() => {
                      SetProjectStatus(statusValue, params.caseId);
                    }}
                  >
                    Save
                  </BlueUnderlinePointerPara>
                </FlexRowContAutox100Percent>

                <GuideCancelProjectWindow
                  deleteOpen={cancelProjectWindowDisplay}
                  projectId={params.caseId}
                  CloseWindow={() => {
                    setCancelProjectWindowDisplay(false);
                  }}
                ></GuideCancelProjectWindow>
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  display={cancelButtonDisplay}
                  onClick={() => {
                    setCancelProjectWindowDisplay(true);
                  }}
                >
                  Cancel
                </RegularResponsiveButton>
                <div>
                  <FlexDiv>
                <FlexDiv
                  onClick={() => {
                    setCreateQuoteWindowDisplay(true);
                  }}
                >
                  <Icon30px20pxPointer src="/newestimate_icon.svg"></Icon30px20pxPointer>
                  <RegularText mobilefontsize="16px" cursor="pointer">
                    Estimate
                  </RegularText>
                </FlexDiv>
                <AreaInputPopup
                  visible={createQuoteWindowDisplay}
                  createRegular={true}
                  onClose={() => {
                    setCreateQuoteWindowDisplay(false);
                  }}
                ></AreaInputPopup>
              
                <FlexDiv
                  onClick={() => {
                    history.push(
                      `/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/0`
                    );
                  }}
                >
                  <Icon30px20pxPointer src="/sitemap_icon.svg"></Icon30px20pxPointer>
                  <RegularText mobilefontsize="16px" cursor="pointer">
                    Sitemap
                  </RegularText>
                </FlexDiv>
            
                <FlexDiv
                  onClick={() => {
                    GuideTakeoverCase(params.caseId, userId);
                  }}
                >
                  <Icon30px20pxPointer src="/takeover_icon.svg"></Icon30px20pxPointer>
                  <RegularText mobilefontsize="16px" cursor="pointer">
                    Self-Assign
                  </RegularText>
                </FlexDiv>
                <FlexDiv onClick={()=>{setIsUploadProjectPhotosOpen(true)}}>
                  <Icon30px20pxPointer src='/Green Button.svg'></Icon30px20pxPointer>
                  <RegularText mobilefontsize="16px" cursor="pointer">
                    Photos
                  </RegularText>
                </FlexDiv>
                <FlexDiv onClick={() => {
                          setEditCaseInfo(true);
                        }}
                        >
                      <Icon30px20pxPointer
                      src="/editprojectinfo_icon.svg"
                      ></Icon30px20pxPointer>
                      <RegularText cursor="pointer">Edit Project</RegularText>
                    </FlexDiv>
                </FlexDiv>
</div>
                <FlexRowContAutox100Percent
                  display={changeEstimatorDisplay}
                  mobiledisplay="none"
                >
                  <Select16px
                    onChange={(e) => {
                      setGuideUserId(e.target.value);
                    }}
                  >
                    <Option16px value={0}>Assign Guide</Option16px>
                    {listOfEstimators.map((o, i) => (
                      <Option16px key={i} value={o.User_Id}>
                        {o.Name}
                      </Option16px>
                    ))}
                  </Select16px>
                  <button
                    bgcolor={ColorPalette.SecondaryGreen}
                    onClick={() => {
                      AssignToCase(params.caseId, guideUserId);
                    }}
                  >
                    Assign
                  </button>
                </FlexRowContAutox100Percent>
               
              </FlexColumnCont>
              <Icon30x30
                src={projectInfoArrow}
                onClick={() => {
                  DisplayProjectInfo(projectInfoArrow);
                }}
              ></Icon30x30>
            </FlexDiv>
            {window.$ISMOBILE === true ? <hr></hr> : null}
            <FlexColumnFullWidth
              display={projectInfoDisplay}
              mobiledisplay={projectInfoDisplay}
            >

            {/* //START OF EDIT PROJECT */}
          
<EditFormContainer>
      {/* LEFT COLUMN */}
      <EditFormColumn>
        {/* PROJECT NAME */}
        <EditFieldRow>
          <EditFieldLabel>Project Name:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Tittle}
              onChange={(e) => {
                projectObj.Tittle = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Tittle}</span>
          )}
        </EditFieldRow>

        {/* FULL NAME */}
        <EditFieldRow>
          <EditFieldLabel>Full Name:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Name}
              onChange={(e) => {
                projectObj.Name = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Name}</span>
          )}
        </EditFieldRow>

        {/* PHONE NUMBER */}
        <EditFieldRow>
          <EditFieldLabel>Phone Number:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Phone}
              onChange={(e) => {
                projectObj.Phone = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Phone}</span>
          )}
        </EditFieldRow>

        {/* EMAIL */}
        <EditFieldRow>
          <EditFieldLabel>Email Address:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Email}
              onChange={(e) => {
                projectObj.Email = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Email}</span>
          )}
        </EditFieldRow>
      </EditFormColumn>

      {/* RIGHT COLUMN */}
      <EditFormColumn>
        {/* ADDRESS */}
        <EditFieldRow>
          <EditFieldLabel>Address:</EditFieldLabel>
          {editCaseInfo ? (
            <EditTextInput
              defaultValue={projectObj.Street}
              onChange={(e) => {
                projectObj.Street = e.target.value;
              }}
            />
          ) : (
            <span>{projectObj.Street}</span>
          )}
        </EditFieldRow>

   {/* REGION */}
   <EditFieldRow>
          <EditFieldLabel>Region:</EditFieldLabel>
          {editCaseInfo ? (
            <EditSelect
              onChange={(e) => {
                SelectMetro(e);
                UpdateCityList(e.target.value);
              }}
              defaultValue={projectObj.Metro_Id}
            >
              <option value={0}>Select Region</option>
              {metroList.map((o) => (
                <option key={o.Id} value={o.Id}>
                  {o.Name}
                </option>
              ))}
            </EditSelect>
          ) : (
            <span>{projectObj.Metro}</span>
          )}
        </EditFieldRow>

        {/* CITY */}
        <EditFieldRow>
          <EditFieldLabel>City:</EditFieldLabel>
          {editCaseInfo ? (
            <EditSelect
              onChange={(e) => SelectCity(e)}
              defaultValue={projectObj.City_Id}
            >
              <option value={0}>Select City</option>
              {cityList.map((o) => (
                <option key={o.Id} value={o.Id}>
                  {o.Name}
                </option>
              ))}
            </EditSelect>
          ) : (
            <span>{projectObj.City}</span>
          )}
        </EditFieldRow>

        {/* SAVE/CANCEL (only when editing) */}
        {editCaseInfo && (
          <EditActionButtonsRow>
            <IconButton bgcolor={ColorPalette.PrimaryGrey} onClick={() => {
                setEditCaseInfo(false);
              }}
              style={{ backgroundColor: "#aaa" }}>Cancel</IconButton>
              <IconButton    onClick={() => {
                setEditCaseInfo(false);
                SaveCase(projectObj);
              }} src='/saveiconwhite_icon.svg'>Save</IconButton>
          </EditActionButtonsRow>
        )}
      </EditFormColumn>
    </EditFormContainer>

              {/* //END OF EDIT PROJECT */}

              <FlexContBottomGrey width="95%" height="1%"></FlexContBottomGrey>

              <br></br>
{/* 
              <FlexColumnCont
                flexdirection="row"
                justifycontent="flex-start"
                height="auto"
                width="80%"
                margin="0"
                mobileflexdirection="column"
                mobilewidth="90vw"
                tabletflexdirection="column"
                tabletalignitems="baseline"
              >
                <FlexInfoCont mobilewidth="100%">
                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader>Project Area:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader>{projectAreaString}</SmallHeader>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader>Project Scope:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader>{projectScopeString}</SmallHeader>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader>Project Type:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader>{ProjectTypeString}</SmallHeader>
                  </WideFlexInfoCont>
                </FlexInfoCont>

                <FlexInfoCont mobilewidth="100%">
                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader>Project Style:</SmallHeader>
                    </WideFlexInfoCont>
                    <SmallHeader>
                      {ProjectStyleString === "N/"
                        ? "Not Selected"
                        : ProjectStyleString}
                    </SmallHeader>
                  </WideFlexInfoCont>

                  <WideFlexInfoCont>
                    <WideFlexInfoCont width="175px">
                      <SmallHeader>Project Budget:</SmallHeader>
                    </WideFlexInfoCont>

                    <SmallHeader>{ProjectBudgetString}</SmallHeader>
                  </WideFlexInfoCont>
                </FlexInfoCont>
              </FlexColumnCont> */}

              {/* <FlexColumnCont
                alignitems="left"
                height="100%"
                margintop="2%"
                margin="0"
                width="100%"
              >
                <SmallHeader paddingbottom="1.5%">
                  Project Description:
                </SmallHeader>
                <LandscapePara
                  disabled={!editCaseDesc}
                  //disabled
                  defaultValue={projectObj.Description}
                  onChange={(e) => {
                    projectObj.Description = e.target.value;
                  }}
                ></LandscapePara>
              </FlexColumnCont> */}
              {/* <WideFlexInfoCont>
                <RegularResponsiveButton
                  width="100px"
                  onClick={() => {
                    setEditCaseDesc(true);
                  }}
                  display={
                    editCaseDesc ||
                    projectObj.CaseStatus >= CaseStatus.Processed
                      ? "none"
                      : "block"
                  }
                >
                  Edit
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  width="100px"
                  onClick={() => {
                    SaveCase(projectObj);
                    setEditCaseDesc(false);
                  }}
                  display={editCaseDesc ? "block" : "none"}
                >
                  Save
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  width="100px"
                  onClick={() => {
                    setEditCaseDesc(false);
                  }}
                  display={editCaseDesc ? "block" : "none"}
                >
                  Cancel
                </RegularResponsiveButton>
              </WideFlexInfoCont> */}
              <FlexContFullWidthJustifyCenter>
                <GridContainer2Wide>
                  {projectPhotos.map((o, i) => (
                    <FlexDivFlexStartCenter key={i}>
                      <a
                        rel="noreferrer"
                        href={window.$IMGURL + o.File}
                        target="_blank"
                      >
                        <FlexRowCont300x200>
                          <Image300x200
                            src={window.$IMGURL + o.File}
                            alt={"File:" + o.File}
                          ></Image300x200>
                        </FlexRowCont300x200>
                      </a>
                      <SmallTextPadding>{o.Caption}</SmallTextPadding>
                    </FlexDivFlexStartCenter>
                  ))}

                  {PDFMap.map((o, i) => (
                    <FlexDivFlexStartCenter>
                      <FlexRowCont300x200>
                        <a
                          rel="noreferrer"
                          href={window.$IMGURL + o.File}
                          target="_blank"
                        >
                          <PhotoContExtra key={i}>
                            <FlexColumnCont height="auto">
                              <Image80x100px
                                src={"/pdficon.png"}
                                alt={"File:" + o.File}
                              ></Image80x100px>
                              <LargeResponsiveButton>
                                View PDF
                              </LargeResponsiveButton>
                            </FlexColumnCont>
                          </PhotoContExtra>
                        </a>
                      </FlexRowCont300x200>
                      <SmallTextPadding>{o.Caption}</SmallTextPadding>
                    </FlexDivFlexStartCenter>
                  ))}
                </GridContainer2Wide>
              </FlexContFullWidthJustifyCenter>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  setDeleteProjectWindowDisplay(true);
                }}
                display={deleteProjectDisplay}
              >
                <Icon20x20 src="/Garbage_Icon.svg"></Icon20x20>
                Delete Project
              </RegularResponsiveButton>

              <DeleteProjectWindow
                projectId={projectObj.Id}
                deleteOpen={deleteProjectWindowDisplay}
                CloseWindow={() => {
                  setDeleteProjectWindowDisplay(false);
                }}
              ></DeleteProjectWindow>
            </FlexColumnFullWidth>
          </ContentCont77vw>

          {/* Project Proposals --------------------------------------------------------------- */}

          <ContentCont77vw display={proposalVisible}>
            {/* <MobileOnlyRegularHeader>
              {projectObj.Tittle}
            </MobileOnlyRegularHeader> */}
            <FlexDiv>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Estimates
                </SmallHeaderLeagueSpartan>
              </FlexColumnCont>
              <Icon30x30
                onClick={() => {
                  DisplayProjectProposals(projectProposalArrow, "nonExpired");
                }}
                src={projectProposalArrow}
              ></Icon30x30>
            </FlexDiv>
            <FlexColumnFullWidth margin="0" display={projectProposalsDisplay}>
              <FlexColumnCont
                justifycontent="flex-start"
                width="73vw"
                height="auto"
                margin="0"
              >

                <FlexColumnCont
                  margin="0"
                  alignitems="left"
                  width="70vw"
                  height="auto"
                >
                  <DeleteProposalWindow
                    proposalid={deleteProposalId}
                    deleteOpen={deleteProposalDisplay}
                    CloseWindow={() => {
                      setDeleteProposalDisplay(false);
                    }}
                  ></DeleteProposalWindow>

                  {reversedProposals.map((o, i) => {
                    if (
                      o.Revision === 0 &&
                      o.QuoteStatus !== QuoteStatus.Expired
                    ) {
                      return (
                        <div key={i}>
                          <ProposalCardGuide
                            quoteid={o.Id}
                            proposalObj={o}
                            DeleteProposal={() => {
                              setDeleteProposalId(o.Id);
                              setDeleteProposalDisplay(true);
                            }}
                            UpdateProjectUI={() => {
                              SetupProjectForDisplay(params.caseId);
                            }}
                          />
                          {reversedProposals.map((revisionobj, j) => {
                            if (
                              revisionobj.Revision !== 0 &&
                              revisionobj.Proposal === o.Proposal &&
                              revisionobj.QuoteStatus !== QuoteStatus.Expired
                            ) {
                              return (
                                <ProposalCardGuide
                                  key={`${i}-${j}`}
                                  quoteid={revisionobj.Id}
                                  proposalObj={revisionobj}
                                  DeleteProposal={() => {
                                    setDeleteProposalId(revisionobj.Id);
                                    setDeleteProposalDisplay(true);
                                  }}
                                  UpdateProjectUI={() => {
                                    SetupProjectForDisplay(params.caseId);
                                  }}
                                />
                              );
                            } else return null;
                          })}
                        </div>
                      );
                    } else return null;
                  })}
                </FlexColumnCont>
              </FlexColumnCont>
            </FlexColumnFullWidth>
          </ContentCont77vw>

          {/* Expired Estimates */}

          <ContentCont77vw display={expiredProposalVisible}>
            {window.$ISMOBILE === true ? <hr></hr> : null}{" "}
            <FlexDiv>
              {/* <MobileOnlyRegularHeader>
              {projectObj.Tittle}
            </MobileOnlyRegularHeader> */}
              <FlexColumnCont
                height="8vh"
                mobilewidth="80vw"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Archived Estimates
                </SmallHeaderLeagueSpartan>
              </FlexColumnCont>
              <Icon30x30
                onClick={() => {
                  DisplayProjectProposals(
                    expiredProjectProposalArrow,
                    "expired"
                  );
                }}
                src={expiredProjectProposalArrow}
              ></Icon30x30>
            </FlexDiv>
            <FlexColumnFullWidth
              margin="0"
              display={expiredProjectProposalsDisplay}
            >
              <FlexColumnCont
                justifycontent="flex-start"
                width="73vw"
                height="auto"
                margin="0"
              >

                <FlexColumnCont
                  margin="0"
                  alignitems="left"
                  width="70vw"
                  height="auto"
                >
                  <DeleteProposalWindow
                    proposalid={deleteProposalId}
                    deleteOpen={deleteProposalDisplay}
                    CloseWindow={() => {
                      setDeleteProposalDisplay(false);
                    }}
                  ></DeleteProposalWindow>

                  {projectProposals.map((o, i) => {
                    if (
                      o.Revision === 0 &&
                      o.QuoteStatus === QuoteStatus.Expired
                    ) {
                      return (
                        <div key={i}>
                          <ProposalCardGuide
                            quoteid={o.Id}
                            proposalObj={o}
                            DeleteProposal={() => {
                              setDeleteProposalId(o.Id);
                              setDeleteProposalDisplay(true);
                            }}
                            UpdateProjectUI={() => {
                              SetupProjectForDisplay(params.caseId);
                            }}
                          ></ProposalCardGuide>
                          {projectProposals.map((revisionobj, i) => {
                            if (
                              revisionobj.Revision !== 0 &&
                              revisionobj.Proposal === o.Proposal &&
                              revisionobj.QuoteStatus === "QuoteStatus.Expired"
                            ) {
                              return (
                                <ProposalCardGuide
                                  key={i}
                                  quoteid={revisionobj.Id}
                                  proposalObj={revisionobj}
                                  DeleteProposal={() => {
                                    setDeleteProposalId(revisionobj.Id);
                                    setDeleteProposalDisplay(true);
                                  }}
                                  UpdateProjectUI={() => {
                                    SetupProjectForDisplay(params.caseId);
                                  }}
                                ></ProposalCardGuide>
                              );
                            } else return null;
                          })}
                        </div>
                      );
                    } else return null;
                  })}
                </FlexColumnCont>
              </FlexColumnCont>
            </FlexColumnFullWidth>
          </ContentCont77vw>

          <FlexDivFlexEnd>
            <BlueUnderlinePointerPara
              onClick={() => {
                DeleteProject(params.caseId);
              }}
              color={ColorPalette.PrimaryRed}
            >
              Delete Project
            </BlueUnderlinePointerPara>
          </FlexDivFlexEnd>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  }
}
  else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

ViewProjectGuideView.defaultProps = {};

export default ViewProjectGuideView;
