import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";

// Material-UI (v4) components you already have
import { 
  Backdrop, 
  CircularProgress, 
  InputLabel, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper 
} from "@material-ui/core";

import Menu from "../../comps/MenuComps/Menu";
import EditStoreWindow from "../../comps/Windows/EditStore";

import {
  FlexCont100vw100vh,
  FlexColumnCont80vwAutoHeight,
  LeftCont,
  FlexDiv
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { 
  FlexDiv70x7Percent, 
  FlexDivSpaceBetween, 
  FlexColumnDiv, 
   
} from "../../StylesheetComps/Div";

import { 
  Icon30x40pxPointer, 
  Icon20x20 
} from "../../StylesheetComps/Imgs/Icons";

import {
  LeagueSpartanExtraLargeBold,
  RegularText
} from "../../StylesheetComps/Titles";
import { InputField } from "../../StylesheetComps/Inputs";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { UserRole } from "../../StylesheetComps/UserRoles";

// API calls
import { GetStoreInfo } from "../../API_Functions/Store_Functions";
import { GetStoreProducts, GetStoreTools } from "../../API_Functions/CatProduct_Functions";
import { GetStoreToolKits } from "../../API_Functions/Tool_Functions";

// Example store card
import StoreCard from "../../comps/StoreCard";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";

/* 
  InventoryScreen: An "Excel-like" display for a store's data
*/
const InventoryScreen = () => {
  const [storeObj, setStoreObj] = useState({});
  const [originalProductData, setOriginalProductData] = useState([]);
  const [productData, setProductData] = useState([]);
  
  const [originalToolData, setOriginalToolData] = useState([]);
  const [toolData, setToolData] = useState([]);

  const [originalStaffData, setOriginalStaffData] = useState([]);
  const [staffData, setStaffData] = useState([]);

  const [originalZoneData, setOriginalZoneData] = useState([]);
  const [zoneData, setZoneData] = useState([]);

  const [toolkitData, setToolkitData] = useState([]);

  const [roleNum, setRoleNum] = useState(0);
  const [managerName, setManagerName] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  // Toggles for each dataset
  const [productSelected, setProductSelected] = useState(true);
  const [toolSelected, setToolSelected] = useState(true);
  const [staffSelected, setStaffSelected] = useState(true);
  const [zoneSelected, setZoneSelected] = useState(true);

  const params = useParams();
  const history = useHistory();

  // ---------------------------------------------------------------------------
  // Data Retrieval
  // ---------------------------------------------------------------------------
  const GetStore = async (storeId) => {
    try {
      const data = await GetStoreInfo(storeId);
      const prodResp = await GetStoreProducts(storeId);
      const toolResp = await GetStoreTools(storeId);
      const staffResp = await axios.get(`${window.$DBURL}users/roles`);
      const zoneResp  = await axios.get(`${window.$DBURL}store/zonelist/${storeId}`);
      const toolkitResp = await GetStoreToolKits(storeId);

      if (data) {
        setStoreObj(data); 

        // Sorting function that places items whose names start with a digit at the end
        const sortByName = (a, b) => {
          const startsWithNumberA = /^\d/.test(a.Name);
          const startsWithNumberB = /^\d/.test(b.Name);
          if (startsWithNumberA && !startsWithNumberB) return 1; 
          if (!startsWithNumberA && startsWithNumberB) return -1; 
          return a.Name.localeCompare(b.Name, undefined, { sensitivity: 'base' });
        };

        // 1. Products
        const sortedProducts = prodResp.products.sort(sortByName);
        setOriginalProductData(sortedProducts);
        setProductData(sortedProducts);

        // 2. Tools
        const sortedTools = toolResp.tools.sort(sortByName);
        setOriginalToolData(sortedTools);
        setToolData(sortedTools);

        // 3. Staff
        const sortedStaff = staffResp.data.roles.sort((a, b) => {
          return a.Role.localeCompare(b.Role, undefined, {sensitivity: 'base'});
        });
        setOriginalStaffData(sortedStaff);
        setStaffData(sortedStaff);

        // 4. Zones
        const sortedZones = zoneResp.data.zones.sort(sortByName);
        setOriginalZoneData(sortedZones);
        setZoneData(sortedZones);

        // 5. Toolkits (no sort in example, adapt if needed)
        setToolkitData(toolkitResp.toolkit);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetProfileInfo = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      setManagerName(userInfo.Name);
    }
  };

  const GetSuppliers = async () => {
    const resp = await axios.get(`${window.$DBURL}store/${params.Id}/supplier`);
    if (resp.status === 200 && resp.data !== null) {
      // if (resp.data.suppliers !== null) ...
      // code for retrieving or storing suppliers
    }
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      setRoleNum(userInfo.Role);
    }
  };

  // ---------------------------------------------------------------------------
  // Search Logic
  // ---------------------------------------------------------------------------
  const handleSearch = (searchText) => {
    const lowerCaseText = searchText.toLowerCase();
    const textArray = lowerCaseText.split(" ");

    // Generalized filter function
    const filterData = (dataList, keyToFilter) => {
      return dataList.filter((item) => {
        // Exclude items with 'DNU' in uppercase in their name
        if (item[keyToFilter] && item[keyToFilter].toUpperCase().includes('DNU')) {
          return false;
        }
        // Must match all words in search
        return textArray.every((word) =>
          item[keyToFilter] && item[keyToFilter].toLowerCase().includes(word)
        );
      });
    };

    // Filter each dataset
    setProductData(filterData(originalProductData, 'Name'));
    setToolData(filterData(originalToolData, 'Name'));
    // For staff, the key is "Role" instead of "Name"
    setStaffData(filterData(originalStaffData, 'Role'));
    setZoneData(filterData(originalZoneData, 'Name'));
  };

  // ---------------------------------------------------------------------------
  // Lifecycle
  // ---------------------------------------------------------------------------
  useEffect(() => {
    GetStore(params.Id);
    GetProfileInfo();
    GetSuppliers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ---------------------------------------------------------------------------
  // Render If Manager
  // ---------------------------------------------------------------------------
  if (roleNum !== UserRole.Manager) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // ---------------------------------------------------------------------------
  // Rendering Functions: Tables
  // ---------------------------------------------------------------------------

  const renderProductTable = () => {
    return (
        // Add some horizontal scrolling so columns don't overflow
        <TableContainer component={Paper} style={{ maxWidth: "100%", overflowX: "auto" }}>
          <Table size="small" aria-label="products-table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#f1f1f1" }}>
                <TableCell><strong>ID</strong></TableCell>
                {/* <TableCell><strong>Code</strong></TableCell> */}
                {/* <TableCell><strong>CateType</strong></TableCell> */}
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Description</strong></TableCell>
                {/* <TableCell><strong>OriginalPrice</strong></TableCell> */}
                {/* <TableCell><strong>Markup</strong></TableCell> */}
                <TableCell><strong>UnitPrice</strong></TableCell>
                <TableCell><strong>Unit</strong></TableCell>
                <TableCell><strong>InstallSpeed</strong></TableCell>
                <TableCell><strong>SalesTax</strong></TableCell>
                <TableCell><strong>Stock</strong></TableCell>
                <TableCell><strong>SaleQuant</strong></TableCell>
                <TableCell><strong>ImageUrl</strong></TableCell>
                <TableCell><strong>Category_Id</strong></TableCell>
                <TableCell><strong>RelatedProducts</strong></TableCell>
                <TableCell><strong>Disclaimer</strong></TableCell>
                <TableCell><strong>Position</strong></TableCell>
                <TableCell><strong>RoundUp</strong></TableCell>
                <TableCell><strong>RoundToHalf</strong></TableCell>
                <TableCell><strong>Supplier(s)</strong></TableCell>
                <TableCell><strong>RelatedTools</strong></TableCell>
                <TableCell><strong>Minimum</strong></TableCell>
                <TableCell><strong>RFactor</strong></TableCell>
                <TableCell><strong>ReStock</strong></TableCell>
                <TableCell><strong>PackSize</strong></TableCell>
                <TableCell><strong>Charge_Rate</strong></TableCell>
                <TableCell><strong>Convert</strong></TableCell>
                <TableCell><strong>Denominator</strong></TableCell>
                <TableCell><strong>DisplayUnit</strong></TableCell>
                <TableCell><strong>Notes</strong></TableCell>
                <TableCell><strong>Allowance</strong></TableCell>
                <TableCell><strong>SetupTime</strong></TableCell>
                <TableCell><strong>ProductLink</strong></TableCell>
                <TableCell><strong>Addons</strong></TableCell>
              </TableRow>
            </TableHead>
    
            <TableBody>
              {productData.map((prod) => (
                <TableRow
                  key={prod.Id}
                  hover
                  style={{ cursor: "pointer" }}
                  // onClick={() => {
                  //   history.push(`/productlist/${params.Id}/${prod.Category_Id}/${prod.Id}`);
                  // }}
                >
                  <TableCell>{prod.Id}</TableCell>
                  {/* <TableCell>{prod.Code ?? ""}</TableCell> */}
                  {/* <TableCell>{prod.CateType ?? ""}</TableCell> */}
                  <TableCell>{prod.Name ?? ""}</TableCell>
                  <TableCell>{prod.Description ?? ""}</TableCell>
                  {/* <TableCell>{prod.OriginalPrice ?? 0}</TableCell> */}
                  {/* <TableCell>{prod.Markup ?? 0}</TableCell> */}
                  <TableCell>{(prod.UnitPrice ?? 0).toFixed(2)}</TableCell>
                  <TableCell>{prod.Unit ?? ""}</TableCell>
                  <TableCell>{prod.InstallSpeed ?? 0}</TableCell>
                  <TableCell>
                    {/* If SalesTax is an array of objects, show their names (or IDs) */}
                    {prod.SalesTax && prod.SalesTax.length > 0
                      ? prod.SalesTax.map((st) => st.TaxName || "Tax").join(", ")
                      : ""}
                  </TableCell>
                  <TableCell>{prod.Stock ?? 0}</TableCell>
                  <TableCell>{prod.SaleQuant ?? 0}</TableCell>
                  <TableCell>{prod.ImageUrl ?? ""}</TableCell>
                  <TableCell>{prod.Category_Id ?? ""}</TableCell>
                  <TableCell>
                    {prod.RelatedProducts && prod.RelatedProducts.length > 0
                      ? prod.RelatedProducts.map((rp) => rp.Name || rp.Id).join(", ")
                      : ""}
                  </TableCell>
                  <TableCell>{prod.Disclaimer ?? ""}</TableCell>
                  <TableCell>{prod.Position ?? 0}</TableCell>
                  <TableCell>{prod.RoundUp ? "true" : "false"}</TableCell>
                  <TableCell>{prod.RoundToHalf ? "true" : "false"}</TableCell>
                  <TableCell>
                    {prod.Supplier && prod.Supplier.length > 0
                      ? prod.Supplier.map((s) => s.SupplierName).join(", ")
                      : ""}
                  </TableCell>
                  <TableCell>
                    {prod.RelatedTools && prod.RelatedTools.length > 0
                      ? prod.RelatedTools.map((rt) => rt.Name || rt.Id).join(", ")
                      : ""}
                  </TableCell>
                  <TableCell>{prod.Minimum ?? 0}</TableCell>
                  <TableCell>{prod.RFactor ?? 0}</TableCell>
                  <TableCell>{prod.ReStock ? "true" : "false"}</TableCell>
                  <TableCell>{prod.PackSize ?? 0}</TableCell>
                  <TableCell>{prod.Charge_Rate ?? 0}</TableCell>
                  <TableCell>{prod.Convert ? "true" : "false"}</TableCell>
                  <TableCell>{prod.Denominator ?? 0}</TableCell>
                  <TableCell>{prod.DisplayUnit ?? ""}</TableCell>
                  <TableCell>{prod.Notes ?? ""}</TableCell>
                  <TableCell>{prod.Allowance ?? 0}</TableCell>
                  <TableCell>{prod.SetupTime ?? 0}</TableCell>
                  <TableCell>{prod.ProductLink ?? ""}</TableCell>
                  <TableCell>
                    {prod.Addons && prod.Addons.length > 0
                      ? prod.Addons.map((a) => a.Name || a.Id).join(", ")
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
  };

  const renderToolTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="tools-table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#f1f1f1" }}>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>ToolKit</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {toolData.map((tool) => {
              // Find the name of the toolkit that matches tool.ToolKit_Id
              const tk = toolkitData.find(t => t.Id === tool.ToolKit_Id);
              return (
                <TableRow 
                  key={tool.Id}
                  hover 
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const toolKitName = tk ? tk.Name : "Unknown";
                    history.push(`/toollist/${params.Id}/${tool.ToolKit_Id}/${toolKitName}`);
                  }}
                >
                  <TableCell>
                    <FlexDiv>
                      <Icon20x20 src="/Tools.svg" />
                      <RegularText style={{ marginLeft: 8 }}>{tool.Name}</RegularText>
                    </FlexDiv>
                  </TableCell>
                  <TableCell>{tk ? tk.Name : ""}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderStaffTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="staff-table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#f1f1f1" }}>
              <TableCell><strong>Role</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {staffData.map((staff) => (
              <TableRow 
                key={staff.Role}
                hover 
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`/rolelist/${params.Id}/${staff.Role}`)}
              >
                <TableCell>
                  <FlexDiv>
                    <Icon20x20 src="/Workforce.svg" />
                    <RegularText style={{ marginLeft: 8 }}>{staff.Role}</RegularText>
                  </FlexDiv>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderZoneTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="zones-table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#f1f1f1" }}>
              <TableCell><strong>Name</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {zoneData.map((zone) => (
              <TableRow 
                key={zone.Id} 
                hover 
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`/Zone/${params.Id}/${zone.Id}`)}
              >
                <TableCell>
                  <FlexDiv>
                    <Icon20x20 src="/Zones.svg" />
                    <RegularText style={{ marginLeft: 8 }}>{zone.Name}</RegularText>
                  </FlexDiv>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // ---------------------------------------------------------------------------
  // Final Render
  // ---------------------------------------------------------------------------
  return (
    <FlexCont100vw100vh>
      {/* Left Menu */}
      <LeftCont>
        <Menu />
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <RightViewFullWidth>
          {/* Header Section */}
          <FlexDiv70x7Percent height="auto" margin="15px">
            <FlexColumnDiv>
              <FlexDiv>
                <LeagueSpartanExtraLargeBold>
                  {storeObj.Name} Inventory Management
                </LeagueSpartanExtraLargeBold>
                <Icon30x40pxPointer
                  onClick={() => setIsEditOpen(true)}
                  src="/pencil.svg"
                  style={{ marginLeft: 10 }}
                />
              </FlexDiv>
              
              <FlexDiv>
                <RegularText>
                  {storeObj.Address} - {storeObj.Phone}
                </RegularText>
              </FlexDiv>

              {/* Cards (Links to dedicated sub-pages) */}
              <FlexDiv>
                <Link
                  to={"/productcategory/" + params.Id}
                  style={{ textDecoration: "none", color: "black", marginRight: 12 }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Products"
                    StoreCardImgUrl="/Inventory.svg"
                  />
                </Link>
                <Link
                  to={"/toolkit/" + params.Id}
                  style={{ textDecoration: "none", color: "black", marginRight: 12 }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Tools"
                    StoreCardImgUrl="/Tools.svg"
                  />
                </Link>
                <Link
                  to={"/rolecat/" + params.Id}
                  style={{ textDecoration: "none", color: "black", marginRight: 12 }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Staff"
                    StoreCardImgUrl="/Workforce.svg"
                  />
                </Link>
                <Link
                  to={"/Zonelist/" + params.Id}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <StoreCard
                    StoreCardHeader="Manage Zones"
                    StoreCardImgUrl="/Zones.svg"
                  />
                </Link>
              </FlexDiv>
            </FlexColumnDiv>
          </FlexDiv70x7Percent>

          {/* Edit Store Window */}
          <EditStoreWindow
            storeobj={storeObj}
            open={isEditOpen}
            onClose={() => setIsEditOpen(false)}
          />

          <hr />
          <br />

          {/* Search + Toggles */}
          <FlexDiv70x7Percent>
            <FlexColumnDiv width="90%">
              <FlexDivSpaceBetween>
                <InputLabel>Search for what you are looking for...</InputLabel>
                <FlexDiv>
                  <RegularResponsiveButton
                    bgcolor={productSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
                    onClick={() => setProductSelected(!productSelected)}
                  >
                    Products
                  </RegularResponsiveButton>

                  <RegularResponsiveButton
                    bgcolor={toolSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
                    onClick={() => setToolSelected(!toolSelected)}
                    style={{ marginLeft: 8 }}
                  >
                    Tools
                  </RegularResponsiveButton>

                  <RegularResponsiveButton
                    bgcolor={staffSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
                    onClick={() => setStaffSelected(!staffSelected)}
                    style={{ marginLeft: 8 }}
                  >
                    Staff
                  </RegularResponsiveButton>

                  <RegularResponsiveButton
                    bgcolor={zoneSelected ? ColorPalette.PrimaryButtonBlue : ColorPalette.PrimaryGrey}
                    onClick={() => setZoneSelected(!zoneSelected)}
                    style={{ marginLeft: 8 }}
                  >
                    Zones
                  </RegularResponsiveButton>
                </FlexDiv>
              </FlexDivSpaceBetween>

              <br />
              <InputField
                onChange={(e) => {
                  const searchText = e.target.value;
                  if (!searchText.trim()) {
                    // Reset data if empty
                    setProductData(originalProductData);
                    setToolData(originalToolData);
                    setStaffData(originalStaffData);
                    setZoneData(originalZoneData);
                  } else {
                    handleSearch(searchText);
                  }
                }}
              />
            </FlexColumnDiv>
          </FlexDiv70x7Percent>

          <br />

          {/* "Excel-like" Tables */}
          <FlexDiv70x7Percent width="78%" alignitems="flex-start" justifycontent="flex-start">
            <FlexColumnDiv width="100%">
              {/* PRODUCTS TABLE */}
              {productSelected && (
                <>
                  <RegularText style={{ marginBottom: 8, marginTop: 16 }}>Products</RegularText>
                  {renderProductTable()}
                </>
              )}

              {/* TOOLS TABLE */}
              {toolSelected && (
                <>
                  <RegularText style={{ marginBottom: 8, marginTop: 16 }}>Tools</RegularText>
                  {renderToolTable()}
                </>
              )}

              {/* STAFF TABLE */}
              {staffSelected && (
                <>
                  <RegularText style={{ marginBottom: 8, marginTop: 16 }}>Staff</RegularText>
                  {renderStaffTable()}
                </>
              )}

              {/* ZONES TABLE */}
              {zoneSelected && (
                <>
                  <RegularText style={{ marginBottom: 8, marginTop: 16 }}>Zones</RegularText>
                  {renderZoneTable()}
                </>
              )}
            </FlexColumnDiv>
          </FlexDiv70x7Percent>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

InventoryScreen.defaultProps = {};

export default InventoryScreen;
