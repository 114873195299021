import styled from "styled-components";
import { animated } from "react-spring";
import { ColorPalette } from "../Colors";

const nullProp = null;

//Estimate Page Containers --------------------------------------------------

export const ProgressBar = styled.div`
  width: 39vw;
  margin-top: 3vh;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  height: 1vh;
  border-radius: 50px;
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const ProgressBarWidth = styled.div`
  width: ${(props) => (props.width ? props.width : "0%")};
  background-color: ${ColorPalette.PrimaryButtonBlue};
  border-radius: 50px;
  height: 1vh;
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const EstimatePageMainFlexCont = styled.div`
  display: flex;
  @media (max-width: 1006px) {
    flex-direction: column;
    height: 100vh;
  }
`;

export const EstimatePageRightCont = styled.div`
  width: 80vw;
  height: 200vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 0vh;
  }
`;

export const EstimatePageRightView = styled.div`
  width: 100vw;
  min-height: 250vh;
  height: auto;
  background-color: #f4f4f4;
  display: flex;
  @media (max-width: 1006px) {
    width: auto;
    margin: 0%;
    padding-bottom: 15vh;
    background-color: white;
    height: auto;
    min-height: auto;
  }
`;

export const Section1 = styled(animated.div)`
width:100%;
height ${(props) => (props.height ? props.height : "55vh")};
display:flex;
flex-direction:column;
align-items:center;
@media (max-width: 1006px) {
    position:relative;
    width:95vw;
    left:2%;
    height:auto;
    align-items:center;
    padding-bottom:${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : "15vh"};
  }
`;

export const EstimatorDiv = styled.div`
  width: 79.5vw;
  margin: 1%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: white;

  @media (max-width: 1006px) {
    width: 90%;
    margin-top: 10%;
    align-items: center;
  }
`;

export const FormDivs = styled.div`
  width: 75%;
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: 1%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1006px) {
    flex-direction: column;
    width: 95vw;
    height: auto;
  }
`;

export const TextAreaRegular = styled.textarea`
  width: 260px;
  height: 50px;
  border-radius: 4px;
  background-color: #ededed;
  margin: 15px;
  font-family: Avenir;
`;

export const TextAreaLarge = styled.textarea`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100px")};
  background-color: #ededed;
  border: 0;
  outline: none;
  overflow: auto;
  border-radius: 4px;
  font-family: Avenir;
  font-size: 16px;
  padding: 20px;
  margin: 10px;
`;

export const LandscapePara = styled.textarea`
  width: ${(props) => (props.width ? props.width : "95%")};
  height: ${(props) => (props.height ? props.height : "20vh")};
  color: black;
  background-color: white;
  border: 2px dashed #d3d3d3;
  border-radius: 10px;
  outline: none;
  font-family: Open Sans;
  font-size: 16px;
  padding: 10px;
  overflow: "auto";
  margin:${(props) => (props.margin ? props.margin : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "95vw")};
    font-size: ${(props) => (props.mobilefontsize ? props.mobilefontsize : "14px")};
   
  }
`;

export const TextAreaAutoResize = styled.textarea`
  width: ${(props) => (props.width ? props.width : "95%")};
  height: ${(props) => (props.height ? props.height : "")};
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  font-family: Avenir;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")};
  padding: 12px 16px;
  overflow: scroll;
  transition: border-color 0.3s ease;
  box-sizing: border-box;

  &:focus {
    border-color: #007aff;
  }

  ::placeholder {
    color: #999;
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "80vw")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
  }
`;

export const TextAreaMobile = styled.textarea`
  width: ${(props) => (props.width ? props.width : "95%")};
  height: ${(props) => (props.height ? props.height : "200px")};
  color: #333;
  margin-left: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  font-family: Avenir;
  font-size: 16px;
  padding: 12px 16px;
  overflow: hidden;
  transition: border-color 0.3s ease;
  box-sizing: border-box;

  &:focus {
    border-color: #007aff;
  }

  ::placeholder {
    color: #999;
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "88vw")};
  }
`;

export const TextAreaAutoResizeNoBorder = styled.textarea`
  width: 95%;
  height: ${(props) => (props.height ? props.height : "")};
  color: black;
  background-color: white;
  border: none !important; /* override any conflicting styles */
  border-radius: 10px;
  outline: none;
  resize: none;
  font-family: Avenir;
  font-size: 16px;
  padding: 10px;
  overflow: hidden;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "80vw")};
  }
`;


export const ButtonFormDiv = styled.div`
  display: flex;
  margin-top: 2%;
  position: relative;
  height: 10vh;
  padding-bottom: 5vh;
  justify-content: center;
  @media (max-width: 1006px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GreyNavigationButton = styled.div`
  width: ${(props) => (props.width ? props.width : "15%")};
  height: ${(props) => (props.height ? props.height : "2.5vw")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "#2e2e2e")};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "2.5vw")};
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "1%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "3%")};
  border: ${(props) => (props.border ? props.border : "4px solid white")};
  border-radius: 10px;
  transition: 0.3s;
  display: ${(props) => (props.display ? props.display : "block")};
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "30vw")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "18px"};
    margin-top: 5vh;
    line-height: 6vh;
    height: 6vh;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  }
`;

export const GreenNavigationButton = styled.div`
  width: ${(props) => (props.width ? props.width : "15%")};
  height: ${(props) => (props.height ? props.height : "2.5vw")};
  text-align: center;
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "LeagueSpartan-Bold"};
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "2.5vw")};
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "1%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  border: ${(props) => (props.border ? props.border : "4px solid white")};
  border-radius: 10px;
  transition: 0.3s;
  display: ${(props) => (props.display ? props.display : "block")};
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "30vw")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "14px"};
    margin-top: ${(props) =>
      props.mobilemargintop ? props.mobilemargintop : "5vh"};
    line-height: 6vh;
    height: 6vh;
  }
`;

export const TextInputRegular = styled.input`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "84%")};
  height: ${(props) => (props.inputheight ? props.inputheight : "1%")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "5px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : "#F4F4F4"};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  margin: 10px;
  margin-top: 2%;
  border: 0;
  padding: 18px;
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "100%")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    margin-top: 2vh;
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "70vw")};
    margin-right: ${(props) =>
      props.mobilemarginright ? props.mobilemarginright : ""};
      font-size: ${(props) => (props.mobilefontsize ? props.mobilefontsize : "")};
  }
`;

export const TextInputReusable = styled.input`
  width: ${(props) => (props.width ? props.width : "400px")};
  height: ${(props) => (props.height ? props.height : "8px")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "5px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : "#F4F4F4"};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.display ? props.display : "block")};
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  border: 0;
  padding: 18px;
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "100%")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    margin-top: 2vh;
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "70vw")};
    margin-right: ${(props) =>
      props.mobilemarginright ? props.mobilemarginright : ""};
  }
`;

export const TextInput72Percent = styled.input`
  background-color: #FFFFF;
  padding: 10px;
  width: 72%;
  margin: 2%;
  font-style: normal;
  border-radius: 4px;
  margin-left: 20px;
  font-family: Avenir;
  font-size: 18px;
`;

export const TextInput100Percent = styled.input`
  background-color: #FFFFF;
  padding: 10px;
  width: 100%;
  display: ${(props) => (props.display ? props.display : "")};
  font-style: normal;
  border-radius: 4px;
  margin-left: 20px;
  font-family: Avenir;
  font-size: 18px;
`;

export const TextSelect72Percent = styled.select`
  background-color: #FFFFF;
  padding: 10px;
  width: 78%;
  margin: 2%;
  font-style: normal;
  border-radius: 4px;
  margin-left: 20px;
  font-family: Avenir;
  font-size: 18px;
`;

export const SelectField = styled.select`
  background-color: #ffffff;
  padding: 12px 16px;
  width: 200px;
  min-width: 75px;
  margin-bottom: 20px;
  font-family: Avenir;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  color: #333;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007aff; // Adjust this to match your theme color if needed
  }

  &::placeholder {
    color: #999;
  }
`;


export const TextArea72Percent = styled.textarea`
  background-color: #FFFFF;
  padding: 10px;
  width: 72%;
  min-height: 80px;
  font-style: normal;
  border-radius: 4px;
  font-family: Avenir;
  font-size: 16px;
`;

export const DatalistMedium = styled.select`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "335px")};
  height: ${(props) => (props.inputheight ? props.inputheight : "50px")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "4px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : "#F4F4F4"};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  border: 0;
  padding-left: 18px;
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "100%")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    margin-top: 2vh;
    height: 10vw;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "80vw")};
  }
`;

export const DatalistOption = styled.option`
  font-family: Avenir;
  color: black;
  font-size: 18px;
`;

export const SmallHeaderLeagueSpartan = styled.p`
  min-width: ${(props) => (props.minwidth ? props.minwidth : "")};
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "")};
  width: ${(props) => (props.width ? props.width : "")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "18px")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "LeagueSpartan-Bold"};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "")};
  color: ${(props) => (props.color ? props.color : "black")};
  margin-right: 5px;
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  border-bottom: ${(props) => (props.borderbottom ? props.borderbottom : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  text-decoration: ${(props) =>
    props.textdecoration ? props.textdecoration : ""};
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
  }
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "16px"};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
  }
`;

export const SecDivPersonalInfoMarginLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  margin-left: 5%;
  @media (max-width: 1006px) {
    width: 90vw;
    // margin-bottom:3vh;
    margin-left: 0%;
  }
`;

export const FormContPersonalInfo = styled.div`
  width: 100%;
  height: 100%;
  margin: 1%;
  position: relative;
  bottom: 5%;
  display: flex;
  text-align: left;
  flex-direction: row;

  @media (max-width: 1006px) {
    flex-direction: column;
    bottom: 0%;
    margin: 3%;
  }
`;

export const FlexColumnGreenSelectables = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: 80%;
  margin: 1%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "60vw")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    flex-direction: column;
  }
`;

export const MediumStyledImage = styled.img`
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "70%")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "0px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "42vw")};
  }
`;

export const ImageFullWidth = styled.img`
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "0px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "contain")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "80vw")};
  }
`;

export const Image300x200 = styled.img`
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  width: ${(props) => (props.width ? props.width : "300px")};
  height: ${(props) => (props.height ? props.height : "200px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "0px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "contain")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  z-index: ${(props) => (props.zindex ? props.zindex : "")};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
  }
`;

export const Image300x200GreenBorder = styled.img`
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  width: ${(props) => (props.width ? props.width : "300px")};
  height: ${(props) => (props.height ? props.height : "200px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "0px"};
  object-fit: cover;
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  :hover {
    object-fit: contain;
  }
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "80vw")};
  }
`;

export const Image375px = styled.img`
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  width: 375px;
  height: 375px;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  object-fit: ${(props) => (props.objfit ? props.objfit : "contain")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    width: 100%;
    object-fit: contain;
  }
`;

export const MediumStyledImage80Opacity = styled.img`
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "70%")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "0px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "cover")};
  opacity: ${(props) => (props.opacity ? props.opacity : "80%")};
  // background-color:black;
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "42vw")};
  }
`;

export const SmallIconDisplayNone = styled.img`
  cursor: pointer;
  display: ${(props) => (props.display ? props.display : "none")};
  width: ${(props) => (props.width ? props.width : "150px")};
  height: ${(props) => (props.height ? props.height : "auto")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "0px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "")};
  position: absolute;
  transition: ${(props) =>
    props.displaytransition ? props.displaytransition : ""};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    position: relative;
    width: 100px;
    bottom: 40%;
  }
`;

export const FlexDivSpaceAround = styled.div`
width:${(props) => (props.width ? props.width : "24%")};
height:${(props) => (props.height ? props.height : "90%")};
margin:${(props) => (props.margin ? props.margin : "1%")};
align-items:${(props) => (props.alignitems ? props.alignitems : "center")};
flex-direction:${(props) =>
  props.flexdirection ? props.flexdirection : "column"};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "space-around"};
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "auto")};
}
`;

export const FlexDivMarginTop = styled.div`
  width: ${(props) => (props.width ? props.width : "24%")};
  height: ${(props) => (props.height ? props.height : "90%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "space-around"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    margin-top: 50px;
  }
`;

export const FlexContBottomGrey = styled.div`
width:${(props) => (props.width ? props.width : "100%")};
height:${(props) => (props.height ? props.height : "40%")};
margin:${(props) => (props.margin ? props.margin : "0")};
align-items:${(props) => (props.alignitems ? props.alignitems : "left")};
display:${(props) => (props.display ? props.display : "flex")};
margin-bottom:${(props) => (props.marginbottom ? props.marginbottom : "")};
flex-direction:${(props) =>
  props.flexdirection ? props.flexdirection : "row"};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "flex-start"};
border-bottom:${(props) =>
  props.borderbottom ? props.borderbottom : "1px solid #d3d3d3"};
padding:1.5%;
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "auto")};
}
`;

export const WideFlexInfoCont = styled.div`
width:${(props) => (props.width ? props.width : "100%")};
height:${(props) => (props.height ? props.height : "100%")};
align-items:${(props) => (props.alignitems ? props.alignitems : "left")};
display:${(props) => (props.display ? props.display : "flex")};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "flex-start"};
word-wrap:${(props) => (props.wordwrap ? props.wordwrap : "")};
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  flex-direction:${(props) =>
    props.mobileflexdirection ? props.mobileflexdirection : ""};
}
`;

export const FlexInfoCont = styled.div`
width:${(props) => (props.width ? props.width : "50%")};
height:${(props) => (props.height ? props.height : "100%")};
align-items:${(props) => (props.alignitems ? props.alignitems : "left")};
display:${(props) => (props.display ? props.display : "flex")};
flex-direction:${(props) =>
  props.flexdirection ? props.flexdirection : "column"};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "flex-start"};
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "auto")};
}
`;

export const FlexDivFlexStartCenter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "5%")};
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
  }
`;

export const PhotoContExtra = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: flex;
  }
`;
// ------------------------------------------------------------------------

export default nullProp;
